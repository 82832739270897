<template>
  <div class="tenant-dashboard">
    <NavBar />
    <div class="tenant-dashboard-content">
      <div class="container">
        <TenantNav />
        <div class="dashboard-content">
          <div class="dashboard-content-left">
            <div id="AdminMessages">
              <div class="message-component-title">
                <h1>Messages</h1>
              </div>
              <div class="messages-widget">
                <div class="messages-widget-left">
                  <form class="search">
                    <input type="text" placeholder="Search..." />
                    <i class="fas fa-search"></i>
                  </form>
                  <div class="message-label">
                    Messages
                    <Tag color="#e96767" v-if="unread > 0">{{ unread }}</Tag>
                  </div>
                  <div class="chat-categories">
                    <div
                      class="chat-category-item"
                      :class="activeTab == 'chat' ? 'active' : ''"
                      @click="activeTab = 'chat'"
                    >
                      <label for="">Chat</label>
                    </div>
                    <div
                      class="chat-category-item"
                      :class="activeTab == 'contacts' ? 'active' : ''"
                      @click="activeTab = 'contacts'"
                    >
                      <label for="">Contacts</label>
                    </div>
                  </div>
                  <div class="chats-list" v-if="activeTab == 'chat'">
                    <div
                      class="chat-item"
                      v-for="chat in chats"
                      :key="chat.chat_id"
                      @click="activeChat = chat"
                      :class="
                        activeChat &&
                        activeChat.admin.admin_id == chat.admin.admin_id
                          ? 'active'
                          : ''
                      "
                    >
                      <div class="chat-item-left">
                        <div class="chat-img">
                          <img :src="$upload(chat.admin.image)" alt="admin" />
                        </div>
                        <div class="chat-item-left-text">
                          <div class="chat-name">
                            <h5>{{ chat.admin.name }}</h5>
                            <p v-if="chat.last_message">
                              {{ $chatdate(chat.last_message.created_at) }}
                            </p>
                          </div>
                          <div class="last-message">
                            <p v-if="chat.last_message">
                              {{ chat.last_message.message }}
                            </p>
                            <p v-else>No messages yet.</p>
                            <Tag color="#e96767" v-if="chat.unread > 0">{{
                              chat.unread
                            }}</Tag>
                            <!-- <Tag color="#e96767">{{ 3 }}</Tag> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="contacts-list" v-else>
                    <form class="search-contact">
                      <input type="text" placeholder="Search..." />
                      <i class="fas fa-search"></i>
                    </form>
                    <div class="chats-list">
                      <div
                        class="contact-item"
                        v-for="admin in allAdmins"
                        :key="admin.admin_id"
                      >
                        <div class="contact-item-left">
                          <div class="contact-img">
                            <img :src="$upload(admin.image)" alt="admin" />
                          </div>
                          <div class="contact-name">
                            <h5>{{ admin.name }}</h5>
                            <p>{{ admin.email }}</p>
                          </div>
                        </div>
                        <div class="message-button">
                          <button @click="startNewChat(admin)">
                            <i class="fas fa-paper-plane"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="messages-widget-right">
                  <div v-if="activeChat">
                    <div class="chat-header">
                      <div class="chat-header-left">
                        <div class="chat-img">
                          <img
                            :src="$upload(activeChat.admin.image)"
                            alt="admin"
                            @error="$imageurlalt()"
                          />
                        </div>
                        <div class="chat-name">
                          <h5>{{ activeChat.admin.name }}</h5>
                          <p>{{ activeChat.admin.email }}</p>
                        </div>
                      </div>
                      <div class="chat-header-right">
                        <a :href="'tel:' + activeChat.admin.phone">
                          <i class="fas fa-phone"></i>
                        </a>
                        <a :href="'mailto:' + activeChat.admin.email">
                          <i class="fas fa-envelope"></i>
                        </a>
                      </div>
                    </div>
                    <div class="chat-messages" id="chatMessages">
                      <div
                        class="chat-message"
                        v-for="message in activeChat.messages"
                        :key="message.message_id"
                      >
                        <div
                          class="received-message"
                          v-if="message.sender_type == 'tenant'"
                        >
                          <div class="chat-message-right">
                            <div class="message-bubble">
                              <p>{{ message.message }}</p>
                            </div>
                            <p class="date">
                              {{ $chatdate(message.created_at) }}
                            </p>
                          </div>
                        </div>
                        <div class="sent-message" v-else>
                          <div class="chat-message-right">
                            <div class="message-bubble">
                              <p>{{ message.message }}</p>
                            </div>
                            <p class="date">
                              {{ $chatdate(message.created_at) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form class="chat-input" @submit.prevent="sendMessage">
                      <input
                        type="text"
                        placeholder="Enter your message..."
                        v-model="newMessage.message"
                      />
                      <button>
                        <i class="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag } from "ant-design-vue";
import { toast } from "vue3-toastify";
export default {
  components: {
    Tag,
  },
  data() {
    return {
      currentPage: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      allItemsCount: 0,
      tenantSubscriptions: [],
      selectedItems: [],
      allSelected: false,
      startViewSubscription: false,
      selectedItem: null,
      popularPosts: [],
      startCancellation: false,
      reason: "",
      allAdmins: [],
      activeTab: "chat",
      activeAdmin: null,
      chats: [],
      activeChat: null,
      newMessage: {
        house_id: "",
        receiver_id: "",
        message: "",
        attachment: "",
        chat_id: "",
      },
      newChat: {
        house_id: "",
        members: [],
      },
    };
  },
  methods: {
    getAdmins() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ADMINS_BY_HOUSE", {
          token: this.$getAdminToken(),
          data: {
            paginate: 100,
            page: this.currentPage,
            house_id: this.$getTenant().house_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allAdmins = res.data.admins.data;
              if (this.$route.query.admin) {
                this.activeTab = "chat";
                this.activeAdmin = this.allAdmins.find(
                  (admin) => admin.admin_id == this.$route.query.admin
                );
                this.getChats();
              } else {
                this.getChats();
              }
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getChats() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_CHATS", {
          token: this.$getTenantToken(),
          data: {
            house_id: this.$getTenant().house_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.chats = res.data.chats;
              this.unread = res.data.unread;
              this.orderChats(this.chats);
              if (this.activeChat) {
                this.activeChat = this.chats.find(
                  (chat) => chat.chat_id == this.activeChat.chat_id
                );
              } else {
                if (this.$route.query.tenant) {
                  this.activeTab = "chat";
                  let currentChat = this.chats.find(
                    (chat) => chat.tenant.tenant_id == this.$route.query.tenant
                  );
                  if (currentChat) {
                    this.activeChat = currentChat;
                  } else {
                    this.startNewChat(this.activeTenant);
                  }
                }
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    sendMessage() {
      if (this.newMessage.message.trim() === "") {
        toast.error("Message is required.");
        return;
      }
      this.$startLoader();
      this.newMessage.house_id = this.$getTenant().house_id;
      this.newMessage.receiver_id = this.activeChat.admin.admin_id;
      this.newMessage.chat_id = this.activeChat.chat_id;
      this.$store
        .dispatch("SEND_MESSAGE", {
          token: this.$getAdminToken(),
          data: this.newMessage,
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.newMessage.message = "";
              this.getChats();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    startNewChat(admin) {
      this.$startLoader();
      this.newChat.house_id = this.$getTenant().house_id;
      this.newChat.members = [];
      this.newChat.members.push(admin.admin_id);
      this.newChat.members.push(this.$getTenant().tenant_id);
      this.$store
        .dispatch("CREATE_CHAT", {
          token: this.$getTenantToken(),
          data: this.newChat,
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              let chat_id = res.data.chat_id;
              this.getChats();
              this.activeChat = this.chats.find(
                (chat) => chat.chat_id == chat_id
              );
              this.activeTab = "chat";
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    orderChats(chats) {
      const sortedChats = chats.sort((a, b) => {
        if (!a.last_message) {
          return 1;
        }
        if (!b.last_message) {
          return -1;
        }
        return (
          new Date(b.last_message.created_at) -
          new Date(a.last_message.created_at)
        );
      });

      this.chats = sortedChats;
      if (!this.activeChat && !this.activeTenant) {
        if (sortedChats.length > 0) {
          this.activeChat = this.chats[0];
        }
      }
    },
  },
  mounted() {
    this.getAdmins();
  },
  watch: {
    activeChat() {
      if (this.activeChat) {
        this.$startLoader();
        this.$store
          .dispatch("MARK_AS_READ", {
            token: this.$getAdminToken(),
            data: {
              chat_id: this.activeChat.chat_id,
              house_id: this.$getTenant().house_id,
              receiver_id: this.$getTenant().tenant_id,
            },
          })
          .then((res) => {
            this.activeChat.unread = 0;
            let chatMessages = document.getElementById("chatMessages");
            if (chatMessages) {
              chatMessages.scrollTo({
                top: chatMessages.scrollHeight,
                behavior: "smooth",
              });
              this.$stopLoader();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-dashboard {
  background: #c6cae046;
  min-height: 100vh;
}
.tenant-dashboard-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.dashboard-content {
  padding: 0 1rem;
  margin: 0 1rem;
  height: 85vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .dashboard-content-left {
    .dashboard-content-header {
      h3 {
        font-size: 1.15rem;
        font-weight: 500;
      }
    }
    .tenant-dashboard-widget {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      margin: 1rem 0;
      .tenant-dashboard-widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }
        form {
          width: 300px;
          input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #f3f3f3;
            padding: 0.7rem 0.5rem;
            outline: none;
          }
        }
      }
      .tenant-dashboard-widget-stats {
        display: flex;
        flex-wrap: wrap;
        .tenant-dashboard-widget-stats-item {
          flex: 1;
          padding: 1rem;
          border-right: 1px solid #f3f3f3;
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            color: $primary_color;
          }
          h3 {
            font-size: 0.8rem;
            font-weight: 400;
          }
          &:last-child {
            border-right: none;
          }
          &.danger {
            h1 {
              color: #ad0000;
            }
          }
        }
      }
    }
  }
}
.container {
  width: 100%;
  padding: 0 1rem;
}
#AdminMessages {
  padding: 2rem;
  .message-component-title {
    margin-bottom: 1rem;
    h1 {
      font-size: 1.1rem;
      font-weight: 500;
      color: $secondary-color;
    }
  }
  .messages-widget {
    display: grid;
    border-radius: 5px;
    gap: 4px;
    grid-template-columns: 420px 1fr;
    @media (max-width: 1200px) {
      grid-template-columns: 300px 1fr;
    }
    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }
    .messages-widget-left {
      background-color: #fff;
      width: 100%;
      padding: 1rem;
      border-right: 1px solid #f0f0f0;
      border-radius: 5px;
      overflow: hidden;
      @media (max-width: 992px) {
        display: none;
      }
      .search {
        position: relative;
        input {
          width: 100%;
          padding: 0.65rem;
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          outline: none;
          font-size: 0.85rem;
        }
        i {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          color: #f0f0f0;
        }
      }
      .message-label {
        margin-top: 0.9rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        label {
          font-size: 1.2rem;
          font-weight: 600;
          color: $primary-color;
        }
      }
      .chat-categories {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background-color: #f6f9fc;
        border-radius: 5px;
        padding: 0.35rem;
        .chat-category-item {
          padding: 0.7rem 1rem;
          background: #f6f9fc;
          cursor: pointer;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          label {
            cursor: pointer;
            font-size: 0.8rem;
            font-weight: 600;
            color: #687d92;
          }
          &.active {
            background-color: $primary-color;
            label {
              color: #fff;
            }
          }
        }
      }
      .chats-list {
        margin-top: 1rem;
        max-height: 70vh;
        height: calc(62vh - 3.5rem);
        overflow-y: auto;
        .chat-item {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          border-bottom: 1px solid #f0f0f0;
          cursor: pointer;
          &:hover {
            background-color: #f6f9fc;
          }
          &.active {
            background-color: #f6f9fc;
          }
          .chat-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            background: #f4f4f4;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .chat-item-left {
            display: grid;
            grid-template-columns: 50px 1fr;
            width: 100%;
            .chat-item-left-text {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 1rem;
              width: 100%;
              .chat-name {
                display: flex;
                justify-content: space-between;
                h5 {
                  font-size: 0.9rem;
                  font-weight: 600;
                  color: $secondary-color;
                  margin-bottom: 0;
                }
                p {
                  font-size: 0.8rem;
                  color: #687d92;
                  font-weight: 400;
                  margin-top: 0.2rem;
                }
              }
              .last-message {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                p {
                  font-size: 0.8rem;
                  color: #687d92;
                  font-weight: 400;
                  margin-top: 0.2rem;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }
            }
          }
          .chat-item-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            p {
              font-size: 0.8rem;
              color: #687d92;
              font-weight: 400;
              margin-top: 0.15rem;
              margin-bottom: 5px;
            }
          }
          .message-button {
            button {
              background-color: $primary-color;
              color: #fff;
              border: none;
              padding: 0.5rem 0.8rem;
              border-radius: 5px;
              cursor: pointer;
              i {
                font-size: 0.8rem;
              }
              &:hover {
                background-color: darken($primary-color, 10%);
              }
            }
          }
        }
        .contact-item {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          border-bottom: 1px solid #f0f0f0;
          cursor: pointer;
          flex-wrap: wrap;
          &:hover {
            background-color: #f6f9fc;
          }
          .contact-item-left {
            display: grid;
            grid-template-columns: 50px 1fr;
            width: calc(100% - 50px);
            overflow: hidden;
            .contact-img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              overflow: hidden;
              background: #f4f4f4;
              display: block;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .contact-name {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 1rem;
              h5 {
                font-size: 0.9rem;
                font-weight: 600;
                color: $secondary-color;
              }
              p {
                font-size: 0.8rem;
                color: #687d92;
                font-weight: 400;
                margin-top: 0.2rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }
          .message-button {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
              background-color: $primary-color;
              color: #fff;
              border: none;
              padding: 0.5rem 0.8rem;
              border-radius: 5px;
              cursor: pointer;
              i {
                font-size: 0.8rem;
              }
              &:hover {
                background-color: darken($primary-color, 10%);
              }
            }
          }
        }
      }
      .search-contact {
        margin-top: 1rem;
        input {
          width: 100%;
          padding: 0.65rem;
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          outline: none;
          font-size: 0.85rem;
        }
        i {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          color: #f0f0f0;
        }
      }
    }
    .messages-widget-right {
      background-color: #fff;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      .chat-header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        .chat-header-left {
          display: flex;
          align-items: center;
          .chat-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            background: #f4f4f4;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .chat-name {
            margin-left: 1rem;
            h5 {
              font-size: 0.9rem;
              font-weight: 600;
              color: $secondary-color;
            }
            p {
              font-size: 0.8rem;
              color: #687d92;
              font-weight: 400;
              margin-top: 0.2rem;
            }
          }
        }
        .chat-header-right {
          display: flex;
          gap: 1rem;
          a {
            color: #687d92;
            background-color: #f6f9fc;
            padding: 0.5rem;
            border-radius: 5px;
            i {
              font-size: 1.2rem;
              cursor: pointer;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
      .chat-messages {
        max-height: 70vh;
        height: 62vh;
        overflow-y: auto;
        padding-bottom: 1rem;
        .chat-message {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          .received-message {
            display: flex;
            max-width: 75%;
            .chat-message-right {
              .message-bubble {
                background-color: $primary-color;
                padding: 0.75rem 1rem;
                border-radius: 0px 10px 10px 10px;
                p {
                  font-size: 0.9rem;
                  color: #fff;
                  font-weight: 400;
                  margin-bottom: 0.15rem;
                  line-height: 1.5;
                }
              }
              p.date {
                font-size: 0.75rem;
                color: #687d92;
                margin-top: 0.5rem;
              }
            }
          }
          .sent-message {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            margin-left: auto;
            width: 100%;
            .chat-message-right {
              max-width: 75%;
              .message-bubble {
                background-color: #f6f9fc;
                padding: 0.75rem 1rem;
                border-radius: 10px 0px 10px 10px;
                p {
                  font-size: 0.9rem;
                  color: #687d92;
                  font-weight: 400;
                  margin-bottom: 0.15rem;
                  line-height: 1.5;
                }
              }
              p.date {
                font-size: 0.75rem;
                color: #687d92;
                margin-top: 0.5rem;
              }
            }
          }
        }
      }
      .chat-input {
        display: flex;
        align-items: center;
        padding: 1rem;
        gap: 1rem;
        background-color: #fcfdff;
        border-top: 1px solid #f0f0f0;
        input {
          width: 100%;
          padding: 0.65rem;
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          outline: none;
          font-size: 0.85rem;
          background-color: #fff;
        }
        button {
          background-color: $primary-color;
          color: #fff;
          border: none;
          padding: 0.5rem 0.8rem;
          border-radius: 5px;
          cursor: pointer;
          i {
            font-size: 0.8rem;
          }
          &:hover {
            background-color: darken($primary-color, 10%);
          }
        }
      }
    }
  }
}
.ant-tag {
  font-weight: 600;
  margin-right: 0;
}
</style>
