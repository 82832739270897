import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/site/Home.vue";
import AdminLogin from "../views/admin/Login.vue";
import Admin from "../views/admin/Home.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import TenantDashboard from "../views/site/TenantDashboard.vue";
import TenantBills from "../views/site/TenantBills.vue";
import TenantSubscriptions from "../views/site/TenantSubscriptions.vue";
import ViewSubscription from "../views/site/ViewSubscription.vue";
import TenantCancellationRequests from "../views/site/TenantCancellationRequests.vue";
import TenantMessages from "../views/site/TenantMessages.vue";
import TenantPosts from "../views/site/TenantPosts.vue";
import filesRoutes from "./files";
import SAdminRoutes from "./SAdminRoutes";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/tenant/dashboard",
    name: "TenantDashboard",
    component: TenantDashboard,
  },
  {
    path: "/tenant/bills",
    name: "TenantBills",
    component: TenantBills,
  },
  {
    path: "/tenant/subscriptions",
    name: "TenantSubscriptions",
    component: TenantSubscriptions,
  },
  {
    path: "/tenant/view-subscription/:id",
    name: "ViewSubscription",
    component: ViewSubscription,
  },
  {
    path: "/tenant/cancellations",
    name: "TenantCancellationRequests",
    component: TenantCancellationRequests,
  },
  {
    path: "/tenant/messages",
    name: "TenantMessages",
    component: TenantMessages,
  },
  {
    path: "/tenant/posts",
    name: "TenantPosts",
    component: TenantPosts,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: "/admin/dashboard",
    children: [...SAdminRoutes, ...filesRoutes],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
