<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Events', 'read')">
      <h1 class="page-title">
        <span
          >Events <small>({{ allItemsCount }})</small></span
        ><a
          href=""
          class="my-btn cw glow wd"
          @click.prevent="startAdd = true"
          v-if="$access('Events', 'write')"
        >
          Create Event
        </a>
      </h1>
      <div class="my-table">
        <form class="table-filters">
          <fieldset>
            <legend>Date</legend>
            <div class="form-group">
              <input
                type="date"
                id="start_date"
                class="form-control"
                v-model="searchQuery.date"
              />
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow" @click.prevent="getItems()">
              Filter
            </button>
          </div>
        </form>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>start</th>
              <th>end</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="allItems">
            <tr v-for="item in allItems" :key="item.wing_id">
              <td>
                <img :src="$upload(item.image)" alt="" />
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.start_date }}</td>
              <td>{{ item.end_date }}</td>

              <td>
                <a-dropdown-button>
                  Actions
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                        v-if="$access('Events', 'write')"
                        >Edit</a-menu-item
                      >
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                        v-if="$access('Events', 'delete')"
                        >Delete</a-menu-item
                      >
                    </a-menu>
                  </template>
                  <template #icon><i class="fa fa-angle-down"></i></template>
                </a-dropdown-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Modal
        :title="'Delete Event'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this event?</p>
      </Modal>
      <Modal
        :title="'Create Event'"
        v-model:open="startAdd"
        @closeModel="startAdd = false"
        :okText="'Create'"
        @ok="createItem()"
        width="800px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="title">Title:</label>
            <input
              type="text"
              id="title"
              v-model="newItem.title"
              placeholder="Title"
              class="form-control"
            />
          </div>
          <div class="input-group">
            <label for="short_description">Short Description:</label>
            <textarea
              id="short_description"
              v-model="newItem.short_description"
              placeholder="Short Description"
              class="form-control"
            ></textarea>
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="start_date">Start Date:</label>
            <input
              type="date"
              id="start_date"
              v-model="newItem.start_date"
              placeholder="Start Date"
              class="form-control"
            />
          </div>
          <div class="input-group">
            <label for="end_date">End Date:</label>
            <input
              type="date"
              id="end_date"
              v-model="newItem.end_date"
              placeholder="End Date"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="image">Image:</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="input-group">
            <label for="other_images">Other Images:</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  newItem.other_images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.other_images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="content">Content:</label>
            <quill-editor
              theme="snow"
              v-model:content="newItem.content"
              contentType="html"
              placeholder="Write here..."
            >
            </quill-editor>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="organizer_info">Organizer Info:</label>
            <quill-editor
              theme="snow"
              v-model:content="newItem.organizer_info"
              contentType="html"
              placeholder="Write here..."
            >
            </quill-editor>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="tickets_link">Tickets Link:</label>
            <input
              type="text"
              id="tickets_link"
              v-model="newItem.tickets_link"
              placeholder="Tickets Link"
              class="form-control"
            />
          </div>
        </div>
      </Modal>
      <Modal
        :title="'Update Event'"
        v-model:open="startEdit"
        @closeModel="startEdit = false"
        :okText="'Update'"
        @ok="updateItem()"
        width="800px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="title">Title:</label>
            <input
              type="text"
              id="title"
              v-model="selectedItem.title"
              placeholder="Title"
              class="form-control"
            />
          </div>
          <div class="input-group">
            <label for="short_description">Short Description:</label>
            <textarea
              id="short_description"
              v-model="selectedItem.short_description"
              placeholder="Short Description"
              class="form-control"
            ></textarea>
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="start_date">Start Date:</label>
            <input
              type="date"
              id="start_date"
              v-model="selectedItem.start_date"
              placeholder="Start Date"
              class="form-control"
            />
          </div>
          <div class="input-group">
            <label for="end_date">End Date:</label>
            <input
              type="date"
              id="end_date"
              v-model="selectedItem.end_date"
              placeholder="End Date"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="image">Image:</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="input-group">
            <label for="other_images">Other Images:</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  selectedItem.other_images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.other_images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="content">Content:</label>
            <quill-editor
              theme="snow"
              v-model:content="selectedItem.content"
              contentType="html"
              placeholder="Write here..."
            >
            </quill-editor>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="organizer_info">Organizer Info:</label>
            <quill-editor
              theme="snow"
              v-model:content="selectedItem.organizer_info"
              contentType="html"
              placeholder="Write here..."
            >
            </quill-editor>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="tickets_link">Tickets Link:</label>
            <input
              type="text"
              id="tickets_link"
              v-model="selectedItem.tickets_link"
              placeholder="Tickets Link"
              class="form-control"
            />
          </div>
        </div>
      </Modal>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>
    
    <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      allItems: null,
      newItem: {
        house_id: "",
        title: "",
        short_description: "",
        content: "",
        start_date: "",
        end_date: "",
        tickets_link: "",
        image: "",
        other_images: "",
        organizer_info: "",
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      searchQuery: {
        date: "",
        search: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_EVENTS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
            paginate: this.itemsPerpage,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            this.allItems = res.data.events.data;
            this.allItemsCount = res.data.events.total;
            this.pagesNumber = Math.ceil(
              this.allItemsCount / this.itemsPerpage
            );
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.title) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_EVENT", {
            data: {
              ...this.newItem,
              organization_id: this.$getOrganizationId(),
              house_id: this.$getHouseId(),
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.title) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_EVENT", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_EVENT", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (this.$access("Events", "read")) {
      this.getItems();
    }
  },
};
</script>
    
    <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>