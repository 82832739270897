<template>
  <div class="tenant-dashboard">
    <NavBar />
    <div class="tenant-dashboard-content">
      <div class="container">
        <TenantNav />
        <div class="dashboard-content">
          <div id="ViewSubscription">
            <div
              class="ViewSubscription-content"
              id="print-content"
              v-if="subscriptionData"
            >
              <div class="ViewSubscription-content-body">
                <div class="room-view-widget">
                  <div class="room-view-widget-right">
                    <div
                      class="room-image"
                      v-if="subscriptionData.room.room_category"
                    >
                      <img
                        :src="
                          $upload(subscriptionData.room.room_category.image)
                        "
                        alt="Room Image"
                      />
                    </div>
                    <div class="room-text-content">
                      <div class="grid-data">
                        <div class="grid-data-item">
                          <span>Wing</span>
                          <p>{{ subscriptionData.room.wing.wing_name }}</p>
                        </div>
                        <div class="grid-data-item">
                          <span>Floor</span>
                          <p>{{ subscriptionData.room.floor.floor_name }}</p>
                        </div>
                        <div class="grid-data-item">
                          <span>Category</span>
                          <p>
                            {{
                              subscriptionData.room.room_category
                                .room_category_name
                            }}
                          </p>
                        </div>
                        <div class="grid-data-item">
                          <span>Number</span>
                          <p>{{ subscriptionData.room.room_number }}</p>
                        </div>
                      </div>
                      <div class="equipments-section">
                        <h5 class="section-title">Equipments:</h5>
                        <div class="equipments-tags">
                          <span
                            class="ant-tag"
                            v-for="equipment in subscriptionData.room
                              .equipments"
                            :key="equipment.equipment_id"
                            color="#f0f0f0"
                          >
                            {{ equipment }}
                          </span>
                        </div>
                      </div>
                      <div class="details-list">
                        <h5 class="section-title">Details:</h5>
                        <div class="details-list-items">
                          <div class="details-list-item">
                            <label>Price:</label>
                            <p>
                              {{
                                $money($comma(subscriptionData.room.room_price))
                              }}
                            </p>
                          </div>
                          <div class="details-list-item">
                            <label>Payment Frequency:</label>
                            <p>{{ subscriptionData.subscription_type }}</p>
                          </div>
                          <div class="details-list-item">
                            <label>Status:</label>
                            <p>
                              <span
                                :class="'chip' + subscriptionData.status"
                                v-if="subscriptionData.status == 'active'"
                              >
                                {{ subscriptionData.status }}
                              </span>
                              <span
                                :class="'chip' + subscriptionData.status"
                                v-if="subscriptionData.status == 'Inactive'"
                              >
                                {{ subscriptionData.status }}
                              </span>
                            </p>
                          </div>
                          <div class="details-list-item">
                            <label>Started:</label>
                            <p>{{ $date(subscriptionData.started_at) }}</p>
                          </div>
                          <div class="details-list-item">
                            <label>Next Payment:</label>
                            <p>{{ $date(subscriptionData.expire_at) }}</p>
                          </div>
                          <div class="details-list-item">
                            <label>Recurring:</label>
                            <p>
                              {{ subscriptionData.recurring ? "Yes" : "No" }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="payment-details">
                        <h5 class="section-title">Payment Details:</h5>
                        <div class="payment-details-items">
                          <div
                            class="payment-details-item"
                            v-for="(
                              payment, index
                            ) in subscriptionData.payments"
                            :key="index"
                          >
                            <span>{{ payment.description }}</span>
                            <p>{{ $money($comma(payment.amount)) }}</p>
                          </div>
                        </div>
                        <h5 class="section-title">Other payments:</h5>
                        <div class="payment-details-items">
                          <div class="payment-details-item">
                            <span>Bail Amount:</span>
                            <p>
                              {{ $money($comma(subscriptionData.bail.amount)) }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="footer-buttons">
                        <router-link
                          :to="{ name: 'TenantSubscriptions' }"
                          class="primary"
                          >Go Back</router-link
                        >
                        <button
                          class="danger"
                          @click="startCancellation = true"
                        >
                          Request Cancellation
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          :title="'Cancel Subscription'"
          :size="'sm'"
          v-model:open="startCancellation"
          @closeModel="startCancellation = false"
          centered
          width="500px"
          :okText="'Submit'"
          :cancelText="'Cancel'"
          @ok="requestCancellation"
        >
          <p>We are sorry to see you go, please tell us why you are leaving.</p>
          <form action="">
            <textarea
              name="reason"
              id="reason"
              placeholder="Reason for cancellation"
              v-model="reason"
              class="form-control"
              rows="5"
            ></textarea>
            <p>
              Once this cancellation is approved the subscription will be
              terminated immediately
            </p>
          </form>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "ViewSubscription",
  data() {
    return {
      subscription_id: null,
      subscriptionData: null,
      startCancellation: false,
      reason: "",
    };
  },
  methods: {
    getSubscription() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SUBSCRIPTION", {
          token: this.$getTenantToken(),
          data: {
            subscription_id: this.subscription_id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.subscriptionData = res.data.subscription;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
    requestCancellation() {
      this.$startLoader();
      this.$store
        .dispatch("CREATE_CANCELLATION_REQUEST", {
          token: this.$getTenantToken(),
          data: {
            subscriptions: [
              { subscription_id: this.subscriptionData.subscription_id },
            ],
            reason: this.reason,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            toast.success(res.data.message);
            this.startCancellation = false;
            this.$stopLoader();
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.subscription_id = this.$route.params.id;
    if (this.subscription_id) {
      this.getSubscription();
    } else {
      this.$router.push({ name: "TenantSubscriptions" });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-dashboard {
  background: #c6cae046;
  min-height: 100vh;
}
.tenant-dashboard-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.dashboard-content {
  padding: 0 1rem;
  margin: 0 1rem;
  height: 85vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
#ViewSubscription {
  padding-bottom: 3rem;
  .room-view-widget {
    display: grid;
    gap: 1rem;
    padding: 1rem 1rem;
    grid-template-columns: 1fr;
    background: white;
    border-radius: 10px;
    border: 1px solid #fff;
    .section-title {
      font-size: 0.9rem;
      font-weight: 600;
      color: $primary_color;
      margin: 20px 0;
    }
    .room-view-widget-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      .room-image {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 10px;
          border: 5px solid #eff5f7;
        }
        .quick-buttons {
          position: absolute;
          bottom: 10px;
          right: 10px;
          display: flex;
          gap: 10px;
          button {
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 0.8rem;
            font-weight: 600;
            &.primary {
              background: $primary_color;
              color: white;
              &:hover {
                background: darken($primary_color, 10%);
              }
            }
            &.danger {
              background: #db4d4d;
              color: white;
              &:hover {
                background: darken(#db4d4d, 10%);
              }
            }
          }
        }
      }
      .room-text-content {
        .grid-data {
          display: grid;
          gap: 10px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          border-radius: 10px;
          background: #032a3a0c;
          padding: 10px;
          .grid-data-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 0 10px;
            border-right: 1px solid #00000017;
            &:nth-last-child(1) {
              border-right: none;
            }
            span {
              font-size: 0.75rem;
              color: #333;
            }
            p {
              font-size: 0.9rem;
              font-weight: 600;
            }
          }
        }
        .equipments-section {
          .equipments-tags {
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            margin-top: 5px;
            .ant-tag {
              font-size: 0.8rem;
              font-weight: 400;
              color: #333;
              background: #f0f0f0;
              border-radius: 5px;
              padding: 5px 10px;
              border: 1px solid #979797;
            }
          }
        }
        .details-list {
          margin-top: 20px;
          .details-list-items {
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr 1fr 1fr;
            .details-list-item {
              display: flex;
              gap: 5px;
              align-items: center;
              &:nth-last-child(1) {
                border-bottom: none;
              }
              label {
                font-size: 0.9rem;
                color: #333;
                font-weight: 600;
              }
              p {
                font-size: 0.9rem;
                margin-bottom: 0;
              }
              &.two-columns {
                grid-column: 1 / -1;
              }
            }
          }
        }
        .payment-details {
          background: #032a3a0c;
          padding: 5px 10px 10px 10px;
          border-radius: 10px;
          margin-top: 20px;
          .payment-details-items {
            display: grid;
            gap: 10px;
            .payment-details-item {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #b9b9b9;
              padding: 10px 0;
              &:nth-last-child(1) {
                border-bottom: none;
              }
              span {
                font-size: 0.9rem;
                color: #333;
                font-weight: 600;
              }
              p {
                font-size: 0.9rem;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    hr {
      margin: 10px 0;
      border: none;
      border: 1px solid #f0f0f0;
    }
  }
  .footer-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    button,
    a {
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      text-align: center;
      text-decoration: none;
      &.primary {
        background: $primary_color;
        color: white;
      }
      &.danger {
        background: #db4d4d;
        color: white;
      }
    }
  }
}
</style>
