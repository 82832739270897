<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Dashboard', 'read')">
      <div class="reports-header">
        <h1 class="reports-header-title">REPORTS</h1>
        <div class="reports-header-select">
          <label for="report">Current Report:</label>
          <select v-model="currentReport">
            <option v-for="report in reports" :key="report">
              {{ report }}
            </option>
          </select>
        </div>
      </div>
      <div class="reports-body">
        <SubscriptionsReport v-if="currentReport == 'Subscriptions'" />
      </div>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import Chart from "chart.js/auto";
import subscriptions from "@/store/modules/subscriptions";
import SubscriptionsReport from "./SubscriptionsReport.vue";
export default {
  components: {
    SubscriptionsReport,
  },
  data() {
    return {
      selectedDate: new Date(),
      insights: [],
      currentReport: "Subscriptions",
      reports: ["Subscriptions", "Area"],
    };
  },
  methods: {
    getItems() {},
    getPercentage(total, value) {
      return value == 0 ? 0 : ((value / total) * 100).toFixed(2);
    },
  },
  mounted() {
    if (this.$access("Dashboard", "read")) {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    padding: 1rem 2rem;
    .reports-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #e0e0e0;
      .reports-header-title {
        font-size: 1.25rem;
        font-weight: 500;
        color: #333;
        line-height: 1.5;
        margin-bottom: 0;
      }
      .reports-header-select {
        display: flex;
        align-items: center;
        gap: 1rem;
        select {
          width: 100%;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          padding: 0.5rem;
          color: #333;
          line-height: 1.5;
          outline: none;
        }
      }
    }
  }
}
</style>