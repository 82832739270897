<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span
          >Users <small>({{ allItemsCount }})</small></span
        ><a href="" class="my-btn cw glow wd" @click.prevent="startAdd = true">
          Add User
        </a>
      </h1>
      <div class="my-table">
        <form class="table-filters">
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow" @click.prevent="getItems()">
              Filter
            </button>
          </div>
        </form>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="allData">
            <tr v-for="item in allData" :key="item.id">
              <td>
                <img
                  :src="$upload(item.image)"
                  alt="user"
                  style="width: 50px; height: 50px; border-radius: 50%"
                />
              </td>
              <td>
                {{ item.name }}
                <span v-if="item.is_owner" style="color: darkred">(Owner)</span>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.type }}</td>
              <td>
                <a-dropdown-button>
                  Actions
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                        >Edit</a-menu-item
                      >
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                        >Delete</a-menu-item
                      >
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startMakeOwner = true;
                        "
                        v-if="!item.is_owner"
                        >Make Owner</a-menu-item
                      >
                      <a-menu-item
                        key="4"
                        @click="
                          selectedItem = item;
                          startRemoveOwner = true;
                        "
                        v-if="item.is_owner"
                        >Remove Owner</a-menu-item
                      >
                    </a-menu>
                  </template>
                  <template #icon><i class="fa fa-angle-down"></i></template>
                </a-dropdown-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Modal
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        :title="'Delete User'"
        :size="'sm'"
        @ok="deleteItem"
        :okText="'Delete'"
      >
        <p>Are you sure you want to delete this User?</p>
      </Modal>
      <Modal
        v-model:open="startMakeOwner"
        @closeModel="startMakeOnwer = false"
        :title="'Make User Owner'"
        :size="'sm'"
        @confirmModel="makeOwner"
        @ok="makeOwner"
        :okText="'Make Owner'"
      >
        <p>Are you sure you want to make this User an owner?</p>
      </Modal>
      <Modal
        v-model:open="startRemoveOwner"
        @closeModel="startRemoveOwner = false"
        :title="'Remove User Owner'"
        :size="'sm'"
        @confirmModel="removeOwner"
        @ok="removeOwner"
        :okText="'Remove Owner'"
      >
        <p>Are you sure you want to remove this User from owner?</p>
      </Modal>
      <Modal
        :title="'Add User'"
        :size="'sm'"
        v-model:open="startAdd"
        @closeModel="startAdd = false"
        @ok="createItem"
        :okText="'Save'"
      >
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.name"
              class="form-control"
              placeholder="Name"
            />
          </div>
          <div class="input-group">
            <label for="name">Email</label>
            <input
              type="email"
              id="name"
              v-model="newItem.email"
              class="form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Password</label>
            <input
              type="password"
              id="name"
              v-model="newItem.password"
              class="form-control"
              placeholder="password"
            />
          </div>
          <div class="input-group">
            <label for="name">Type</label>
            <select v-model="newItem.type" class="form-control">
              <option value="admin">Admin</option>
              <option value="agent">Agent</option>
            </select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
      </Modal>
      <Modal
        :title="'Edit User'"
        :size="'sm'"
        v-model:open="startEdit"
        @closeModel="startEdit = false"
        @ok="updateItem"
        :okText="'Save'"
      >
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.name"
              class="form-control"
              placeholder="Name"
            />
          </div>
          <div class="input-group">
            <label for="name">Email</label>
            <input
              type="email"
              id="name"
              v-model="selectedItem.email"
              class="form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Password</label>
            <input
              type="password"
              id="name"
              v-model="selectedItem.password"
              class="form-control"
              placeholder="password"
            />
          </div>
          <div class="input-group">
            <label for="name">Type</label>
            <select v-model="selectedItem.type" class="form-control">
              <option value="admin">Admin</option>
              <option value="agent">Agent</option>
            </select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>
  
  <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      allData: null,
      newItem: {
        name: "",
        email: "",
        password: "",
        type: "",
        organization_id: "",
        image: "",
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startMakeOwner: false,
      startRemoveOwner: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      searchQuery: {
        created_by: "all",
        search: "",
      },
      admins: null,
      organization_id: null,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ADMINS_BY_ORG", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            organization_id: this.organization_id,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.allData = res.data.data;
            this.allItemsCount = res.data.total;
            this.pagesNumber = Math.ceil(
              this.allItemsCount / this.itemsPerpage
            );
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.name) {
        this.newItem.organization_id = this.organization_id;
        this.$startLoader();
        this.$store
          .dispatch("CREATE_ADMIN", {
            data: {
              ...this.newItem,
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.name) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ADMIN", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ADMIN", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    makeOwner() {
      this.$startLoader();
      this.$store
        .dispatch("MAKE_ADMIN_OWNER", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startMakeOwner = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    removeOwner() {
      this.$startLoader();
      this.$store
        .dispatch("REMOVE_ADMIN_OWNER", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startRemoveOwner = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.organization_id = this.$route.params.id;
    this.getItems();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>