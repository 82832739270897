<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Room Classes', 'read')">
      <h1 class="page-title">
        <span
          >Room Classes <small>({{ roomClasses.length }})</small></span
        ><a
          href=""
          class="my-btn cw glow wd"
          @click.prevent="startAdd = true"
          v-if="$access('Room Classes', 'write')"
          >Add Class</a
        >
      </h1>
      <div class="my-table">
        <form class="table-filters">
          <fieldset>
            <legend>Added By</legend>
            <div class="form-group">
              <select
                id="approvedBy"
                class="form-control"
                v-model="searchQuery.created_by"
              >
                <option value="all">Anyone</option>
                <option
                  v-for="admin in admins"
                  :key="admin.admin_id"
                  :value="admin.admin_id"
                >
                  {{ admin.name }}
                </option>
              </select>
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button
              class="my-btn cw wd glow"
              type="submit"
              @click.prevent="getItems()"
            >
              Filter
            </button>
          </div>
        </form>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Rooms</th>
              <th>Equipments</th>
              <th>Added by</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roomClasses" :key="item.room_category_id">
              <td>
                <img :src="$upload(item.image)" alt="Room Category Image" />
              </td>
              <td>{{ item.room_category_name }}</td>
              <td>{{ item.rooms.length }}</td>
              <td>
                <span
                  v-for="(equipment, index) in item.equipments"
                  :key="index"
                  class=""
                >
                  {{ index < 4 ? equipment : index === 4 ? "..." : "" }}
                  {{
                    index < 3 && index != item.equipments.length - 1 ? "," : ""
                  }}
                  {{
                    index > 3 && index != item.equipments.length - 1 ? "" : ""
                  }}
                  {{ index === 4 ? item.equipments.length - 4 + " more" : "" }}
                  {{ index === 4 ? "" : "" }}
                </span>
              </td>
              <td v-if="item.created_by.name">{{ item.created_by.name }}</td>
              <td v-else>-</td>

              <td>
                <a-dropdown-button>
                  Actions
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                        v-if="$access('Room Classes', 'write')"
                        >Edit</a-menu-item
                      >
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                        v-if="$access('Room Classes', 'delete')"
                        >Delete</a-menu-item
                      >
                    </a-menu>
                  </template>
                  <template #icon><i class="fa fa-angle-down"></i></template>
                </a-dropdown-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Modal
        :title="'Delete Class'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this class?</p>
      </Modal>
      <Modal
        :title="'Add Class'"
        :size="'sm'"
        v-model:open="startAdd"
        @closeModel="startAdd = false"
        centered
        :okText="'Save'"
        @ok="createItem()"
        width="600px"
      >
        <div class="form-group">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.room_category_name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="companyEquipments">Room Equipments</label>
            <a-select
              v-model:value="newItem.equipments"
              mode="tags"
              style="width: 100%"
              :token-separators="[',']"
              placeholder="Room Equipments"
            >
              <a-select-option
                v-for="item in $store.state.equipments"
                :key="item"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </div>
          <div class="form-group">
            <label for="image">Cover Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="image">Other Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  newItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
      </Modal>
      <Modal
        :title="'Edit Class'"
        :size="'sm'"
        v-model:open="startEdit"
        @closeModel="startEdit = false"
        centered
        :okText="'Save'"
        @ok="updateItem()"
        width="600px"
      >
        <div class="form-group">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.room_category_name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="companyEquipments">Room Equipments</label>
            <a-select
              v-model:value="selectedItem.equipments"
              mode="tags"
              style="width: 100%"
              :token-separators="[',']"
              placeholder="Room Equipments"
            >
              <a-select-option
                v-for="item in $store.state.equipments"
                :key="item"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </div>
          <div class="form-group">
            <label for="image">Cover Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="image">Other Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  selectedItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
      </Modal>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>
  
  <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      roomClasses: [],
      newItem: {
        room_category_name: "",
        equipments: [],
        organization_id: this.$getOrganizationId(),
        house_id: this.$getHouseId(),
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      admins: null,
      searchQuery: {
        search: "",
        created_by: "all",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ROOM_CLASSES", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.roomClasses = res.data.data.data;
              this.allItemsCount = res.data.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.room_category_name) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_ROOM_CLASS", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.room_category_name) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ROOM_CLASS", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ROOM_CLASS", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.$getAdminsByOrg();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>