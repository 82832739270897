<template>
  <div id="NavBar">
    <div class="lg-navbar">
      <div class="nav-bar">
        <router-link class="left" :to="{ name: 'Home' }">
          <img src="/img/logob.png" alt="Ireme PMS" />
        </router-link>
        <div class="middle">
          <!-- links for real estate web  -->
          <ul class="nav-links">
            <li>
              <router-link :to="{ name: 'Home' }" class="nav-link"
                >Home</router-link
              >
            </li>
            <li>
              <a href="#about" class="nav-link">About us</a>
            </li>
            <li>
              <a href="#listing" class="nav-link">Listing</a>
            </li>
            <li>
              <a href="#contact" class="nav-link">Contact us</a>
            </li>
          </ul>
        </div>
        <div class="right">
          <router-link
            :to="{ name: 'TenantDashboard' }"
            class="nav-link contact my-btn"
            v-if="isLoggedIn"
          >
            My Account
          </router-link>
          <button
            class="nav-link contact my-btn"
            @click="handleStartLogin(true)"
            v-else
          >
            <i class="fa-solid fa-right-to-bracket"></i>
            Sign In
          </button>
        </div>
      </div>
    </div>
    <div class="sm-navbar">
      <div class="sm-nav-bar">
        <div class="left">
          <i class="fa-solid fa-bars" @click="showMobileNav = true"></i>
          <img src="/img/logob.png" alt="Ireme PMS" />
        </div>
        <div class="right">
          <router-link
            :to="{ name: 'TenantDashboard' }"
            class="nav-link contact my-btn"
            v-if="isLoggedIn"
          >
            My Account
          </router-link>
          <button
            class="nav-link contact my-btn"
            @click="handleStartLogin(true)"
            v-else
          >
            <i class="fa-solid fa-right-to-bracket"></i>
            Sign In
          </button>
        </div>
        <div class="floater-mobile-nav" :class="{ show: showMobileNav }">
          <div class="floater-mobile-nav-header">
            <img src="/img/logob.png" alt="Ireme PMS" />
            <i class="fa-solid fa-xmark" @click="showMobileNav = false"></i>
          </div>
          <div class="floater-mobile-nav-links">
            <router-link
              :to="{ name: 'Home' }"
              class="nav-link"
              @click="showMobileNav = false"
              >Home</router-link
            >
            <a href="#about" class="nav-link" @click="showMobileNav = false"
              >About us</a
            >
            <a href="#listing" class="nav-link" @click="showMobileNav = false"
              >Listing</a
            >
            <a href="#contact" class="nav-link" @click="showMobileNav = false"
              >Contact us</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showMobileNav: false,
      isLoggedIn: false,
      tenantData: null,
    };
  },
  methods: {
    handleStartLogin(value) {
      this.$emit("startLogin", value);
    },
  },
  mounted() {
    let tenant = this.$getTenant();
    if (tenant) {
      this.isLoggedIn = true;
      this.tenantData = tenant;
    } else {
      this.isLoggedIn = false;
      this.tenantData = null;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/main.scss";
#NavBar {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #fff;
  .lg-navbar {
    display: flex;
    justify-content: center;
  }
  .nav-bar {
    width: 1500px;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #000;
    justify-content: space-between;
    padding: 20px 0rem;
    width: 1500px;
    @media (max-width: 2400px) {
      width: 1400px;
    }
    @media (max-width: 1500px) {
      width: 1200px;
      padding: 10px 0rem;
    }
    @media (max-width: 1200px) {
      width: 100%;
      padding: 7px 2rem;
    }
    @media (max-width: 900px) {
      display: none;
    }
    .middle,
    .right {
      .nav-links {
        display: flex;
        list-style: none;
        align-items: center;
        margin: 0 !important;
        .nav-link {
          text-decoration: none;
          color: #000;
          padding: 0 20px;
          font-weight: 500;
          font-size: 17px;
          @media (max-width: 1500px) {
            font-size: 15px;
          }
          @media (max-width: 1200px) {
            font-size: 14px;
          }
          @media (max-width: 1000px) {
            font-size: 13px;
          }
          @media (max-width: 750px) {
            font-size: 12px;
          }
          @media (max-width: 500px) {
            font-size: 11px;
          }
          color: $secondary_color;
          &:hover {
            color: $primary_color;
          }
        }
        .drop-item {
          position: relative;
          cursor: pointer;
          padding: 20px 16px;

          @media (max-width: 1500px) {
            padding: 10px 10px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          .drop-content {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 160px;
            margin-top: 20px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            @media (max-width: 1500px) {
              margin-top: 10px;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            .drop-link {
              color: $primary_color;
              padding: 12px 16px;
              text-decoration: none;
              display: block;
              @media (max-width: 1500px) {
                padding: 10px 10px;
                font-size: 12px;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
              }
              @media (max-width: 500px) {
              }
              &:hover {
                background-color: $primary_color;
                color: #fff;
                font-weight: 800;
              }
            }
          }
          &:hover .drop-content {
            display: block;
          }
        }
        // .router-link-active,
        // .router-link-exact-active {
        //   color: $primary_color;
        // }
      }
      .my-btn {
        margin: 0 !important;
        padding: 10px 20px;
        font-size: 16px;
        i {
          margin-right: 5px;
          font-size: 14px;
        }
        @media (max-width: 1500px) {
          font-size: 15px;
          padding: 8px 15px;
          i {
            font-size: 13px;
          }
        }
      }
    }
    .left {
      img {
        width: 100px;
        @media (max-width: 1500px) {
          width: 80px;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
    }
  }
  .sm-navbar {
    display: none;
    width: 100vw;
    @media (max-width: 900px) {
      display: block;
    }
    .sm-nav-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 2rem;
      background-color: #fff;
      width: 100%;
      .left {
        display: flex;
        align-items: center;
        gap: 1rem;
        i {
          font-size: 1.5rem;
        }
        img {
          width: 80px;
        }
      }
      .right {
        button {
          padding: 10px 20px;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
      .floater-mobile-nav {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 0;
        height: 100vh;
        background-color: #fff;
        z-index: 100;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        transition: width 0.3s ease-in-out;
        overflow-x: hidden;
        &.show {
          width: 300px;
          display: block;
        }
        .floater-mobile-nav-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          img {
            width: 80px;
          }
          i {
            font-size: 1.5rem;
          }
        }
        .floater-mobile-nav-links {
          display: flex;
          flex-direction: column;
          a {
            padding: 15px 1rem;
            border-bottom: 1px solid #f1f1f1;
          }
        }
      }
    }
  }
}
</style>