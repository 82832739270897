import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_ROLES_URL = BACKEND + "/roles/getRoles";
const ADD_ROLE_URL = BACKEND + "/roles/addRole";
const UPDATE_ROLE_URL = BACKEND + "/roles/updateRole";
const DELETE_ROLE_URL = BACKEND + "/roles/deleteRole";
const GET_ROLE_URL = BACKEND + "/roles/getRole";

const GET_PERMISSIONS_URL = BACKEND + "/permissions/getPermissions";
const ADD_PERMISSION_URL = BACKEND + "/permissions/addPermission";
const UPDATE_PERMISSION_URL = BACKEND + "/permissions/updatePermission";
const DELETE_PERMISSION_URL = BACKEND + "/permissions/deletePermission";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ROLES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROLES_URL + "?page=" + payload.data.page,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["ADD_ROLE"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_ROLE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ROLE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ROLE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ROLE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ROLE_URL + "?page=" + payload.data.page,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ROLE"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ROLE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_PERMISSIONS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_PERMISSIONS_URL,
        data: payload.data,
      });
    },
    ["ADD_PERMISSION"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_PERMISSION_URL,
        data: payload.data,
      });
    },
    ["UPDATE_PERMISSION"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_PERMISSION_URL,
        data: payload.data,
      });
    },
    ["DELETE_PERMISSION"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_PERMISSION_URL,
        data: payload.data,
      });
    },
  },
};
