import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_ALL_EVENTS = BACKEND + "/events/getAllEvents";
const GET_EVENTS = BACKEND + "/events/getEvents";
const GET_EVENT_BY_ID = BACKEND + "/events/getEventById";
const CREATE_EVENT = BACKEND + "/events/createEvent";
const UPDATE_EVENT = BACKEND + "/events/updateEvent";
const DELETE_EVENT = BACKEND + "/events/deleteEvent";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ALL_EVENTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_EVENTS + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_EVENTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_EVENTS + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_EVENT_BY_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_EVENT_BY_ID,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_EVENT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_EVENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_EVENT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_EVENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_EVENT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_EVENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
