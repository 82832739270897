<template>
  <div class="tenant-dashboard">
    <NavBar />
    <div class="tenant-dashboard-content">
      <div class="container">
        <TenantNav />
        <div class="dashboard-content">
          <div class="dashboard-content-left">
            <div class="dashboard-content-header">
              <h3>Subscriptions</h3>
            </div>
            <div class="dashboard-content-body">
              <div class="tenant-dashboard-widget">
                <div class="tenant-dashboard-widget-header">
                  <h3>Subscriptions</h3>
                </div>
                <div
                  class="tenant-dashboard-widget-body"
                  v-if="tenantSubscriptions.length > 0"
                >
                  <div class="my-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Room</th>
                          <th>Start Date</th>
                          <th>Next Billing Date</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(subscription, index) in tenantSubscriptions"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ subscription.room.room_number }}</td>
                          <td>{{ $date(subscription.started_at) }}</td>
                          <td>{{ $date(subscription.expire_at) }}</td>
                          <td>
                            <small :class="'chip ' + subscription.status">{{
                              subscription.status
                            }}</small>
                          </td>
                          <td>
                            {{ $comma($fp(subscription.amount_to_be_paid)) }}
                          </td>
                          <td>
                            <a-dropdown-button>
                              Actions
                              <template #overlay>
                                <a-menu>
                                  <a-menu-item
                                    key="1"
                                    @click="
                                      selectedItem = subscription;
                                      startViewSubscription = true;
                                    "
                                  >
                                    <router-link
                                      :to="{
                                        name: 'ViewSubscription',
                                        params: {
                                          id: subscription.subscription_id,
                                        },
                                      }"
                                      >View Details</router-link
                                    >
                                  </a-menu-item>
                                  <a-menu-item
                                    key="2"
                                    @click="
                                      selectedItem = subscription;
                                      startViewSubscription = true;
                                    "
                                  >
                                    <span
                                      @click="
                                        selectedItem = subscription;
                                        startCancellation = true;
                                      "
                                      >Request Cancellation</span
                                    >
                                  </a-menu-item>
                                </a-menu>
                              </template>
                              <template #icon
                                ><i class="fa fa-angle-down"></i
                              ></template>
                            </a-dropdown-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="pagination">
                      <button
                        :class="{ disabled: currentPage === 1 }"
                        @click="$previousPage()"
                      >
                        <label>Previous</label>
                      </button>
                      <p v-if="currentPage > 3">...</p>
                      <button
                        class="numbers"
                        v-for="number in pagesNumber"
                        :key="number"
                      >
                        <label
                          :class="{ active: currentPage === number }"
                          for=""
                          v-if="
                            (number == 1 && currentPage <= 2) ||
                            (currentPage + 2 >= pagesNumber &&
                              number >= pagesNumber - 2) ||
                            (number >= currentPage - 2 &&
                              number <= currentPage + 2)
                          "
                          @click="$changePage(number)"
                        >
                          {{ number }}
                        </label>
                      </button>
                      <p v-if="currentPage <= pagesNumber - 3">...</p>
                      <button
                        :class="{
                          disabled: currentPage === pagesNumber.length,
                        }"
                        @click="$nextPage()"
                      >
                        <label>Next</label>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="no-data" v-else>
                  <i>No subscriptions found</i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          :title="'Cancel Subscription'"
          :size="'sm'"
          v-model:open="startCancellation"
          @closeModel="startCancellation = false"
          centered
          width="500px"
          :okText="'Submit'"
          :cancelText="'Cancel'"
          @ok="requestCancellation"
        >
          <p>We are sorry to see you go, please tell us why you are leaving.</p>
          <form action="">
            <textarea
              name="reason"
              id="reason"
              placeholder="Reason for cancellation"
              v-model="reason"
              class="form-control"
              rows="5"
            ></textarea>
            <p>
              Once this cancellation is approved the subscription will be
              terminated immediately
            </p>
          </form>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      allItemsCount: 0,
      tenantSubscriptions: [],
      selectedItems: [],
      allSelected: false,
      startViewSubscription: false,
      selectedItem: null,
      popularPosts: [],
      startCancellation: false,
      reason: "",
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_SUBSCRIPTIONS", {
          token: this.$getTenantToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tenantSubscriptions = res.data.subscriptions.data;
              this.pagesNumber = Math.ceil(
                res.data.subscriptions.total / this.itemsPerpage
              );
              this.allItemsCount = res.data.subscriptions.total;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
    requestCancellation() {
      this.$startLoader();
      this.$store
        .dispatch("CREATE_CANCELLATION_REQUEST", {
          token: this.$getTenantToken(),
          data: {
            subscriptions: [
              { subscription_id: this.selectedItem.subscription_id },
            ],
            reason: this.reason,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            toast.success(res.data.message);
            this.startCancellation = false;
            this.$stopLoader();
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-dashboard {
  background: #c6cae046;
  min-height: 100vh;
}
.tenant-dashboard-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.dashboard-content {
  padding: 0 1rem;
  margin: 0 1rem;
  height: 85vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .dashboard-content-left {
    .dashboard-content-header {
      h3 {
        font-size: 1.15rem;
        font-weight: 500;
      }
    }
    .tenant-dashboard-widget {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      margin: 1rem 0;
      .tenant-dashboard-widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }
        form {
          width: 300px;
          input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #f3f3f3;
            padding: 0.7rem 0.5rem;
            outline: none;
          }
        }
      }
      .tenant-dashboard-widget-stats {
        display: flex;
        flex-wrap: wrap;
        .tenant-dashboard-widget-stats-item {
          flex: 1;
          padding: 1rem;
          border-right: 1px solid #f3f3f3;
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            color: $primary_color;
          }
          h3 {
            font-size: 0.8rem;
            font-weight: 400;
          }
          &:last-child {
            border-right: none;
          }
          &.danger {
            h1 {
              color: #ad0000;
            }
          }
        }
      }
    }
  }
}
.container {
  width: 100%;
  padding: 0 1rem;
}
</style>
