<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Dashboard', 'read')">
      <h1 class="page-title">ANALYTICS</h1>
      <div class="bottom-dash mb-10">
        <div
          class="dash-item"
          v-for="(insight, index) in insights"
          :key="index"
        >
          <div class="left">
            <p class="title">{{ insight.title }}</p>
            <p class="count">{{ $comma(insight.total) }}</p>
            <div class="today">
              <div class="percentage">
                <span
                  >{{ getPercentage(insight.total, insight.this_month) }}%</span
                >
                <i class="fas fa-arrow-up"></i>
              </div>
              <div class="people">+ {{ insight.this_month }} this month</div>
            </div>
          </div>
          <div class="right">
            <div class="blue-dot">
              <div class="inner-dot"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-dash">
        <div class="dash-item">
          <div class="dash-item-header">
            <div class="left">
              <h2>SUBSCRIPTIONS</h2>
              <p>Subscribers count</p>
            </div>
          </div>
          <div class="dash-item-body">
            <canvas id="myChart" width="400" height="400"></canvas>
          </div>
        </div>
        <div class="dash-item">
          <div class="dash-item-header">
            <div class="left">
              <h2>AREA</h2>
              <p>Occupied and unoccupied area</p>
            </div>
          </div>
          <div class="dash-item-body">
            <canvas id="pieChart" width="400" height="300"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import Chart from "chart.js/auto";
import subscriptions from "@/store/modules/subscriptions";
export default {
  data() {
    return {
      selectedDate: new Date(),
      insights: [],
      subscriptions: {
        thisYear: [200, 210, 195, 215, 120, 230, 140, 225, 210, 205, 210, 222],
        lastYear: [190, 200, 175, 200, 200, 212, 200, 110, 195, 202, 198, 203],
      },
      area: {
        total: 0,
        occupied: 0,
        unOccupied: 0,
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_STATISTICS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.insights = res.data.insights;
              this.subscriptions = res.data.subscriptions;
              this.area = res.data.area;

              const ctx = document.getElementById("myChart").getContext("2d");
              const myChart = new Chart(ctx, {
                type: "line",
                data: {
                  labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ],
                  datasets: [
                    {
                      label: "This Year (" + new Date().getFullYear() + ")",
                      data: this.subscriptions.thisYear,
                      backgroundColor: ["#008800"],
                      borderColor: ["#008800"],
                      borderWidth: 2,
                      tension: 0.5,
                    },
                    {
                      label:
                        "Last Year (" + (new Date().getFullYear() - 1) + ")",
                      data: this.subscriptions.lastYear,
                      backgroundColor: ["#5468ff"],
                      borderColor: ["#5468ff"],
                      borderWidth: 2,
                      tension: 0.5,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                },
              });

              const ctxPie = document
                .getElementById("pieChart")
                .getContext("2d");
              const pieChart = new Chart(ctxPie, {
                type: "doughnut",
                data: {
                  labels: [
                    "Occupied (" +
                      this.getPercentage(this.area.total, this.area.occupied) +
                      "%)",
                    "Available (" +
                      this.getPercentage(
                        this.area.total,
                        this.area.unOccupied
                      ) +
                      "%)",
                    "In Repair (" +
                      this.getPercentage(this.area.total, this.area.inRepair) +
                      "%)",
                    "Unavailable (" +
                      this.getPercentage(
                        this.area.total,
                        this.area.unAvailable
                      ) +
                      "%)",
                  ],
                  datasets: [
                    {
                      label: "Area",
                      data: [
                        this.area.occupied,
                        this.area.unOccupied,
                        this.area.inRepair,
                        this.area.unAvailable,
                      ],
                      backgroundColor: [
                        "#008800",
                        "#5468ff",
                        "#ffe000",
                        "#888",
                      ],
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: false,
                      text: "Area",
                    },
                  },
                },
              });
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error(err.response.data.message);
            this.$AdminLogout();
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getPercentage(total, value) {
      return value == 0 ? 0 : ((value / total) * 100).toFixed(2);
    },
  },
  mounted() {
    if (this.$access("Dashboard", "read")) {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    padding: 0 20px;
    .top-dash {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 2rem;
      @media (max-width: 1300px) {
        grid-template-columns: 1fr;
      }
      .dash-item {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        .dash-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 2rem;
          padding-bottom: 0;
          .left {
            h2 {
              font-size: 1.5rem;
              font-weight: 600;
              @media (max-width: 1700px) {
                font-size: 1.2rem;
              }
              @media (max-width: 1500px) {
                font-size: 1.1rem;
              }
              @media (max-width: 1300px) {
                font-size: 1rem;
              }
              @media (max-width: 900px) {
                font-size: 0.95rem;
              }
            }
            p {
              font-size: 0.9rem;
              color: #888;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
              }
              @media (max-width: 1500px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.6rem;
              }
              @media (max-width: 900px) {
                font-size: 0.55rem;
              }
            }
          }
        }
        .dash-item-body {
          padding: 20px;
          border-radius: 10px;

          .circular-progress {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            .circular-progress-circle {
              position: relative;
              width: 170px;
              height: 170px;
              background: transparent;
              border-radius: 50%;
              overflow: hidden;
              @media (max-width: 1700px) {
                width: 150px;
                height: 150px;
              }
              @media (max-width: 1500px) {
                width: 130px;
                height: 130px;
              }
              @media (max-width: 1300px) {
                width: 110px;
                height: 110px;
              }
              @media (max-width: 900px) {
                width: 90px;
                height: 90px;
              }
            }

            .segment {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100vw;
              height: 100vw;
              background: $primary_color;
              transform-origin: 0 0;
            }

            .circular-progress-inner {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 150px;
              height: 150px;
              transform: translateX(-50%) translateY(-50%);
              background: #fff;
              border-radius: 50%;
            }

            .circular-progress-value {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              p {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 5px;
                @media (max-width: 1700px) {
                  font-size: 1.3rem;
                }
                @media (max-width: 1500px) {
                  font-size: 1.1rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.9rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.8rem;
                }
              }
              span {
                font-size: 0.9rem;
                color: $primary_color;
                background: rgba(12, 2, 68, 0.993) !important;
                padding: 3px 5px;
                display: inline-block;
                border-radius: 5px;
                font-weight: 600;
              }
            }
          }
          .arc-text {
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0 2rem;
            p {
              font-size: 0.9rem;
              color: #000;
              text-align: center;
            }
          }
          .arc-stats {
            display: flex;
            justify-content: space-between;
            .stat-item {
              width: 30%;
              padding: 10px;
              border-radius: 5px;
              background-color: #fff;
              .top {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                p {
                  font-size: 0.9rem;
                  color: #646464;
                }
              }
              .bottom {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 5px;
                span {
                  font-size: 1.2rem;
                  font-weight: 600;
                }
                i {
                  font-size: 0.9rem;
                  color: $primary_color;
                }
              }
            }
          }

          #myChart {
            width: 100%;
            max-height: 300px;
          }
        }
      }
    }
    .bottom-dash {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
      margin-top: 20px;
      flex-wrap: wrap;
      @media (max-width: 1700px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @media (max-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }

      .dash-item {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        border-radius: 10px;
        .left {
          .title {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 5px;
            text-transform: uppercase;
            color: #666;
            @media (max-width: 1700px) {
              font-size: 0.9rem;
            }
            @media (max-width: 1500px) {
              font-size: 0.8rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media (max-width: 900px) {
              font-size: 0.6rem;
            }
          }
          .count {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 5px;
            @media (max-width: 1700px) {
              font-size: 1.8rem;
            }
            @media (max-width: 1500px) {
              font-size: 1.6rem;
            }
            @media (max-width: 1300px) {
              font-size: 1.4rem;
            }
            @media (max-width: 900px) {
              font-size: 1.2rem;
            }
          }
          .today {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 10px;
            .percentage {
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 5px;
              span {
                font-size: 0.9rem;
                color: $primary_color;
                font-weight: 600;
                @media (max-width: 1700px) {
                  font-size: 0.8rem;
                }
                @media (max-width: 1500px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.6rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.55rem;
                }
              }
              i {
                font-size: 0.9rem;
                color: $primary_color;
                @media (max-width: 1700px) {
                  font-size: 0.8rem;
                }
                @media (max-width: 1500px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.6rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.55rem;
                }
              }
            }
            .people {
              font-size: 0.9rem;
              color: #888;
              font-weight: 500;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
              }
              @media (max-width: 1500px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.6rem;
              }
              @media (max-width: 900px) {
                font-size: 0.55rem;
              }
            }
          }
        }
        .right {
          .blue-dot {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: dot 1s infinite;
            @media (max-width: 1700px) {
              width: 30px;
              height: 30px;
            }
            @media (max-width: 1500px) {
              width: 25px;
              height: 25px;
            }
            @media (max-width: 1300px) {
              width: 20px;
              height: 20px;
            }
            @media (max-width: 900px) {
              width: 15px;
              height: 15px;
            }
            @keyframes dot {
              0% {
                background: transparent;
              }
              50% {
              }
              100% {
                background-color: rgba(36, 184, 204, 0.24);
              }
            }
            .inner-dot {
              width: 17px;
              height: 17px;
              border-radius: 50%;
              background-color: $primary_color;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.137);
            }
          }
        }
      }
    }
  }
}
</style>