<template>
  <div class="home">
    <NavBar @startLogin="showLogin = true" />
    <div class="jumbotron" style="background-image: url('/img/centenary.jpg')">
      <div class="jumbo-content">
        <label for="" class="welcome">
          Welcome to <span>IREME PMS</span>
        </label>
        <label for="" class="welcome-text">
          Invest today in your dream office
        </label>
        <p>
          Duis ultricies, tortor a accumsan fermentum, purus diam mollis velit,
          eu bibendum ipsum erat quis leo. Vestibulum finibus, leo dapibus
          feugiat rutrum, augue lacus rhoncus velit, vel scelerisque odio est.
        </p>
        <div class="jumbo-btns">
          <button class="btn btn-primary">View Rooms</button>
          <button class="btn btn-secondary">Contact Us</button>
        </div>
        <form action="" class="filter-form">
          <input
            type="Keywords"
            placeholder="Enter Keywords"
            class="form-control"
          />
          <select name="type" id="" class="form-control">
            <option value="Commercial">Commercial</option>
            <option value="Residential">Residential</option>
          </select>
          <select name="house" id="" class="form-control">
            <option value="Centenary house Kigali">
              Centenary house Kigali
            </option>
            <option value="Muhima Apartments">Muhima Apartments</option>
          </select>
          <button class="btn btn-primary">Search</button>
        </form>
      </div>
    </div>
    <div class="available-rooms">
      <p class="site-title">Available Rooms</p>
      <label for="" class="site-label">Explore our available spaces</label>
      <div class="available-rooms-cards">
        <div class="available-room-card">
          <i class="fa-solid fa-building"></i>
          <div class="available-room-card-content">
            <p class="available-room-card-title">Residential</p>
            <p class="available-room-card-text">22 Properties</p>
          </div>
        </div>
        <div class="available-room-card">
          <i class="fa-solid fa-hotel"></i>
          <div class="available-room-card-content">
            <p class="available-room-card-title">Commercial</p>
            <p class="available-room-card-text">22 Properties</p>
          </div>
        </div>
        <div class="available-room-card">
          <i class="fa-solid fa-building"></i>
          <div class="available-room-card-content">
            <p class="available-room-card-title">Office</p>
            <p class="available-room-card-text">22 Available</p>
          </div>
        </div>
        <div class="available-room-card">
          <i class="fa-solid fa-bed"></i>
          <div class="available-room-card-content">
            <p class="available-room-card-title">Units</p>
            <p class="available-room-card-text">22 Available</p>
          </div>
        </div>
      </div>
    </div>

    <div id="listing">
      <div class="container">
        <p class="site-title">Listing</p>
        <label for="" class="site-label">Explore our available rooms</label>
        <div class="listing-cards">
          <swiper
            :slides-per-view="3"
            :space-between="50"
            :modules="modules"
            :loop="true"
            :speed="1000"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :breakpoints="{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }"
          >
            <swiper-slide v-for="room in processedRooms" :key="room.id">
              <div class="listing-card">
                <div class="listing-card-content">
                  <div class="listing-card-image">
                    <img :src="$upload(room.image)" alt="" />
                    <div class="listing-card-content-available">
                      <span>{{ room.availableRooms }}</span>
                      <span>Available</span>
                    </div>
                  </div>
                  <div class="listing-card-content">
                    <h3 class="listing-card-title">
                      {{ room.room_category_name }}
                    </h3>
                    <h4 class="listing-card-text">
                      {{ room.house.house_name }}
                    </h4>
                    <div class="equipments">
                      <span
                        v-for="(equipment, index) in room.equipments"
                        :key="index"
                        :class="index > 2 ? 'none' : ''"
                      >
                        {{ equipment }}
                      </span>
                      <span
                        v-if="
                          room.equipments.length > 3 &&
                          room.equipments.length != 4
                        "
                      >
                        + {{ room.equipments.length - 3 }} more
                      </span>
                      <span v-if="room.equipments.length == 4">
                        {{ room.equipments[3] }}
                      </span>
                    </div>
                    <div class="listing-card-footer">
                      <h3 class="listing-card-price">
                        {{ $money(room.rooms[0].room_price) }}
                      </h3>
                      <button class="btn btn-primary">View Room</button>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div id="about">
      <div class="container">
        <div class="about-left">
          <img src="/img/centenary.jpg" alt="" />
          <img src="/img/centenary-house.jpg" alt="" class="img-2" />
        </div>
        <div class="about-right">
          <p class="site-title">About</p>
          <label for="" class="site-label">
            Conference Room | Executive Private Offices
          </label>
          <p class="about-text">
            Duis pulvinar metus elit, ut aliquam est sollicitudin finibus.
            Integer lobortis est interdum. Suspendisse nunc est, varius quis
            fringilla ac, commodo a ante. Praesent non elit cursus, aliquam
            sapien quis, dictum tortor.
          </p>
          <div class="qualities">
            <div class="quality">
              <i class="fa-solid fa-check"></i>
              <p>Beautiful Scene Around</p>
            </div>
            <div class="quality">
              <i class="fa-solid fa-check"></i>
              <p>Exceptional Open Space</p>
            </div>
            <div class="quality">
              <i class="fa-solid fa-check"></i>
              <p>High Speed Internet</p>
            </div>
            <div class="quality">
              <i class="fa-solid fa-check"></i>
              <p>Complete 24/7 Security</p>
            </div>
          </div>
          <button class="btn btn-primary">View Rooms</button>
        </div>
      </div>
    </div>
    <div id="how-it-works">
      <div class="container">
        <p class="site-title">How it works</p>
        <label for="" class="site-label"> Our simple 4 step process </label>
        <div class="how-it-works-cards">
          <div class="how-it-works-card">
            <i class="fa-solid fa-building"></i>
            <p class="how-it-works-card-title">Choose a Room</p>
            <p class="how-it-works-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div class="how-it-works-card">
            <i class="fa-solid fa-calendar-days"></i>
            <p class="how-it-works-card-title">Choose a Date</p>
            <p class="how-it-works-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div class="how-it-works-card">
            <i class="fa-solid fa-file-contract"></i>
            <p class="how-it-works-card-title">Sign a Contract</p>
            <p class="how-it-works-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div class="how-it-works-card">
            <i class="fa-solid fa-key"></i>
            <p class="how-it-works-card-title">Get the Key</p>
            <p class="how-it-works-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="need-help" id="contact">
      <div class="container">
        <div class="need-help-left">
          <label for="" class="site-label"> Need Help? Reach out to us </label>
          <p class="help-text">
            Duis pulvinar metus elit, ut aliquam est sollicitudin finibus.
            Integer lobortis est interdum. Suspendisse nunc est, varius quis
            fringilla ac, commodo a ante.
          </p>
        </div>
        <div class="need-help-right">
          <button class="btn btn-primary call-btn">
            <i class="fa-solid fa-phone"></i> 0788 300 000
          </button>
          <button class="btn btn-secondary email-btn">
            <i class="fa-solid fa-envelope"></i> Email Us
          </button>
        </div>
      </div>
    </div>
    <div
      class="go-back-up"
      @click="goBackUp"
      :class="!backUp ? 'none' : 'show'"
    >
      <i class="fa-solid fa-arrow-up"></i>
    </div>
    <Footer />
    <Modal
      :modelTitle="''"
      :size="'sm'"
      v-model:open="showLogin"
      @closeModel="showLogin = false"
      centered
      :footer="false"
      width="400px"
    >
      <div class="form-group login-form">
        <div class="avatar">
          <h3>Sign In</h3>
          <p>Sign in to your account to continue</p>
          <div class="avatar-img">
            <img src="/img/avatar.png" alt="" />
          </div>
        </div>
        <div class="input-group">
          <label for="name">Email</label>
          <input
            type="text"
            id="name"
            v-model="newItem.email"
            class="form-control"
            placeholder="Your Email"
          />
        </div>
        <div class="input-group">
          <label for="name">Password</label>
          <input
            type="password"
            id="name"
            v-model="newItem.password"
            class="form-control"
            placeholder="Your Password"
          />
        </div>
        <button @click="Login()" class="login-btn">Login</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { A11y, Autoplay } from "swiper/modules";
import { toast } from "vue3-toastify";
export default {
  name: "Home",
  data() {
    return {
      posts: [],
      rooms: [],
      modules: [A11y, Autoplay],
      backUp: false,
      showLogin: false,
      newItem: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    goBackUp() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.backUp = false;
    },
    handleScroll() {
      this.backUp = window.scrollY > 1000;
    },
    Login() {
      if (!this.newItem.email) {
        toast.error("Email is required.");
        return;
      }
      if (!this.newItem.password) {
        toast.error("Password is required.");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("TENANT_LOGIN", { data: this.newItem })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.$TenantLogin(res.data.tenant, res.data.token);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
      // this.$router.push({ name: "AdminDashboard" });
    },

    getRoomClasses() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_ROOM_CLASSES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.data.data;
              this.allItemsCount = res.data.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  computed: {
    processedRooms() {
      return this.rooms.filter((room) => room.availableRooms > 0);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getRoomClasses();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.home {
  h1 {
    color: $primary_color;
  }
  .jumbotron {
    height: fit-content;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem;
    margin-top: 80px;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    .jumbo-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90vh;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      padding: 2rem 20px;
      .welcome {
        font-size: 22px;
        font-weight: 400;
        color: rgb(164, 226, 255);
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 1rem;
      }
      .welcome-text {
        font-size: 4.75rem;
        font-weight: 700;
        width: 750px;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 2rem;
        color: #ffffffe8;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2rem;
        width: 900px;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 2rem;
      }
      .jumbo-btns {
        display: flex;
        gap: 20px;
        margin-bottom: 4rem;
        .btn {
          width: 200px;
          font-size: 16px;
          font-weight: 500;
          padding: 15px 20px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          background-color: $primary_color;
          color: #fff;
          border: 2px solid $primary_color;
          &:hover {
            background-color: $secondary_color;
            color: #fff;
            border: 2px solid $secondary_color;
          }
          &:nth-child(2) {
            background-color: transparent;
            color: #fff;
            border: 2px solid #fff;
            &:hover {
              background-color: $secondary_color;
              color: #fff;
              border: 2px solid $secondary_color;
            }
          }
        }
      }
      .filter-form {
        width: 1200px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        background: white;
        padding: 30px;
        border-radius: 10px;
        .form-control {
          width: 100%;
          padding: 10px 20px;
          border-radius: 5px;
          border: 1px solid #c7d5d8;
          outline: none;
          font-size: 14px;
          background: #ebf3f5be;
          color: #000;
          font-weight: 400;
        }
        select {
          width: 100%;
          padding: 15px 20px;
          border-radius: 5px;
          border: 1px solid #ccc;
          outline: none;
          font-size: 16px;
          background: white;
          color: #000;
        }
        button {
          width: 100%;
          padding: 15px 20px;
          border-radius: 5px;
          border: 1px solid $primary_color;
          outline: none;
          font-size: 16px;
          background-color: $primary_color;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          &:hover {
            background-color: $secondary_color;
            border: 1px solid $secondary_color;
          }
        }
      }
    }
    @media (max-width: 1400px) {
      margin-top: 60px;
      .jumbo-content {
        height: 90vh;
        .welcome {
          font-size: 16px;
        }
        .welcome-text {
          font-size: 3rem;
          width: 600px;
        }
        p {
          width: 600px;
          font-size: 12px;
        }
        .filter-form {
          width: 900px;
          padding: 20px;
          .form-control {
            padding: 10px 15px;
            font-size: 12px;
          }
          select {
            padding: 10px 15px;
            font-size: 12px;
          }
          button {
            padding: 10px 15px;
            font-size: 12px;
          }
        }
        .jumbo-btns {
          .btn {
            width: 120px;
            font-size: 12px;
            padding: 10px 15px;
          }
        }
      }
    }
    @media (max-width: 1200px) {
      .jumbo-content {
        .welcome {
          font-size: 18px;
        }
        .welcome-text {
          font-size: 3.5rem;
          width: 600px;
        }
        p {
          width: 800px;
          font-size: 14px;
        }
        .jumbo-btns {
          .btn {
            width: 150px;
            font-size: 14px;
            padding: 12px 15px;
          }
        }
        .filter-form {
          width: 850px;
          padding: 20px;
          .form-control {
            padding: 13px 15px;
            font-size: 12px;
          }
          select {
            padding: 13px 15px;
            font-size: 12px;
          }
          button {
            padding: 13px 15px;
            font-size: 12px;
          }
        }
      }
    }
    @media screen and (max-width: 950px) {
      .jumbo-content {
        height: auto;
        padding: 5rem 2rem;
        .welcome-text {
          font-size: 3rem;
          width: 60%;
        }
        p {
          font-size: 14px;
          width: 90%;
        }
        .jumbo-btns {
          .btn {
            font-size: 1rem;
            padding: 12px 15px;
          }
        }
        .filter-form {
          width: 100%;
          .form-control,
          select,
          button {
            font-size: 14px;
            padding: 10px;
          }
        }
      }
    }
    @media screen and (max-width: 900px) {
      .jumbo-content {
        .welcome-text {
          font-size: 2.5rem;
          width: 70%;
        }
        p {
          font-size: 12px;
          width: 90%;
        }
        .jumbo-btns {
          .btn {
            font-size: 1rem;
            padding: 10px 12px;
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      margin: 0;
      .jumbo-content {
        .welcome-text {
          font-size: 2.5rem;
          width: 70%;
        }
        p {
          font-size: 10px;
          width: 90%;
        }

        .jumbo-btns {
          .btn {
            font-size: 0.8rem;
            padding: 10px 12px;
          }
        }
        .filter-form {
          width: 100%;
          .form-control,
          select,
          button {
            font-size: 12px;
            padding: 8px;
          }
        }
      }
    }
    @media screen and (max-width: 700px) {
      .jumbo-content {
        padding-top: 7rem;
        .welcome-text {
          font-size: 2rem;
          width: 80%;
        }
        p {
          font-size: 10px;
          width: 90%;
        }
        .jumbo-btns {
          .btn {
            font-size: 0.7rem;
            padding: 10px 1px;
          }
        }
        .filter-form {
          width: 100%;
          .form-control,
          select,
          button {
            font-size: 10px;
            padding: 6px;
          }
        }
      }
    }
    @media screen and (max-width: 600px) {
      .jumbo-content {
        .welcome-text {
          font-size: 1.5rem;
          width: 90%;
        }
        p {
          font-size: 10px;
          width: 90%;
        }
        .jumbo-btns {
          .btn {
            font-size: 0.7rem;
            padding: 10px 1.4rem;
            width: auto;
          }
        }
        .filter-form {
          width: 100%;
          .form-control,
          select,
          button {
            font-size: 10px;
            padding: 6px;
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      margin: 0;
      border-radius: 0;
      width: 100vw;
      min-height: 100vh;
      .jumbo-content {
        padding: 2rem 1rem;
        padding-top: 7rem;
        min-height: 100vh;
        .welcome-text {
          font-size: 2.5rem;
          width: 100%;
          line-height: 1.5;
        }
        p {
          font-size: 14px;
          width: 90%;
        }
        .jumbo-btns {
          .btn {
            font-size: 1rem;
            padding: 1rem 2rem;
            width: auto;
          }
        }
        .filter-form {
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
          .form-control,
          select,
          button {
            font-size: 14px;
            padding: 12px 10px;
          }
        }
      }
    }
    @media screen and (max-width: 400px) {
      .jumbo-content {
        .welcome-text {
          font-size: 1.5rem;
          width: 100%;
        }
        p {
          font-size: 12px;
          width: 100%;
        }
        .jumbo-btns {
          display: flex;
          flex-direction: column;
          padding: 1rem 0;
          width: 100%;
          .btn {
            font-size: 0.9rem;
            padding: 0.7rem 1.5rem;
            width: 100%;
          }
        }
        .filter-form {
          width: 100%;
          .form-control,
          select,
          button {
            font-size: 10px;
            padding: 10px;
          }
        }
      }
    }
  }

  .available-rooms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem;
    width: 100%;
    flex-wrap: wrap;
    .available-rooms-cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      @media (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .available-room-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 2rem;
        border-radius: 10px;
        transition: all 0.3s ease;
        background-color: #c0c6e031;
        i {
          font-size: 1.3rem;
          background-color: $primary_color;
          color: #fff;
          border-radius: 50%;
          height: 60px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .available-room-card-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .available-room-card-title {
            margin-top: 1rem;
            display: block;
            font-size: 1.2rem;
            font-weight: 600;
            color: $secondary_color;
            margin-bottom: 0.3rem;
          }
          .available-room-card-text {
            font-size: 1rem;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
  #listing {
    padding: 5rem 2rem;
    display: flex;
    justify-content: center;
    background-color: #a5a2d333;
    .listing-cards {
      .listing-card {
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        .listing-card-content {
          .listing-card-image {
            position: relative;
            overflow: hidden;
            img {
              width: 100%;
              height: 250px;
              filter: brightness(0.8);
              object-fit: cover;
              object-position: center;
              transition: all 1s ease;
              &:hover {
                filter: brightness(1);
                transform: scale(1.5);
              }
            }
            .listing-card-content-available {
              position: absolute;
              top: 10px;
              right: 10px;
              background-color: $primary_color;
              color: #fff;
              padding: 0.5rem 1rem;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.8rem;
              font-weight: 500;
              gap: 0.2rem;
              text-transform: uppercase;
            }
          }
          .listing-card-content {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            .listing-card-title {
              font-size: 1.4rem;
              font-weight: 600;
              color: $secondary_color;
            }
            .listing-card-text {
              font-size: 1rem;
              font-weight: 400;
              color: #000000;
            }
            .equipments {
              display: flex;
              flex-wrap: wrap;
              gap: 0.5rem;
              margin-top: 1rem;
              span {
                font-size: 0.9rem;
                font-weight: 400;
                color: #222222;
                border-radius: 5px;
                padding: 0.2rem 0.5rem;
                width: fit-content;
                background-color: #f0f0f0;
              }
            }
          }
          .listing-card-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-top: 1px solid #e0e0e0;
            padding-top: 1rem;
            margin-top: 1rem;
            .listing-card-price {
              font-size: 1.1rem;
              font-weight: 500;
              color: $secondary_color;
              margin-bottom: 0;
            }
            button {
              padding: 0.75rem 1.5rem;
              border-radius: 2px;
              background-color: $primary_color;
              color: #fff;
              font-size: 0.9rem;
              font-weight: 500;
            }
          }
        }
      }
    }
    @media (max-width: 900px) {
      padding: 2rem 0.5rem;
      .listing-cards {
        .listing-card {
          .listing-card-content {
            .listing-card-content {
              .listing-card-title {
                font-size: 1rem;
              }
              .listing-card-text {
                font-size: 0.8rem;
              }
              .equipments {
                span {
                  font-size: 0.8rem;
                }
              }
              .listing-card-footer {
                .listing-card-price {
                  font-size: 1rem;
                }
                button {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
  #about {
    padding: 5rem 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
    .about-left {
      position: relative;
      img {
        width: calc(100% - 7rem);
        height: 100%;
        object-fit: cover;
        object-position: center;
        left: 7rem;
        border-radius: 20px;
        position: relative;
        &.img-2 {
          position: absolute;
          bottom: 2rem;
          left: 0;
          width: 250px;
          height: 200px;
          object-fit: cover;
          object-position: center;
          border-radius: 20px;
          border: 3px solid #fff;
        }
      }
    }
    .about-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .site-title,
      .site-label {
        text-align: left;
      }
      .about-text {
        text-align: left;
        font-size: 1rem;
        font-weight: 400;
        color: #333333;
        margin-bottom: 1rem;
        line-height: 1.5;
      }
      .qualities {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
        margin-top: 1rem;
        .quality {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 1.1rem;
          font-weight: 600;
          color: #333333;
          i {
            background-color: $primary_color;
            color: #fff;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
      .btn {
        margin-top: 2rem;
        width: fit-content;
        padding: 1rem 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        background-color: $primary_color;
        color: #fff;
      }
    }
  }
  @media (max-width: 900px) {
    #about {
      padding: 2rem 0.5rem;
      .container {
        grid-template-columns: repeat(1, 1fr);
      }
      .about-left {
        img {
          width: 100%;
          height: 100%;
          left: 0;
        }
        img.img-2 {
          display: none;
        }
      }
      .about-right {
        .qualities {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
  #how-it-works {
    padding: 5rem 2rem;
    display: flex;
    justify-content: center;
    background-color: #a5a2d333;
    width: 100%;
    .how-it-works-cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      margin-top: 5rem;
      .how-it-works-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 10px;
        background-color: #fff;
        gap: 0.5rem;
        i {
          font-size: 1.5rem;
          background-color: $secondary_color;
          color: #fff;
          border-radius: 50%;
          height: 60px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .how-it-works-card-title {
          font-size: 1.2rem;
          font-weight: 600;
          color: $secondary_color;
          text-align: center;
          margin-bottom: 0;
        }
        .how-it-works-card-text {
          font-size: 1rem;
          font-weight: 400;
          color: #6d6d6d;
          text-align: center;
          margin-bottom: 0;
          line-height: 1.5;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    #how-it-works {
      .how-it-works-cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  @media (max-width: 900px) {
    #how-it-works {
      padding: 2rem 0.5rem;
      .how-it-works-cards {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .need-help {
    padding: 5rem 2rem;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    background-color: #a5a2d333;
    .container {
      background-color: $secondary_color;
      border-radius: 10px;
      padding: 2rem;
      display: grid;
      grid-template-columns: 5fr 3fr;
      gap: 2rem;
      margin-bottom: -2rem;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.404);
    }
    .need-help-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .site-label {
        text-align: left;
        margin-bottom: 1rem;
        color: #ebebeb;
      }
      .help-text {
        font-size: 1rem;
        font-weight: 400;
        color: #ffffffc4;
        line-height: 1.5;
      }
    }
    .need-help-right {
      display: flex;
      align-items: center;
      gap: 1rem;
      .call-btn,
      .email-btn {
        width: fit-content;
        padding: 1rem 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        background-color: $primary_color;
        color: #fff;
        border-radius: 2px;
        border: 2px solid $primary_color;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        &.email-btn {
          background-color: transparent;
          color: #fff;
          border: 2px solid #fff;
          &:hover {
            background-color: #ffffff1f;
          }
        }
      }
    }
  }
  @media (max-width: 900px) {
    .need-help {
      padding: 2rem 0.5rem;
      .container {
        grid-template-columns: repeat(1, 1fr);
      }
      .need-help-left {
        .help-text {
          font-size: 0.9rem;
        }
      }
      .need-help-right {
        gap: 0.5rem;
        flex-direction: column;
        .call-btn,
        .email-btn {
          width: 100%;
        }
      }
    }
  }
  .go-back-up {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    cursor: pointer;
    background-color: $primary_color;
    color: #fff;
    padding: 1rem 0.75rem;
    border-radius: 5px;
    z-index: 100;
    i {
      font-size: 1.25rem;
    }
  }
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .avatar {
    text-align: center;
    margin-bottom: 1rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      color: #666;
    }
  }

  .avatar-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 0rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .input-group {
    width: 100%;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    input {
      width: 100%;
      padding: 0.4rem 0.75rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 0rem;
      &:focus {
        outline: none;
        border-color: $primary_color;
      }
    }
  }
  .login-btn {
    margin-top: 1rem;
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    border: 2px solid $primary_color;
    background-color: $primary_color;
    color: #fff;
    &:hover {
      background-color: $secondary_color;
      border-color: $secondary_color;
      color: #fff;
    }
  }
}
</style>

