import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_POSTS_BY_HOUSE_ID = BACKEND + "/post/getPostsByHouse";
const GET_POPULAR_POSTS = BACKEND + "/post/getPopularPosts";
const GET_POSTS_BY_ID = BACKEND + "/post/getPostById/";
const CREATE_POST = BACKEND + "/post/createPost";
const UPDATE_POST = BACKEND + "/post/updatePost";
const DELETE_POST = BACKEND + "/post/deletePost";

// post comments
const CREATE_POST_COMMENT = BACKEND + "/post/createComment";
const DELETE_POST_COMMENT = BACKEND + "/post/deleteComment";
const GET_POST_COMMENTS = BACKEND + "/post/getPostComments/";

// post likes
const LIKE_POST = BACKEND + "/post/likePost";
const DISLIKE_POST = BACKEND + "/post/dislikePost";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_POSTS_BY_HOUSE_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_POSTS_BY_HOUSE_ID + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_POPULAR_POSTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_POPULAR_POSTS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_POSTS_BY_ID"](state, payload) {
      return axios({
        method: "GET",
        url: GET_POSTS_BY_ID + payload.data.post_id,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_POST"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_POST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_POST"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_POST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_POST"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_POST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },

    // post comments
    ["CREATE_POST_COMMENT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_POST_COMMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_POST_COMMENT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_POST_COMMENT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_POST_COMMENTS"](state, payload) {
      return axios({
        method: "GET",
        url: GET_POST_COMMENTS + payload.id,
        headers: {
          Authorization: payload.token,
        },
      });
    },

    // post likes
    ["LIKE_POST"](state, payload) {
      return axios({
        method: "POST",
        url: LIKE_POST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DISLIKE_POST"](state, payload) {
      return axios({
        method: "POST",
        url: DISLIKE_POST,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
