<template>
  <div class="tenant-dashboard">
    <NavBar />
    <div class="tenant-dashboard-content">
      <div class="container">
        <TenantNav />
        <div class="dashboard-content">
          <div class="dashboard-content-left">
            <div class="dashboard-content-header">
              <h3>Overview</h3>
            </div>
            <div class="dashboard-content-body">
              <div class="tenant-dashboard-widget">
                <div class="tenant-dashboard-widget-stats">
                  <div class="tenant-dashboard-widget-stats-item">
                    <h1>{{ dashStats.subscriptions }}</h1>
                    <h3>Subscriptions</h3>
                  </div>
                  <div class="tenant-dashboard-widget-stats-item">
                    <h1>{{ $amount(dashStats.totalBills) }}</h1>
                    <h3>Total Bills</h3>
                  </div>
                  <div class="tenant-dashboard-widget-stats-item">
                    <h1>{{ $amount(dashStats.totalPaid) }}</h1>
                    <h3>Total Paid</h3>
                  </div>
                  <div class="tenant-dashboard-widget-stats-item danger">
                    <h1>{{ $amount(dashStats.totalUnpaid) }}</h1>
                    <h3>Total Unpaid</h3>
                  </div>
                  <div class="tenant-dashboard-widget-stats-item">
                    <h1>{{ dashStats.complaints }}</h1>
                    <h3>Complaints</h3>
                  </div>
                </div>
              </div>
              <div class="tenant-dashboard-widget">
                <div class="tenant-dashboard-widget-header">
                  <h3>Unpaid Bills</h3>
                  <router-link
                    :to="{ name: 'TenantBills' }"
                    class="my-btn default"
                  >
                    View All
                  </router-link>
                </div>
                <div
                  class="tenant-dashboard-widget-body"
                  v-if="tenantBills.length > 0"
                >
                  <div class="my-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Rooms</th>
                          <th>Issued</th>
                          <th>Due Date</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="bill in tenantBills" :key="bill.id">
                          <td>{{ bill.bill_number }}</td>
                          <td>
                            <span v-if="bill.subscriptions">
                              <a
                                v-for="subscription in bill.subscriptions"
                                :key="subscription.id"
                              >
                                <em v-if="subscription.room">{{
                                  subscription.room.room_number
                                }}</em>
                              </a>
                            </span>
                          </td>
                          <td>{{ $date(bill.created_at) }}</td>
                          <td>{{ $date(bill.due_date) }}</td>
                          <td>
                            <small
                              :class="
                                bill.status != 'paid' && bill.due_date < today
                                  ? 'chip overdue'
                                  : 'chip ' + bill.status
                              "
                              >{{ bill.status }}</small
                            >
                          </td>
                          <td>{{ $comma($fp(bill.amount)) }}</td>
                          <td>
                            <a-dropdown-button>
                              Actions
                              <template #overlay>
                                <a-menu>
                                  <a-menu-item
                                    key="1"
                                    @click="
                                      selectedItem = bill;
                                      startViewBill = true;
                                    "
                                    >View Details</a-menu-item
                                  >
                                </a-menu>
                              </template>
                              <template #icon
                                ><i class="fa fa-angle-down"></i
                              ></template>
                            </a-dropdown-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="pagination">
                      <button
                        :class="{ disabled: currentPage === 1 }"
                        @click="$previousPage()"
                      >
                        <label>Previous</label>
                      </button>
                      <p v-if="currentPage > 3">...</p>
                      <button
                        class="numbers"
                        v-for="number in pagesNumber"
                        :key="number"
                      >
                        <label
                          :class="{ active: currentPage === number }"
                          for=""
                          v-if="
                            (number == 1 && currentPage <= 2) ||
                            (currentPage + 2 >= pagesNumber &&
                              number >= pagesNumber - 2) ||
                            (number >= currentPage - 2 &&
                              number <= currentPage + 2)
                          "
                          @click="$changePage(number)"
                        >
                          {{ number }}
                        </label>
                      </button>
                      <p v-if="currentPage <= pagesNumber - 3">...</p>
                      <button
                        :class="{
                          disabled: currentPage === pagesNumber.length,
                        }"
                        @click="$nextPage()"
                      >
                        <label>Next</label>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="no-data" v-else>
                  <i>No bills found</i>
                </div>
              </div>
              <div class="tenant-dashboard-widget">
                <div class="tenant-dashboard-widget-header">
                  <h3>Your Subscriptions</h3>
                  <router-link
                    :to="{ name: 'TenantSubscriptions' }"
                    class="my-btn default"
                  >
                    View All
                  </router-link>
                </div>
                <div
                  class="tenant-dashboard-widget-body"
                  v-if="tenantSubscriptions.length > 0"
                >
                  <div class="my-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Room</th>
                          <th>Start Date</th>
                          <th>Next Billing Date</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(subscription, index) in tenantSubscriptions"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ subscription.room.room_number }}</td>
                          <td>{{ $date(subscription.started_at) }}</td>
                          <td>{{ $date(subscription.expire_at) }}</td>
                          <td>
                            <small :class="'chip ' + subscription.status">{{
                              subscription.status
                            }}</small>
                          </td>
                          <td>
                            {{ $comma($fp(subscription.amount_to_be_paid)) }}
                          </td>
                          <td>
                            <a-dropdown-button>
                              Actions
                              <template #overlay>
                                <a-menu>
                                  <a-menu-item
                                    key="1"
                                    @click="
                                      selectedItem = subscription;
                                      startViewSubscription = true;
                                    "
                                  >
                                    <router-link
                                      :to="{
                                        name: 'ViewSubscription',
                                        params: {
                                          id: subscription.subscription_id,
                                        },
                                      }"
                                      >View Details</router-link
                                    >
                                  </a-menu-item>
                                </a-menu>
                              </template>
                              <template #icon
                                ><i class="fa fa-angle-down"></i
                              ></template>
                            </a-dropdown-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="pagination">
                      <button
                        :class="{ disabled: currentPage === 1 }"
                        @click="$previousPage()"
                      >
                        <label>Previous</label>
                      </button>
                      <p v-if="currentPage > 3">...</p>
                      <button
                        class="numbers"
                        v-for="number in pagesNumber"
                        :key="number"
                      >
                        <label
                          :class="{ active: currentPage === number }"
                          for=""
                          v-if="
                            (number == 1 && currentPage <= 2) ||
                            (currentPage + 2 >= pagesNumber &&
                              number >= pagesNumber - 2) ||
                            (number >= currentPage - 2 &&
                              number <= currentPage + 2)
                          "
                          @click="$changePage(number)"
                        >
                          {{ number }}
                        </label>
                      </button>
                      <p v-if="currentPage <= pagesNumber - 3">...</p>
                      <button
                        :class="{
                          disabled: currentPage === pagesNumber.length,
                        }"
                        @click="$nextPage()"
                      >
                        <label>Next</label>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="no-data" v-else>
                  <i>No subscriptions found</i>
                </div>
              </div>
            </div>
          </div>

          <div class="popular-posts">
            <h3>Popular Posts</h3>
            <div class="popular-posts-items">
              <div
                class="popular-posts-item"
                v-for="item in popularPosts"
                :key="item.id"
              >
                <div class="post-item">
                  <div class="post-item-content">
                    <div class="left">
                      <div class="post-image">
                        <img
                          :src="$upload(item.image)"
                          alt="post"
                          class="post-image"
                        />
                      </div>
                    </div>
                    <div class="right">
                      <div class="post-item-title">
                        <h3>{{ item.title }}</h3>
                        <p>{{ $date(item.created_at) }}</p>
                        <div class="stats">
                          <!-- likes -->
                          <span class="likes">
                            <i class="fas fa-thumbs-up"></i>
                            {{ item.likes }}
                          </span>
                          <!-- comments -->
                          <span class="comments">
                            <i class="fas fa-comment"></i>
                            {{ item.comments.length }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          :modelTitle="''"
          :size="'sm'"
          v-model:open="startViewBill"
          @closeModel="startViewBill = false"
          centered
          :footer="false"
          width="550px"
        >
          <ViewBill :bill="selectedItem" />
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
      dashStats: {
        subscriptions: 2,
        totalBills: 700000,
        totalPaid: 90000,
        totalUnpaid: 100000,
        complaints: 1,
      },
      currentPage: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      allItemsCount: 0,
      tenantBills: [],
      selectedItems: [],
      allSelected: false,
      startViewBill: false,
      selectedItem: null,
      popularPosts: [],
      tenantSubscriptions: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_BILLS", {
          token: this.$getTenantToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            status: "pending",
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tenantBills = res.data.data.data;
              this.pagesNumber = Math.ceil(
                res.data.data.total / this.itemsPerpage
              );
              this.allItemsCount = res.data.data.total;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
    selectItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        this.selectedItems.push(item);
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.tenantBills.map((item) => item.id);
      }
    },
    getPosts() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POPULAR_POSTS", {
          token: this.$getTenantToken(),
          data: {
            house_id: this.$getTenant().house_id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.popularPosts = res.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getSubscriptions() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_SUBSCRIPTIONS", {
          token: this.$getTenantToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tenantSubscriptions = res.data.subscriptions.data;
              this.pagesNumber = Math.ceil(
                res.data.subscriptions.total / this.itemsPerpage
              );
              this.allItemsCount = res.data.subscriptions.total;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.getPosts();
    this.getSubscriptions();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-dashboard {
  background: #c6cae046;
  min-height: 100vh;
}
.tenant-dashboard-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.dashboard-content {
  padding: 0 1rem;
  margin: 0 1rem;
  height: 85vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 500px;
  gap: 1rem;
  .dashboard-content-left {
    .dashboard-content-header {
      h3 {
        font-size: 1.15rem;
        font-weight: 500;
      }
    }
  }
}
.container {
  width: 100%;
  padding: 0 1rem;
}
</style>
