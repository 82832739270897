<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Complaints', 'read')">
      <h1 class="page-title">
        <span>Complaints <small>(3)</small></span>
        <div class="priorities">
          <span
            class="priority"
            :class="priority"
            v-for="priority in priorities"
            :key="priority"
          >
            {{ priority }}
          </span>
        </div>
      </h1>
      <div class="kanban-section">
        <div class="kanban-board">
          <div class="kanban-col backlog">
            <div class="kanban-col-header">
              <h3>Backlog({{ allItems.backlog.length }})</h3>
            </div>
            <div class="kanban-col-body">
              <div
                class="kanban-card"
                v-for="item in sortedItems(allItems.backlog)"
                :key="item.id"
                :class="item.priority"
                @click="
                  selectedCard = item;
                  showCard = true;
                "
              >
                <div class="kanban-card-header">
                  <div class="header-left">
                    <h4>{{ item.title }}</h4>
                    <span>{{ $ago(item.created_at) }}</span>
                  </div>
                  <div class="header-right">
                    <span
                      class="send-right"
                      @click="changeCardStatus(item, 'assigned')"
                    >
                      <i class="fa-regular fa-circle-right"></i>
                    </span>
                  </div>
                </div>
                <div class="kanban-card-body">
                  <p>{{ item.description }}</p>
                </div>
                <div class="kanban-card-footer">
                  <div class="tenant">
                    <div class="up">Sent by:</div>
                    <div class="down">
                      <img
                        :src="$upload(item.tenant.tenant_image)"
                        :alt="item.tenant.tenant_name"
                      />
                      <span>{{ item.tenant.tenant_name }}</span>
                    </div>
                  </div>
                  <div class="assignees" v-if="item.assignees.length">
                    <div class="up">
                      <span>Assigned To:</span>
                    </div>
                    <div class="down">
                      <a-tooltip
                        :title="assignee.name"
                        v-for="assignee in item.assignees"
                        :key="assignee.id"
                      >
                        <img
                          :src="$upload(assignee.image)"
                          :alt="assignee.name"
                          @error="$imageurlalt()"
                        />
                      </a-tooltip>
                      <!-- <div class="add-more">
                        <span>+</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kanban-card-actions">
                  <button class="btn btn-primary">View</button>
                  <button class="btn btn-primary">Edit</button>
                  <button class="btn btn-primary">Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="kanban-col assigned">
            <div class="kanban-col-header">
              <h3>Assigned({{ allItems.assigned.length }})</h3>
            </div>
            <div class="kanban-col-body">
              <div
                class="kanban-card"
                v-for="item in sortedItems(allItems.assigned)"
                :key="item.id"
                :class="item.priority"
                @click="
                  selectedCard = item;
                  showCard = true;
                "
              >
                <div class="kanban-card-header">
                  <div class="header-left">
                    <h4>{{ item.title }}</h4>
                    <span>{{ $ago(item.created_at) }}</span>
                  </div>
                  <div class="header-right">
                    <span
                      class="send-right"
                      @click="changeCardStatus(item, 'inProgress')"
                    >
                      <i class="fa-regular fa-circle-right"></i>
                    </span>
                  </div>
                </div>
                <div class="kanban-card-body">
                  <p>{{ item.description }}</p>
                </div>
                <div class="kanban-card-footer" v-if="item.assignees.length">
                  <div class="tenant">
                    <div class="up">Sent by:</div>
                    <div class="down">
                      <img
                        :src="$upload(item.tenant.tenant_image)"
                        :alt="item.tenant.tenant_name"
                      />
                      <span>{{ item.tenant.tenant_name }}</span>
                    </div>
                  </div>
                  <div class="assignees">
                    <div class="up">
                      <span>Assigned To:</span>
                    </div>
                    <div class="down">
                      <a-tooltip
                        :title="assignee.name"
                        v-for="assignee in item.assignees"
                        :key="assignee.id"
                      >
                        <img
                          :src="$upload(assignee.image)"
                          :alt="assignee.name"
                          @error="$imageurlalt()"
                        />
                      </a-tooltip>
                      <!-- <div class="add-more">
                        <span>+</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kanban-card-actions">
                  <button class="btn btn-primary">View</button>
                  <button class="btn btn-primary">Edit</button>
                  <button class="btn btn-primary">Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="kanban-col inProgress">
            <div class="kanban-col-header">
              <h3>In Progress({{ allItems.inProgress.length }})</h3>
            </div>
            <div class="kanban-col-body">
              <div
                class="kanban-card"
                v-for="item in sortedItems(allItems.inProgress)"
                :key="item.id"
                :class="item.priority"
                @click="
                  selectedCard = item;
                  showCard = true;
                "
              >
                <div class="kanban-card-header">
                  <div class="header-left">
                    <h4>{{ item.title }}</h4>
                    <span>{{ $ago(item.created_at) }}</span>
                  </div>
                  <div class="header-right">
                    <span
                      class="send-right"
                      @click="changeCardStatus(item, 'inReview')"
                    >
                      <i class="fa-regular fa-circle-right"></i>
                    </span>
                  </div>
                </div>
                <div class="kanban-card-body">
                  <p>{{ item.description }}</p>
                </div>
                <div class="kanban-card-footer">
                  <div class="tenant">
                    <div class="up">Sent by:</div>
                    <div class="down">
                      <img
                        :src="$upload(item.tenant.tenant_image)"
                        :alt="item.tenant.tenant_name"
                      />
                      <span>{{ item.tenant.tenant_name }}</span>
                    </div>
                  </div>
                  <div class="assignees" v-if="item.assignees.length">
                    <div class="up">
                      <span>Assigned To:</span>
                    </div>
                    <div class="down">
                      <a-tooltip
                        :title="assignee.name"
                        v-for="assignee in item.assignees"
                        :key="assignee.id"
                      >
                        <img
                          :src="$upload(assignee.image)"
                          :alt="assignee.name"
                          @error="$imageurlalt()"
                        />
                      </a-tooltip>
                      <!-- <div class="add-more">
                        <span>+</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kanban-card-actions">
                  <button class="btn btn-primary">View</button>
                  <button class="btn btn-primary">Edit</button>
                  <button class="btn btn-primary">Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="kanban-col inReview">
            <div class="kanban-col-header">
              <h3>In Review({{ allItems.inReview.length }})</h3>
            </div>
            <div class="kanban-col-body">
              <div
                class="kanban-card"
                v-for="item in sortedItems(allItems.inReview)"
                :key="item.id"
                :class="item.priority"
                @click="
                  selectedCard = item;
                  showCard = true;
                "
              >
                <div class="kanban-card-header">
                  <div class="header-left">
                    <h4>{{ item.title }}</h4>
                    <span>{{ $ago(item.created_at) }}</span>
                  </div>
                  <div class="header-right">
                    <span
                      class="send-right"
                      @click="changeCardStatus(item, 'completed')"
                    >
                      <i class="fa-regular fa-circle-right"></i>
                    </span>
                  </div>
                </div>
                <div class="kanban-card-body">
                  <p>{{ item.description }}</p>
                </div>
                <div class="kanban-card-footer">
                  <div class="tenant">
                    <div class="up">Sent by:</div>
                    <div class="down">
                      <img
                        :src="$upload(item.tenant.tenant_image)"
                        :alt="item.tenant.tenant_name"
                      />
                      <span>{{ item.tenant.tenant_name }}</span>
                    </div>
                  </div>
                  <div class="assignees" v-if="item.assignees.length">
                    <div class="up">
                      <span>Assigned To:</span>
                    </div>
                    <div class="down">
                      <a-tooltip
                        :title="assignee.name"
                        v-for="assignee in item.assignees"
                        :key="assignee.id"
                      >
                        <img
                          :src="$upload(assignee.image)"
                          :alt="assignee.name"
                          @error="$imageurlalt()"
                        />
                      </a-tooltip>
                      <!-- <div class="add-more">
                        <span>+</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kanban-card-actions">
                  <button class="btn btn-primary">View</button>
                  <button class="btn btn-primary">Edit</button>
                  <button class="btn btn-primary">Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="kanban-col completed">
            <div class="kanban-col-header">
              <h3>Completed({{ allItems.completed.length }})</h3>
            </div>
            <div class="kanban-col-body">
              <div
                class="kanban-card"
                v-for="item in sortedItems(allItems.completed)"
                :key="item.id"
                :class="item.priority"
                @click="
                  selectedCard = item;
                  showCard = true;
                "
              >
                <div class="kanban-card-header">
                  <div class="header-left">
                    <h4>{{ item.title }}</h4>
                    <span>{{ $ago(item.created_at) }}</span>
                  </div>
                  <div class="header-right">
                    <span
                      class="send-right"
                      @click="
                        selectedCard = item;
                        startArchive = true;
                      "
                    >
                      <i class="fa fa-archive"></i>
                    </span>
                  </div>
                </div>
                <div class="kanban-card-body">
                  <p>{{ item.description }}</p>
                </div>
                <div class="kanban-card-footer">
                  <div class="tenant">
                    <div class="up">Sent by:</div>
                    <div class="down">
                      <img
                        :src="$upload(item.tenant.tenant_image)"
                        :alt="item.tenant.tenant_name"
                      />
                      <span>{{ item.tenant.tenant_name }}</span>
                    </div>
                  </div>
                  <div class="assignees" v-if="item.assignees.length">
                    <div class="up">
                      <span>Assigned To:</span>
                    </div>
                    <div class="down">
                      <a-tooltip
                        :title="assignee.name"
                        v-for="assignee in item.assignees"
                        :key="assignee.id"
                      >
                        <img
                          :src="$upload(assignee.image)"
                          :alt="assignee.name"
                          @error="$imageurlalt()"
                        />
                      </a-tooltip>
                      <!-- <div class="add-more">
                        <span>+</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kanban-card-actions">
                  <button class="btn btn-primary">View</button>
                  <button class="btn btn-primary">Edit</button>
                  <button class="btn btn-primary">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        :title="'Complaint Details'"
        :size="'lg'"
        v-model:open="showCard"
        @closeModel="showCard = false"
        centered
        width="800px"
        footer=""
        style="top: 30px; padding-bottom: 3rem"
      >
        <KanBanModal
          :selectedCard="selectedCard"
          @addComment="
            (comment) => {
              newComment.comment = comment;
              saveComment();
            }
          "
          @deleteComment="
            (comment) => {
              deleteComment(comment);
            }
          "
          @addAttachments="
            (attachments) => {
              newAttachment.attachments = attachments;
              saveAttachments();
            }
          "
          @deleteAttachment="
            (attachment) => {
              deleteAttachment(attachment);
            }
          "
        />
      </Modal>

      <Modal
        :title="'Archive Complaint'"
        :size="'sm'"
        v-model:open="startArchive"
        @closeModel="startArchive = false"
        centered
        :okText="'Yes'"
        @ok="archiveCard(selectedCard)"
        width="400px"
      >
        <p>Are you sure you want to archive this complaint?</p>
      </Modal>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      newItem: {
        house_id: "",
        tenant_id: "",
        title: "",
        description: "",
        priority: "low",
        assignee_ids: [],
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startArchive: false,
      allItems: {
        backlog: [],
        assigned: [],
        inProgress: [],
        inReview: [],
        completed: [],
        archived: [],
      },
      selectedCard: null,
      showCard: false,
      priorities: ["low", "medium", "high", "critical"],
      newComment: {
        comment: "",
        complaint_id: "",
      },
      newAttachment: {
        attachments: [],
        complaint_id: "",
        attachment_type: "image",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_COMPLAINTS_BY_HOUSE_ID", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (
        this.newItem.title &&
        this.newItem.description &&
        this.newItem.priority &&
        this.newItem.tenant_id
      ) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_COMPLAINT", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem(card) {
      if (
        card.title &&
        card.description &&
        card.priority &&
        card.status &&
        card.tenant_id
      ) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_COMPLAINT", {
            data: card,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                // this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_COMPLAINT", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    changeCardStatus(card, status) {
      let cardData = this.allItems[card.status].find(
        (item) => item.id === card.id
      );
      let newCard = {
        ...cardData,
        status: status,
        order: this.allItems[status][0]
          ? this.allItems[status][0].order - 1
          : 1,
      };
      this.allItems[card.status] = this.allItems[card.status].filter(
        (item) => item.id !== card.id
      );
      this.updateItem(newCard);
      this.allItems[status].push(newCard);
      this.showCard = false;
    },
    sortedItems(items) {
      return items.sort((a, b) => a.order - b.order);
    },
    archiveCard(card) {
      this.changeCardStatus(card, "archived");
      this.startArchive = false;
    },
    saveComment() {
      if (this.newComment.comment) {
        this.newComment.complaint_id = this.selectedCard.complaint_id;
        this.$startLoader();
        this.$store
          .dispatch("CREATE_COMPLAINT_COMMENT", {
            data: {
              ...this.newComment,
              house_id: this.$getHouseId(),
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                this.selectedCard.comments.unshift(res.data.data);
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteComment(comment) {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_COMPLAINT_COMMENT", {
          data: { comment_id: comment },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.selectedCard.comments = this.selectedCard.comments.filter(
                (item) => item.comment_id !== comment
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    saveAttachments() {
      if (
        this.newAttachment.attachments &&
        this.newAttachment.attachments.length > 0
      ) {
        this.$startLoader();
        this.newAttachment.complaint_id = this.selectedCard.complaint_id;

        this.$store
          .dispatch("CREATE_COMPLAINT_ATTACHMENT", {
            data: {
              ...this.newAttachment,
              house_id: this.$getHouseId(),
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                this.selectedCard = res.data.data;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteAttachment(attachment) {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_COMPLAINT_ATTACHMENT", {
          data: { attachment_id: attachment },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.selectedCard.attachments =
                this.selectedCard.attachments.filter(
                  (item) => item.attachment_id !== attachment
                );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (this.$access("Complaints", "read")) {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
    .priorities {
      display: flex;
      gap: 10px;
      .priority {
        padding: 0.25rem 1rem;
        background-color: #f5f5f5;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        font-size: 0.8rem;
        &.low {
          background-color: #00b894;
          color: white;
        }
        &.medium {
          background-color: #d9ff00;
        }
        &.high {
          background-color: #ff8c00;
        }
        &.critical {
          background-color: #c50202;
          color: white;
        }
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

.kanban-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 0rem;
  padding-left: 0rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  .kanban-board {
    display: flex;
    align-items: flex-start;
    .kanban-col {
      width: 400px;
      margin-right: 1rem;
      background-color: #f5f5f5;
      border-radius: 5px;
      @media (max-width: 1800px) {
        width: 300px;
      }
      .kanban-col-header {
        color: #5c5c5c;
        padding: 1rem;
        border-radius: 5px 5px 0 0;
        h3 {
          margin: 0;
          font-size: 0.9rem;
          @media (max-width: 1800px) {
            font-size: 0.8rem;
          }
        }
      }
      .kanban-col-body {
        border-radius: 0 0 5px 5px;
        padding: 1rem;
        .kanban-card {
          background-color: #fff;
          margin-bottom: 1rem;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.041);
          cursor: pointer;
          &.critical {
            border-bottom: 5px solid #c50202;
          }
          &.high {
            border-bottom: 5px solid #ff8c00;
          }
          &.medium {
            border-bottom: 5px solid #d9ff00;
          }
          &.low {
            border-bottom: 5px solid #00b894;
          }
          .kanban-card-header {
            display: grid;
            grid-template-columns: 1fr 50px;
            padding: 1rem;
            .header-left {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              column-gap: 2rem;
              h4 {
                margin: 0;
                font-size: 0.95rem;
                line-height: 1.5;
                @media (max-width: 1800px) {
                  font-size: 0.8rem;
                }
              }
              span {
                font-size: 0.8rem;
                color: $primary_color;
                line-height: 1.5;
                @media (max-width: 1800px) {
                  font-size: 0.7rem;
                }
              }
            }
            .header-right {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              i {
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                font-size: 1.5rem;
                color: #777;
                @media (max-width: 1800px) {
                  font-size: 1.2rem;
                }
                &:hover {
                  color: $primary_color;
                }
              }
            }
          }
          .kanban-card-body {
            padding: 1rem;
            border-bottom: 1px solid #eee;
            padding-top: 0;
            p {
              margin: 0;
              font-size: 0.8rem;
              line-height: 1.5;
              color: #777;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              @media (max-width: 1800px) {
                font-size: 0.7rem;
              }
            }
          }
          .kanban-card-footer {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            .tenant {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .up {
                font-size: 0.8rem;
                color: #5c5c5c;
                @media (max-width: 1800px) {
                  font-size: 0.7rem;
                }
              }
              .down {
                display: flex;
                align-items: center;
                gap: 5px;
                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  object-fit: cover;
                  @media (max-width: 1800px) {
                    width: 25px;
                    height: 25px;
                  }
                }
                span {
                  font-size: 0.8rem;
                  color: #5c5c5c;
                  @media (max-width: 1800px) {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .assignees {
              display: flex;
              flex-direction: column;
              gap: 5px;
              align-items: flex-end;
              .up {
                font-size: 0.8rem;
                color: #5c5c5c;
                @media (max-width: 1800px) {
                  font-size: 0.7rem;
                }
              }
              .down {
                display: flex;
                align-items: center;
                gap: 1px;

                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  object-fit: cover;
                  @media (max-width: 1800px) {
                    width: 25px;
                    height: 25px;
                  }
                }
                .add-more {
                  background-color: #f5f5f5;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
                  @media (max-width: 1800px) {
                    width: 25px;
                    height: 25px;
                  }
                  &:hover {
                    background-color: #e5e5e5;
                  }
                }
              }
            }
          }
          .kanban-card-actions {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            display: none;
            button {
              padding: 0.5rem 1rem;
              background-color: $primary_color;
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              font-size: 1rem;
              &:hover {
                background-color: $secondary_color;
              }
            }
          }
        }
      }
    }
  }
}
</style>