<template>
  <div id="ViewBill">
    <div class="viewBill-header">
      <h3>Preview</h3>
      <div class="pdf">
        <i class="fa fa-file-alt"></i>
        <p>PDF</p>
      </div>
    </div>
    <div class="viewBill-content" id="print-content">
      <div class="viewBill-content-header">
        {{ bill.bill_number }}
      </div>
      <div class="viewBill-content-body">
        <div class="group-2">
          <div class="dates">
            <h5>Dates</h5>
            <p>Issue Date: {{ $date(bill.created_at) }}</p>
            <p>Due Date: {{ $date(bill.due_date) }}</p>
          </div>
          <div class="client">
            <h5>Billed to</h5>
            <p>{{ bill.tenant.tenant_name }}</p>
            <p>{{ bill.tenant.tenant_email }}</p>
          </div>
        </div>
        <div class="subject">
          <h5>Subject</h5>
          <p>{{ bill.bill_description }}</p>
        </div>
        <div class="bill-table">
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>P/U</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody
              v-for="subscription in bill.subscriptions"
              :key="subscription.id"
            >
              <tr
                v-for="(payment, index) in subscription.payments"
                :key="index"
              >
                <td>
                  {{ subscription.room.room_name }} {{ payment.description }}
                </td>
                <td>{{ bill.quantity }}</td>
                <td>{{ $comma($fp(payment.amount)) }}</td>
                <td>{{ $comma($fp(bill.quantity * payment.amount)) }}</td>
              </tr>
              <tr class="totals">
                <td></td>
                <td></td>
                <td>Sub Total:</td>
                <td>{{ $comma($fp(bill.amount)) }}</td>
              </tr>
              <tr class="totals">
                <td></td>
                <td></td>
                <td>Total:</td>
                <td>{{ $comma($fp(bill.amount)) }}</td>
              </tr>
              <tr class="totals">
                <td></td>
                <td></td>
                <td>Amount due:</td>
                <td>{{ $comma($fp(bill.amount - bill.paid_amount)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="viewBill-footer">
      <button class="btn btn-primary" @click="printBill">
        <i class="fa fa-print"></i>
        Print
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewBill",
  props: ["bill"],
  data() {
    return {};
  },
  methods: {
    printBill() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
#ViewBill {
  //   background: #eee;
  border-radius: 10px;
  p {
    margin-bottom: 0 !important;
  }
  padding: 2rem;
  .viewBill-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .pdf {
      display: flex;
      align-items: center;
      border: 1px solid #000;
      padding: 2px 0.5rem;
      border-radius: 50px;
      gap: 5px;
    }
  }
  .viewBill-content {
    .viewBill-content-header {
      font-size: 1.15rem;
      font-weight: 600;
      margin: 1rem 0;
    }
    .viewBill-content-body {
      .group-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        h5 {
          font-size: 0.75rem;
          font-weight: 600;
          color: #999;
        }
        p {
          font-size: 0.8rem;
          color: #000;
          font-weight: 500;
        }
      }
      .subject {
        margin-top: 1rem;
        h5 {
          font-size: 0.75rem;
          font-weight: 600;
          color: #999;
        }
        p {
          font-size: 0.8rem;
          color: #000;
          font-weight: 500;
        }
      }
      .bill-table {
        margin-top: 1rem;
        border-bottom: 1px solid #eee;
        table {
          width: 100%;
          border-collapse: collapse;
          thead {
            tr {
              background: #ecf1f1;
              text-transform: uppercase;
              color: #333;
              font-weight: 600;
              font-size: 0.75rem;
              text-align: left;
              th {
                padding: 2px 0.5rem;
              }
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid #eee;
              td {
                padding: 10px 0.5rem;
              }
              &.totals {
                border-bottom: 1px solid #fff;
                td {
                  font-weight: 600;
                  color: #000;
                  padding: 5px 0.5rem;
                  padding-left: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .viewBill-footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 0.5rem 1rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 0.8rem;
      background: $primary_color;
      color: #fff;
    }
  }
}
</style>
