<template>
  <div class="dashboard" v-if="$getAdmin()">
    <div class="dash-content">
      <Topbar :expand="false" />
      <div class="dash-widget-jumbo" v-if="organization">
        <div class="jumbo-content">
          <h1 class="page-title">
            {{ organization.name }}
          </h1>
          <p class="page-desc">
            {{ organization.address }}
          </p>
        </div>
        <div class="houses">
          <div
            class="house"
            v-for="house in houses"
            :key="house.house_id"
            @click="goToHouse(house.house_id)"
          >
            <div class="house-icon">
              <i
                class="fas fa-home"
                v-if="house.house_level === 'Single level'"
              ></i>
              <i class="fas fa-building" v-else></i>
            </div>
            <div class="house-info">
              <p class="house-name">
                <span>{{ house.house_name }}</span
                ><small>({{ house.house_type }})</small>
              </p>
            </div>
          </div>
          <div
            class="add-house-btn"
            v-if="$canAddHouse(houses.length)"
            @click="startAdd = true"
          >
            <i class="fas fa-plus"></i>
          </div>
        </div>
      </div>

      <a-modal
        v-model:open="startAdd"
        title="Add House"
        :size="'600px'"
        @ok="createItem"
      >
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.house_name"
              class="form-control"
              placeholder="Enter name"
            />
          </div>
          <div class="input-group">
            <label for="name">Currency</label>
            <input
              type="text"
              id="name"
              v-model="newItem.currency"
              class="form-control"
              placeholder="RWF"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="wing">Category</label>
            <select id="wing" v-model="newItem.house_type" class="form-control">
              <option value="">Select category</option>
              <option value="Commercial">Commercial</option>
              <option value="Residential">Residential</option>
            </select>
          </div>
          <div class="input-group">
            <label for="level">Level</label>
            <select
              id="level"
              v-model="newItem.house_level"
              class="form-control"
            >
              <option value="">Select level</option>
              <option value="Single level">Single level</option>
              <option value="Multi Level">Multi Level</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="address">Address</label>
            <input
              type="text"
              id="address"
              v-model="newItem.house_address"
              class="form-control"
              placeholder="Enter address"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="description">Description</label>
            <textarea
              id="description"
              v-model="newItem.house_description"
              class="form-control"
              placeholder="Enter description"
              rows="5"
            ></textarea>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Topbar from "@/views/admin/components/Topbar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  components: {
    Topbar,
  },
  data() {
    return {
      selectedDate: new Date(),
      insights: [],
      houses: [],
      startAdd: false,
      newItem: {
        house_name: "",
        house_type: "",
        house_level: "",
        house_address: "",
        house_description: "",
      },
      organization: null,
    };
  },
  methods: {
    goToHouse(id) {
      const house = this.houses.find((h) => h.house_id === id);
      localStorage.setItem("ipms_house", JSON.stringify(house));
      this.$router.push({ name: "AdminAnalytics" });
    },
    getHouses() {
      this.$startLoader();
      let token = this.$getAdminToken();
      this.$store
        .dispatch("GET_MY_ORG_HOUSES", {
          token: token,
          data: {
            organization_id: this.$getAdmin().organization_id,
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.houses = res.data.data.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      this.$startLoader();
      let token = this.$getAdminToken();
      this.$store
        .dispatch("CREATE_ORG_HOUSE", {
          token: token,
          data: { ...this.newItem },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.getHouses();
              this.startAdd = false;
              toast.success(res.data.message);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (!this.$getAdmin()) {
      this.$userLogout();
    } else {
      if (this.$getAdmin() && this.$getAdmin().type == "sadmin") {
        this.$router.push({ name: "AdminOrganizations" });
      }
      this.organization = this.$getOrganization();
      this.getHouses();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
    background-image: url("@/assets/img/bg2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .dash-widget-jumbo {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: #5468ff59;
      height: calc(100vh - 50px);
      padding-top: 10%;
      .jumbo-content {
        width: 100%;
        padding: 20px;
        color: #fff;
        // height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 3rem;
          font-weight: 600;
          margin-bottom: 20px;
          text-align: center;
          display: flex;
          flex-direction: column;
          i {
            font-size: 1rem;
            font-weight: 400;
            display: block;
            margin-bottom: 2rem;
          }
        }
      }
      .houses {
        display: flex;
        padding: 20px;
        gap: 20px;
        .house {
          width: 300px;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-radius: 10px;
          transition: 0.3s;
          cursor: pointer;
          @media (max-width: 2000px) {
            width: 250px;
          }
          @media (max-width: 1700px) {
            width: 200px;
          }
          @media (max-width: 1500px) {
            width: 180px;
          }
          @media (max-width: 1300px) {
            width: 160px;
          }
          @media (max-width: 900px) {
            width: 140px;
          }
          &:hover {
            background-color: #edeffd;
          }
          .house-icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $primary_color;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              font-size: 1.5rem;
              color: #fff;
            }
          }
          .house-info {
            display: flex;
            flex-direction: column;
            .house-name {
              font-size: 1rem;
              font-weight: 600;
              display: flex;
              flex-direction: column;
              align-items: center;
              @media (max-width: 1700px) {
                font-size: 0.9rem;
              }
              @media (max-width: 1500px) {
                font-size: 0.8rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.7rem;
              }
              @media (max-width: 900px) {
                font-size: 0.6rem;
              }
              span {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              small {
                font-size: 0.8rem;
                color: #888;
                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @media (max-width: 1700px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 1500px) {
                  font-size: 0.6rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.5rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.45rem;
                }
              }
            }
          }
        }
        .add-house-btn {
          width: 300px;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-radius: 10px;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            background-color: #edeffd;
          }
          @media (max-width: 2000px) {
            width: 250px;
          }
          @media (max-width: 1700px) {
            width: 200px;
          }
          @media (max-width: 1500px) {
            width: 180px;
          }
          @media (max-width: 1300px) {
            width: 160px;
          }
          @media (max-width: 900px) {
            width: 140px;
          }
          i {
            font-size: 2rem;
            color: $primary_color;
          }
        }
      }
    }
  }
}
</style>