import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import mixin from "./Mixins/mixin.vue";
import Notifications from "@kyvg/vue3-notification";

// import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";
import "vue3-toastify/dist/index.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import { Modal } from "ant-design-vue";
import { Swiper, SwiperSlide } from "swiper/vue";

// componets
import TopBar from "./views/components/TopBar.vue";
import NavBar from "./views/components/NavBar.vue";
import Footer from "./views/components/Footer.vue";
import PropertyCard from "./views/components/PropertyCard.vue";
import MySelect from "./views/components/MySelect.vue";
import miLoader from "./views/components/miLoader.vue";
import Model from "./views/components/Model.vue";
import confirmModel from "./views/components/confirmModel.vue";
import OUpload from "./views/components/OUpload.vue";
import ImagesViewer from "./views/components/ImagesViewer.vue";
import KanBanModal from "./views/admin/components/KanBanModal.vue";
import TenantNav from "./views/components/TenantNav.vue";
import ViewBill from "./views/components/ViewBill.vue";
import "swiper/css/bundle";
const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);
app.component("TopBar", TopBar);
app.component("NavBar", NavBar);
app.component("Footer", Footer);
app.component("PropertyCard", PropertyCard);
app.component("MySelect", MySelect);
app.component("miLoader", miLoader);
app.component("Model", Model);
app.component("confirmModel", confirmModel);
app.component("OUpload", OUpload);
app.component("ImagesViewer", ImagesViewer);
app.component("KanBanModal", KanBanModal);
app.component("TenantNav", TenantNav);
app.component("QuillEditor", QuillEditor);
app.component("Modal", Modal);
app.component("Swiper", Swiper);
app.component("SwiperSlide", SwiperSlide);
app.component("ViewBill", ViewBill);
app.use(Notifications);

app.mixin(mixin);
app.use(vuetify);
app.use(Antd);
app.config.productionTip = false;

app.use(store).use(router).mount("#app");
