<script>
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
      primary_color: "#1d2088",
      secondary_color: "#1eb9ee",
    };
  },
  methods: {
    $urlCompatible(name, roll = false) {
      if (roll) {
        return name.toLowerCase().replace(/\s/g, "-");
      }
      return name.toLowerCase().replace(/\s/g, "-");
    },
    $img(name) {
      return `/img/${name}`;
    },
    $upload(name) {
      if (name) {
        return this.$store.state.apiUploadUrl + `${name}`;
      }
      return "https://via.placeholder.com/150";
    },
    $startLoader() {
      document.body.style.overflow = "hidden";
      this.$store.state.startLoader = true;
    },
    $stopLoader() {
      document.body.style.overflow = "auto";
      this.$store.state.startLoader = false;
    },
    $urlContains(url) {
      if (url) {
        return this.$route.path.includes(url);
      } else {
        return "https://via.placeholder.com/150";
      }
    },
    $AdminLogin(user, token) {
      localStorage.setItem("ipms_admin", JSON.stringify(user));
      localStorage.setItem("ipms_admin_token", token);
      this.$router.push({ name: "AdminDashboard" });
    },
    $getAdmin() {
      let admin = localStorage.getItem("ipms_admin");
      if (admin) {
        return JSON.parse(admin);
      }
      return false;
    },
    $getAdminToken() {
      let token = localStorage.getItem("ipms_admin_token");
      if (token) {
        return token;
      }
      return false;
    },
    $adminIsAdmin() {
      const user = JSON.parse(localStorage.getItem("ipms_admin"));
      if (user.type == "admin") {
        return true;
      }
      return false;
    },
    $adminIsAgent() {
      const user = JSON.parse(localStorage.getItem("ipms_admin"));
      if (user.type == "agent") {
        return true;
      }
      return false;
    },
    $TenantLogin(tenant, token) {
      localStorage.setItem("ipms_tenant", JSON.stringify(tenant));
      localStorage.setItem("ipms_tenant_token", token);
      this.$router.push({ name: "TenantDashboard" });
    },
    $tenantLogout() {
      this.$startLoader();
      this.$store
        .dispatch("TENANT_LOGOUT", {
          token: this.$getTenantToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              localStorage.removeItem("ipms_tenant");
              localStorage.removeItem("ipms_tenant_token");
              this.$router.push({ name: "Home" });
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    $getTenant() {
      let tenant = localStorage.getItem("ipms_tenant");
      if (tenant) {
        return JSON.parse(tenant);
      }
      return false;
    },
    $getTenantToken() {
      let token = localStorage.getItem("ipms_tenant_token");
      if (token) {
        return token;
      }
      return false;
    },
    $canAddHouse(houses) {
      const user = JSON.parse(localStorage.getItem("ipms_admin"));
      if (user.type == "admin" && user.organization.max_houses > houses) {
        return true;
      }
      return false;
    },
    $AdminLogout() {
      this.$startLoader();
      this.$store
        .dispatch("ADMIN_LOGOUT", {
          token: localStorage.getItem("ipms_admin_token"),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.$stopLoader();
            } else {
              this.$stopLoader();
            }
            localStorage.removeItem("ipms_admin");
            localStorage.removeItem("ipms_admin_token");
            this.$router.push({ name: "AdminLogin" });
          }
        })
        .catch(() => {
          this.$stopLoader();
          this.$router.push({ name: "AdminLogin" });
        })
        .finally(() => {
          this.$stopLoader();
        });
    },
    $imageurlalt() {
      event.target.src = "https://via.placeholder.com/300";
    },
    $price(price) {
      if (isNaN(price)) {
        return price;
      } else {
        return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    $date(date, type = "date") {
      if (!date) {
        return;
      }
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "short" });
      let dayNumber = newDate.toLocaleString("default", { day: "numeric" });
      // add 0 before day if less than 10
      if (dayNumber < 10) {
        dayNumber = "0" + dayNumber;
      }
      let monthName = newDate.toLocaleString("default", { month: "short" });
      let monthNameShort = newDate.toLocaleString("default", {
        month: "short",
      });
      let monthNumber = newDate.toLocaleString("default", { month: "numeric" });
      // add 0 before month if less than 10
      if (monthNumber < 10) {
        monthNumber = "0" + monthNumber;
      }
      let dateDots = `${dayNumber}.${monthNumber}.${year}`;
      if (type == "date") {
        return `${monthName} ${dayNumber}, ${year}`;
      }
      if (type == "dots") {
        return dateDots;
      }
      if (type == "day") {
        return dayNumber;
      }
      if (type == "month") {
        return monthNameShort;
      }
      if (type == "year") {
        return year;
      }
    },
    $ago(date) {
      if (!date) {
        return;
      }
      // get time ago since  date
      let newDate = new Date(date);
      let today = new Date();
      let diff = today - newDate;
      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let weeks = Math.floor(days / 7);
      let months = Math.floor(days / 30);
      let years = Math.floor(days / 365);
      if (seconds < 60) {
        return seconds + " seconds ago";
      } else if (minutes < 60) {
        return minutes + " minutes ago";
      } else if (hours < 24) {
        return hours + " hours ago";
      } else if (days < 7) {
        return days + " days ago";
      } else if (weeks < 4) {
        return weeks + " weeks ago";
      } else if (months < 12) {
        return months + " months ago";
      } else {
        return years + " years ago";
      }
    },
    $chatdate(date) {
      if (!date) {
        return;
      }
      let newDate = new Date(date);
      let today = new Date();
      let diff = today - newDate;
      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let weeks = Math.floor(days / 7);
      let months = Math.floor(days / 30);
      let years = Math.floor(days / 365);
      if (seconds < 60) {
        return "Just now";
      } else if (days == 0) {
        return newDate.toLocaleString("default", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      } else if (days == 1) {
        return "Yesterday";
      } else if (days < 7) {
        return newDate.toLocaleString("default", { weekday: "short" });
      } else if (years == 0) {
        return newDate.toLocaleString("default", {
          month: "short",
          day: "numeric",
        });
      } else {
        return newDate.toLocaleString("default", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        });
      }
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
    $comma(amount) {
      if (!amount) {
        return amount;
      }
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $changePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.getItems();
    },
    $previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getItems();
      }
    },
    $nextPage() {
      if (this.currentPage < this.pagesNumber) {
        this.currentPage++;
        this.getItems();
      }
    },
    $num(val) {
      // add 0 before number if less than 10
      if (val < 10) {
        return "0" + val;
      }
      return val;
    },
    $fiesIcons(type, name) {
      if (type == "folder") {
        return "folder.png";
      }
      if (type == "file") {
        let ext = name.split(".").pop();
        if (ext == "pdf") {
          return "pdf.png";
        }
        if (ext == "doc" || ext == "docx") {
          return "word.png";
        }
        if (ext == "xls" || ext == "xlsx") {
          return "excel.png";
        }
        if (ext == "ppt" || ext == "pptx") {
          return "ppt.png";
        }
        if (ext == "txt") {
          return "txt.png";
        }
        if (
          ext == "zip" ||
          ext == "7z" ||
          ext == "tar" ||
          ext == "gz" ||
          ext == "rar"
        ) {
          return "zip.png";
        }
        if (
          ext == "jpg" ||
          ext == "jpeg" ||
          ext == "png" ||
          ext == "gif" ||
          ext == "svg"
        ) {
          return "image.png";
        }
        if (
          ext == "mp3" ||
          ext == "wav" ||
          ext == "ogg" ||
          ext == "flac" ||
          ext == "wma"
        ) {
          return "audio.png";
        }
        if (
          ext == "mp4" ||
          ext == "avi" ||
          ext == "mkv" ||
          ext == "mov" ||
          ext == "flv"
        ) {
          return "video.png";
        }
        if (
          ext == "exe" ||
          ext == "msi" ||
          ext == "apk" ||
          ext == "dmg" ||
          ext == "deb"
        ) {
          return "exe.png";
        }
        if (
          ext == "html" ||
          ext == "css" ||
          ext == "js" ||
          ext == "php" ||
          ext == "py"
        ) {
          return "code.png";
        }
        if (
          ext == "json" ||
          ext == "xml" ||
          ext == "csv" ||
          ext == "tsv" ||
          ext == "yaml"
        ) {
          return "json.png";
        }
        if (ext == "ai" || ext == "eps") {
          return "ai.png";
        }
        if (ext == "psd" || ext == "psb") {
          return "potoshop.png";
        }
        return "file.png";
      }
    },
    $fp(price) {
      if (price >= 1000000000) {
        return (price / 1000000000).toFixed(1).replace(/\.0$/, "") + "B RWF";
      }
      if (price >= 1000000) {
        return (price / 1000000).toFixed(1).replace(/\.0$/, "") + "M RWF";
      }
      return price + " RWF";
    },
    $getHouse() {
      if (
        localStorage.getItem("ipms_house") &&
        localStorage.getItem("ipms_house") != "undefined"
      ) {
        const house = JSON.parse(localStorage.getItem("ipms_house"));
        return house;
      } else {
        return false;
      }
    },
    $getHouseId() {
      if (
        localStorage.getItem("ipms_house") &&
        localStorage.getItem("ipms_house") != "undefined"
      ) {
        const house = JSON.parse(localStorage.getItem("ipms_house"));
        return house.house_id;
      } else {
        return false;
      }
    },
    $getOrganization() {
      if (
        localStorage.getItem("ipms_admin") &&
        localStorage.getItem("ipms_admin") != "undefined"
      ) {
        const organization = JSON.parse(
          localStorage.getItem("ipms_admin")
        ).organization;
        return organization;
      } else {
        return false;
      }
    },
    $getOrganizationId() {
      if (
        localStorage.getItem("ipms_admin") &&
        localStorage.getItem("ipms_admin") != "undefined"
      ) {
        const organization = JSON.parse(
          localStorage.getItem("ipms_admin")
        ).organization;
        return organization.organization_id;
      } else {
        return false;
      }
    },

    $getAdminsByOrg(id) {
      this.$store
        .dispatch("GET_ADMINS_BY_ORG", {
          token: this.$getAdminToken(),
          data: {
            organization_id: id,
            page: 1,
            paginate: 1000,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.admins = res.data.admins;
          }
        })
        .catch(() => {});
    },

    $getWings() {
      this.$store
        .dispatch("GET_WINGS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            this.wings = res.data.wings.data;
          }
        })
        .catch(() => {});
    },

    $getRoomClasses() {
      this.$store
        .dispatch("GET_ROOM_CLASSES", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.roomClasses = res.data.data.data;
            }
          }
        })
        .catch(() => {});
    },

    $getFloors() {
      this.$store
        .dispatch("GET_FLOORS_BY_HOUSE_ID", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.floors = res.data.floors.data;
            }
          }
        })
        .catch(() => {});
    },
    $getFloorsByWing(id) {
      this.$store
        .dispatch("GET_FLOORS_BY_WING_ID", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
            wing_id: id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.floors = res.data.floors.data;
            }
          }
        })
        .catch(() => {});
    },
    $getRooms() {
      this.$store
        .dispatch("GET_ROOMS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.rooms.data;
            }
          }
        })
        .catch(() => {});
    },
    $getRoomsByFloor(id) {
      this.$store
        .dispatch("GET_ROOMS_BY_FLOOR", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
            floor_id: id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.rooms.data;
            }
          }
        })
        .catch(() => {});
    },
    $getRoomsByWing(id) {
      this.$store
        .dispatch("GET_ROOMS_BY_WING", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
            wing_id: id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.rooms.data;
            }
          }
        })
        .catch(() => {});
    },
    $getRoomsByRoomCategory(id) {
      this.$store
        .dispatch("GET_ROOMS_BY_ROOM_CATEGORY", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
            room_category_id: id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.rooms.data;
            }
          }
        })
        .catch(() => {});
    },

    $amount(amount) {
      if (isNaN(amount)) {
        return amount;
      } else {
        // add K M B T suffix if greater than 1000
        if (amount >= 1000000000) {
          return (amount / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
        }
        if (amount >= 1000000) {
          return (amount / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
        }
        if (amount >= 10000) {
          return (amount / 1000).toFixed(1).replace(/\.0$/, "") + "K";
        }
        return amount;
      }
    },

    $overGracePeriod(dueDate, today) {
      let due = new Date(dueDate);
      let todayDate = new Date(today);
      let diff = todayDate - due;
      return diff > 15 * 24 * 60 * 60 * 1000;
    },
    $money(amount) {
      return this.$comma(amount) + " RWF";
    },
    $startLogin(value) {
      this.showLogin = value;
    },

    $access(name, value) {
      let admin = JSON.parse(localStorage.getItem("ipms_admin"));
      if (admin.is_owner) {
        return true;
      }
      try {
        const permissions = JSON.parse(
          this.$getAdmin().role.permissions || "{}"
        );
        const currentPermission = permissions.find(
          (permission) => permission.name === name
        );
        if (!currentPermission) {
          return false;
        }
        if (Array.isArray(currentPermission.values)) {
          return currentPermission.values.includes(value);
        }
        return currentPermission.values === value;
      } catch (error) {
        console.error("Error parsing permissions:", error);
        return false;
      }
    },
  },
};
</script>