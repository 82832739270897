<template>
  <div class="tenant-dashboard">
    <NavBar />
    <div class="tenant-dashboard-content">
      <div class="container">
        <TenantNav />
        <div class="dashboard-content">
          <div class="dashboard-content-left">
            <div class="admin-posts-section">
              <div class="posts-list">
                <div class="posts-list-items">
                  <div
                    class="posts-list-item"
                    v-for="item in allItems"
                    :key="item.id"
                  >
                    <div class="post-item">
                      <div class="post-item-content">
                        <div
                          class="post-image"
                          @click="
                            selectedItem = item;
                            startViewItem = true;
                          "
                        >
                          <img
                            :src="$upload(item.image)"
                            alt="post"
                            class="post-image"
                          />
                        </div>
                        <div
                          class="post-item-title"
                          @click="
                            selectedItem = item;
                            startViewItem = true;
                          "
                        >
                          <h3>{{ item.title }}</h3>
                          <div class="tags">
                            <span
                              class="tag"
                              v-for="tag in item.tags"
                              :key="tag"
                            >
                              {{ tag }}</span
                            >
                          </div>
                        </div>
                        <div
                          class="post-item-description"
                          @click="
                            selectedItem = item;
                            startViewItem = true;
                          "
                        >
                          <p v-html="item.post_content"></p>
                        </div>
                        <div class="post-item-footer">
                          <div class="creator">
                            <div class="right flex-between">
                              <h3>Published by {{ item.admin.name }}</h3>
                              <p>{{ $date(item.created_at) }}</p>
                            </div>
                          </div>
                          <div class="comments-section">
                            <div class="stats">
                              <!-- likes -->
                              <span
                                class="likes"
                                @click="likePost(item.post_id)"
                              >
                                <i class="fas fa-thumbs-up"></i> Likes({{
                                  item.likes
                                }})
                              </span>
                              <!-- comments -->
                              <span class="comments">
                                <i class="fas fa-comment"></i> Comments({{
                                  item.comments.length
                                }})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination">
                  <button
                    :class="{ disabled: currentPage === 1 }"
                    @click="$previousPage()"
                  >
                    <label>Previous</label>
                  </button>
                  <p v-if="currentPage > 3">...</p>
                  <button
                    class="numbers"
                    v-for="number in pagesNumber"
                    :key="number"
                  >
                    <label
                      :class="{ active: currentPage === number }"
                      for=""
                      v-if="
                        (number == 1 && currentPage <= 2) ||
                        (currentPage + 2 >= pagesNumber &&
                          number >= pagesNumber - 2) ||
                        (number >= currentPage - 2 && number <= currentPage + 2)
                      "
                      @click="$changePage(number)"
                    >
                      {{ number }}
                    </label>
                  </button>
                  <p v-if="currentPage <= pagesNumber - 3">...</p>
                  <button
                    :class="{ disabled: currentPage === pagesNumber.length }"
                    @click="$nextPage()"
                  >
                    <label>Next</label>
                  </button>
                </div>
              </div>
              <div class="popular-posts">
                <h3>Popular Posts</h3>
                <div class="popular-posts-items">
                  <div
                    class="popular-posts-item"
                    v-for="item in popularPosts"
                    :key="item.id"
                  >
                    <div class="post-item">
                      <div class="post-item-content">
                        <div class="left">
                          <div class="post-image">
                            <img
                              :src="$upload(item.image)"
                              alt="post"
                              class="post-image"
                            />
                          </div>
                        </div>
                        <div class="right">
                          <div class="post-item-title">
                            <h3>{{ item.title }}</h3>
                            <p>{{ $date(item.created_at) }}</p>
                            <div class="stats">
                              <!-- likes -->
                              <span class="likes">
                                <i class="fas fa-thumbs-up"></i>
                                {{ item.likes }}
                              </span>
                              <!-- comments -->
                              <span class="comments">
                                <i class="fas fa-comment"></i>
                                {{ item.comments.length }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          :title="'Post Details'"
          :size="'sm'"
          v-model:open="startViewItem"
          @closeModel="startViewItem = false"
          centered
          width="1000px"
          :footer="false"
        >
          <div class="post-modal">
            <div class="post-modal-image">
              <img :src="$upload(selectedItem.image)" alt="post" />
            </div>
            <div class="post-modal-content">
              <h3>{{ selectedItem.title }}</h3>
              <div class="tags">
                <span class="tag" v-for="tag in selectedItem.tags" :key="tag">
                  {{ tag }}</span
                >
              </div>
              <div class="post-text-details">
                <p v-html="selectedItem.post_content"></p>
              </div>
              <div class="post-engagements">
                <div class="likes">
                  <i class="fas fa-thumbs-up"></i>
                  <span>{{ $comma(selectedItem.likes) }}</span>
                </div>
                <div class="comments">
                  <i class="fas fa-comment"></i>
                  <span>{{ $comma(selectedItem.comments.length) }}</span>
                </div>
              </div>
              <div class="post-comments">
                <h3>
                  <i class="fa-regular fa-message"></i>
                  Comments({{ selectedItem.comments.length }})
                </h3>
                <div class="post-comment-form">
                  <form @submit.prevent="addComment(selectedItem.post_id)">
                    <textarea
                      placeholder="Write a comment"
                      rows="3"
                      v-model="newComment.comment"
                    ></textarea>
                    <button type="submit">Send</button>
                  </form>
                </div>
                <div
                  class="post-comment-item"
                  v-for="comment in selectedItem.comments"
                  :key="comment.id"
                >
                  <div class="comment-author">
                    <div class="author-image" v-if="comment.user">
                      <img
                        :src="$upload(comment.user.tenant_image)"
                        alt="Author image"
                      />
                    </div>
                    <div class="right">
                      <div class="author-names">
                        <h4>{{ comment.user.tenant_name }}</h4>
                        <p>{{ $ago(comment.created_at) }}</p>
                      </div>
                      <div class="comment-text">
                        <p>{{ comment.comment }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      allItemsCount: 0,
      allItems: [],
      tenantSubscriptions: [],
      selectedItems: [],
      allSelected: false,
      startViewSubscription: false,
      selectedItem: {
        title: "",
        image: "",
        post_content: "",
      },
      popularPosts: [],
      startViewItem: false,
      newComment: {
        comment: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POSTS_BY_HOUSE_ID", {
          token: this.$getTenantToken(),
          data: {
            house_id: this.$getTenant().house_id,
            paginate: this.itemsPerpage,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.data;
              this.pagesNumber = Math.ceil(res.data.total / this.itemsPerpage);
              this.allItemsCount = res.data.total;

              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    likePost(id) {
      this.$startLoader();
      this.$store
        .dispatch("LIKE_POST", {
          token: this.$getTenantToken(),
          data: {
            post_id: id,
            house_id: this.$getTenant().house_id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = this.allItems.map((item) => {
                if (item.post_id === id) {
                  item.likes = res.data.likes;
                }
                return item;
              });
            } else {
              toast.error(res.data.message);
            }
            this.$stopLoader();
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
    getPosts() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POPULAR_POSTS", {
          token: this.$getTenantToken(),
          data: {
            house_id: this.$getTenant().house_id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.popularPosts = res.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    addComment(post_id) {
      this.$startLoader();
      this.$store
        .dispatch("CREATE_POST_COMMENT", {
          token: this.$getTenantToken(),
          data: {
            post_id,
            comment: this.newComment.comment,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.newComment.comment = "";
              this.selectedItem.comments.push(res.data.comment);
            } else {
              toast.error(res.data.message);
            }
            this.$stopLoader();
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.getPosts();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-dashboard {
  background: #c6cae046;
  min-height: 100vh;
}
.tenant-dashboard-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.dashboard-content {
  padding: 0 1rem;
  margin: 0 1rem;
  height: 85vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .dashboard-content-left {
    .dashboard-content-header {
      h3 {
        font-size: 1.15rem;
        font-weight: 500;
      }
    }
    .tenant-dashboard-widget {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      margin: 1rem 0;
      .tenant-dashboard-widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }
        form {
          width: 300px;
          input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #f3f3f3;
            padding: 0.7rem 0.5rem;
            outline: none;
          }
        }
      }
      .tenant-dashboard-widget-stats {
        display: flex;
        flex-wrap: wrap;
        .tenant-dashboard-widget-stats-item {
          flex: 1;
          padding: 1rem;
          border-right: 1px solid #f3f3f3;
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            color: $primary_color;
          }
          h3 {
            font-size: 0.8rem;
            font-weight: 400;
          }
          &:last-child {
            border-right: none;
          }
          &.danger {
            h1 {
              color: #ad0000;
            }
          }
        }
      }
    }
  }
}
.container {
  width: 100%;
  padding: 0 1rem;
}

.admin-posts-section {
  display: grid;
  grid-template-columns: 1fr 500px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
    .popular-posts {
      display: none;
    }
  }
  .add-post {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    h3 {
      font-size: 1rem;
      font-weight: bold;
      border-left: 3px $primary_color solid;
      padding-left: 0.5rem;
    }
    p {
      padding: 1rem 0;
      font-size: 0.85rem;
      color: #666;
      margin-bottom: 1rem;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      width: 100%;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .posts-list {
    width: 100%;
    .posts-list-items {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .posts-list-item {
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        border-radius: 5px;
        padding: 1rem;
        .post-item {
          .post-item-content {
            .post-image {
              width: 100%;
              height: 250px;
              border-radius: 5px;
              object-fit: cover;
              margin-bottom: 1rem;
              cursor: pointer;
            }
            .post-item-title {
              cursor: pointer;
              h3 {
                font-size: 1rem;
                font-weight: bold;
                margin-bottom: 1rem;
              }
            }
            .post-item-description {
              cursor: pointer;
              margin-top: 1rem;
              p {
                font-size: 0.85rem;
                color: #333;
                margin-bottom: 1rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              p {
                &:deep(h1),
                &:deep(h2),
                &:deep(h3),
                &:deep(h4),
                &:deep(h5),
                &:deep(h6) {
                  font-size: 14px;
                  color: #333;
                  margin-top: 10px;
                  line-height: 1.5;
                }
              }
            }
            .post-item-footer {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              .creator {
                width: 100%;
                .right {
                  h3 {
                    font-size: 0.9rem;
                    font-weight: 500;
                  }
                  p {
                    font-size: 0.85rem;
                    color: #666;
                    font-style: italic;
                  }
                }
              }
              .comments-section {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .stats {
                  display: flex;
                  gap: 1rem;
                  span {
                    font-size: 0.85rem;
                    color: #666;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    border: 1px solid #ccc;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    cursor: pointer;
                    &.likes {
                      color: #007bff;
                      i {
                        color: #007bff;
                      }
                      &:hover {
                        background-color: #007bff;
                        border-color: #007bff;
                        color: #fff;
                        i {
                          color: #fff;
                        }
                      }
                    }
                    &.comments {
                      color: #007bff;
                      i {
                        color: #007bff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.post-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  .post-modal-image {
    width: 100%;
    height: 60vh;
    background-color: #e2e2e2;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .post-modal-content {
    padding: 1rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    height: 60vh;
    .tags {
      .tag {
        display: inline-block;
        background-color: #f5f5f5;
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 10px;
        font-size: 14px;
        color: #666;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      color: $secondary_color;
    }
    .post-text-details {
      p {
        font-size: 14px;
        color: #333;
        margin-top: 10px;
        line-height: 1.5;
      }
      p {
        &:deep(h1),
        &:deep(h2),
        &:deep(h3),
        &:deep(h4),
        &:deep(h5),
        &:deep(h6) {
          font-size: 14px;
          color: #333;
          margin-top: 10px;
          line-height: 1.5;
        }
      }
    }
    .post-engagements {
      display: flex;
      margin-top: 20px;
      background-color: #e9f4f5a6;
      padding: 15px 20px;
      border-radius: 5px;
      gap: 2rem;
      .likes,
      .comments {
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          color: #222;
          margin-right: 5px;
        }

        span {
          font-size: 16px;
          color: #222;
        }
      }
    }
    .post-comments {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 10px;
        i {
          font-size: 16px;
          color: #222;
          margin-right: 10px;
        }
      }
      .post-comment-form {
        padding-bottom: 10px;
        form {
          display: flex;
          gap: 10px;
          textarea {
            width: 100%;
            padding: 10px;
            border: 1px solid #f5f5f5;
            border-radius: 5px;
            outline: none;
            height: 50px;
          }
          button {
            padding: 10px 20px;
            background-color: $secondary_color;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              background-color: darken($secondary_color, 10%);
            }
          }
        }
      }
      .post-comment-item {
        display: flex;
        gap: 10px;
        flex-direction: column;
        margin-bottom: 20px;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 10px;
        &:last-child {
          border-bottom: none;
        }
        .comment-author {
          display: flex;
          align-items: flex-start;
          .author-image {
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
              object-fit: cover;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .author-names {
              h4 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
                line-height: 1.5;
              }
              p {
                color: #666;
                margin: 0;
                font-size: 14px;
                line-height: 1.5;
                font-style: italic;
              }
            }
            .comment-text {
              p {
                font-size: 14px;
                color: #333;
                line-height: 1.5;
                margin-top: 0px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
