import axios from "axios";
import { BACKEND } from "../variables";
const GET_STATISTICS_URL = BACKEND + "/statistics";
const GET_REPORTS_URL = BACKEND + "/reports";

const site = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_STATISTICS"]: (state, payload) => {
      return axios({
        url: GET_STATISTICS_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_REPORTS"]: (state, payload) => {
      return axios({
        url: GET_REPORTS_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default site;
