<template>
  <div id="Footer">
    <div class="top">
      <div class="left">
        <img src="/img/logob.png" alt="logo" />
        <div class="footer-list">
          <p>
            Duis pulvinar metus elit, ut aliquam est sollicitudin finibus.
            Integer lobortis est interdum.
          </p>
          <div class="socials">
            <a :href="'df'" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a :href="'df'" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-instagram"></i>
            </a>
            <a :href="'sdf'" target="_blank" rel="noopener noreferrer">
              <i class="fa-brands fa-x-twitter"></i>
            </a>
            <a :href="'df'" target="_blank" rel="noopener noreferrer">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="middle">
        <h3 class="title">Quick Links</h3>
        <div class="footer-list">
          <ul>
            <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
            <li><a :href="'#listing'">Listing</a></li>
            <li>
              <a :href="'#about'">About</a>
            </li>
            <li><a :href="'#contact'">Contact</a></li>
          </ul>
        </div>
      </div>
      <div class="middle-2">
        <h3 class="title">Contact Info</h3>
        <div class="footer-list">
          <ul>
            <li>
              <i class="fa-solid fa-location-dot"></i>
              <span>Kigali, Rwanda</span>
            </li>
            <li>
              <i class="fa-solid fa-phone"></i>
              <span>+250 788 300 000</span>
            </li>
            <li>
              <i class="fa-solid fa-envelope"></i>
              <span>info@centenary.com</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <h3 class="title">Reach Out</h3>
        <p>
          Duis pulvinar metus elit, ut aliquam est sollicitudin finibus. Integer
          lobortis est interdum.
        </p>
        <a href="tel:+250788300000" class="btn btn-primary">Call Us</a>
      </div>
    </div>
    <div class="bottom">
      <p>
        &copy; {{ new Date().getFullYear() }} Centenary House. All rights
        reserved.
      </p>
      <p>
        Powered by
        <a
          href="https://iremetech.com/"
          target="_blank"
          rel="noopener noreferrer"
          >Ireme Technologies</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/main.scss";
#Footer {
  width: 100%;
  color: #000;
  border-top: 1px #eee solid;
  background-color: #a5a2d333;
  overflow-x: hidden;
  .top {
    background: #fff;
    border-radius: 20px 20px 0 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 3rem 8%;
    gap: 1rem;
    width: 100%;
    ul {
      list-style: none;
      li {
        margin: 10px 0;
        color: #000;
      }
    }
    .left {
      img {
        width: 125px;
        margin-bottom: 1rem;
      }
      p {
        font-size: 16px;
        margin-bottom: 1rem;
        color: #333;
        line-height: 1.5;
      }
      .socials {
        display: flex;
        gap: 10px;
        i {
          font-size: 20px;
          background: $primary_color;
          padding: 10px;
          border-radius: 5px;
          color: #fff;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: $secondary_color;
          }
        }
      }
    }
    .middle {
      h3 {
        margin-bottom: 1rem;
      }
      .footer-list {
        ul {
          li {
            a {
              color: #000;
            }
          }
        }
      }
    }
    .middle-2 {
      h3 {
        margin-bottom: 1rem;
      }
      .footer-list {
        ul {
          li {
            color: #000;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            line-height: 1.5;
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
    .right {
      h3 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 1rem;
        font-size: 16px;
        line-height: 1.5;
      }
      .btn {
        background: $primary_color;
        color: #fff;
        width: 200px;
        display: inline-block;
        text-align: center;
        padding: 15px 0;
        border-radius: 5px;
        &:hover {
          background: $secondary_color;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .top {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
  }
  @media (max-width: 900px) {
    .top {
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
    }
  }
  .bottom {
    padding: 20px 8%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px #eee solid;
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0;
      font-weight: 400;
      color: #777;
    }
    a {
      color: $primary_color;
      font-weight: 600;
      &:hover {
        color: $secondary_color;
      }
    }
  }
  @media (max-width: 900px) {
    .bottom {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>