<template>
  <div class="dashboard">
    <div class="dash-content" v-if="selectedItem">
      <h1 class="page-title">
        <span>Post Details</span>
      </h1>
      <div class="post-details-section">
        <div class="post-details-widget left-widget">
          <div class="post-image">
            <img :src="$upload(selectedItem.image)" alt="Post image" />
          </div>
          <div class="post-image-footer-details">
            <div class="post-author">
              <div class="author-image">
                <img
                  :src="$upload(selectedItem.admin.image)"
                  alt="Author image"
                />
              </div>
              <div class="author-names">
                <h3>{{ selectedItem.admin.name }}</h3>
                <p>{{ $date(selectedItem.created_at) }}</p>
              </div>
            </div>
            <div class="right-btns">
              <a-tooltip
                placement="top"
                title="Publish Post"
                v-if="selectedItem.status === 'draft'"
              >
                <button
                  @click="publishItem(selectedItem)"
                  class="my-btn publish"
                >
                  <i class="fas fa-check-circle"></i>
                  <span>Publish</span>
                </button>
              </a-tooltip>
              <a-tooltip placement="top" title="Unpublish Post" v-else>
                <button
                  @click="unpublishItem(selectedItem)"
                  class="my-btn unpublish"
                >
                  <i class="fas fa-times-circle"></i>
                  <span>Make Draft</span>
                </button>
              </a-tooltip>
              <a-tooltip
                placement="top"
                title="Delete Post"
                v-if="$access('Posts', 'delete')"
              >
                <button @click="startDelete = true" class="my-btn danger">
                  <i class="fas fa-trash"></i>
                  <span>Delete</span>
                </button>
              </a-tooltip>
            </div>
          </div>
          <div class="tags">
            <span class="tag" v-for="tag in selectedItem.tags" :key="tag">
              {{ tag }}</span
            >
          </div>
          <div class="post-text-details">
            <h3>{{ selectedItem.title }}</h3>
            <p v-html="selectedItem.post_content"></p>
          </div>
          <div class="post-engagements">
            <div class="likes">
              <i class="fas fa-thumbs-up"></i>
              <span>{{ $comma(selectedItem.likes) }}</span>
            </div>
            <div class="comments">
              <i class="fas fa-comment"></i>
              <span>{{ $comma(selectedItem.comments.length) }}</span>
            </div>
          </div>
          <div class="post-comments">
            <h3>
              <i class="fa-regular fa-message"></i>
              Comments({{ selectedItem.comments.length }})
            </h3>
            <div
              class="post-comment-item"
              v-for="comment in selectedItem.comments"
              :key="comment.id"
            >
              <div class="comment-author">
                <div class="author-image">
                  <img
                    :src="$upload(comment.user.tenant_image)"
                    alt="Author image"
                  />
                </div>
                <div class="right">
                  <div class="author-names">
                    <h4>{{ comment.user.tenant_name }}</h4>
                    <p>{{ $date(comment.created_at) }}</p>
                  </div>
                  <div class="comment-text">
                    <p>{{ comment.comment }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="post-details-widget right-widget">
          <div class="popular-posts">
            <h3>Popular Posts</h3>
            <div class="popular-posts-items">
              <div
                class="popular-posts-item"
                v-for="item in popularItems"
                :key="item.id"
              >
                <div class="post-item">
                  <div class="post-item-content">
                    <div class="left">
                      <div class="post-image">
                        <img
                          :src="$upload(item.image)"
                          alt="post"
                          class="post-image"
                        />
                      </div>
                    </div>
                    <div class="right">
                      <div class="post-item-title">
                        <h3>{{ item.title }}</h3>
                        <p>{{ $date(item.created_at) }}</p>
                        <div class="stats">
                          <!-- likes -->
                          <span class="likes">
                            <i class="fas fa-thumbs-up"></i>
                            {{ item.likes }}
                          </span>
                          <!-- comments -->
                          <span class="comments">
                            <i class="fas fa-comment"></i>
                            {{ item.comments.length }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        :title="'Delete post'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this post?</p>
      </Modal>
    </div>
  </div>
</template>
    
    <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      selectedItem: null,
      selectedId: null,
      startDelete: false,
      popularItems: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POSTS_BY_ID", {
          token: this.$getAdminToken(),
          data: {
            post_id: this.selectedId,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.selectedItem = res.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getPopularItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POPULAR_POSTS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.popularItems = res.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    publishItem(item) {
      item.status = "published";
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_POST", {
          data: item,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Post published.");
              this.getItems();
              this.getPopularItems();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    unpublishItem(item) {
      item.status = "draft";
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_POST", {
          data: item,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Post unpublished.");
              this.getItems();
              this.getPopularItems();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_POST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.startDelete = false;
              this.$router.push({ name: "AdminPosts" });
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.selectedId = this.$route.params.id;
      this.getItems();
      this.getPopularItems();
    } else {
      this.$router.push({ name: "AdminPosts" });
    }
  },
};
</script>
    
    <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding: 0 1rem;
  padding-bottom: 5rem;
}
.post-details-section {
  display: grid;
  grid-template-columns: 1fr 500px;
  gap: 20px;
  align-items: flex-start;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  .post-details-widget {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    .post-image {
      width: 100%;
      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .post-image-footer-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .post-author {
        display: flex;
        align-items: center;
        .author-image {
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
          }
        }
        .author-names {
          h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            line-height: 1.5;
          }
          p {
            line-height: 1.5;
            font-size: 0.8rem;
            color: #666;
            margin: 0;
          }
        }
      }
      .right-btns {
        display: flex;
        button {
          margin-left: 10px;
        }
      }
    }
    .tags {
      margin-top: 20px;
      .tag {
        display: inline-block;
        background-color: #f5f5f5;
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 10px;
        font-size: 14px;
        color: #666;
      }
    }
    .post-text-details {
      margin-top: 20px;
      h3 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
      }
      p {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
        line-height: 1.5;
      }
    }
    .post-engagements {
      display: flex;
      margin-top: 20px;
      background-color: #e9f4f5a6;
      padding: 15px 20px;
      border-radius: 5px;
      gap: 2rem;
      .likes,
      .comments {
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          color: #222;
          margin-right: 5px;
        }

        span {
          font-size: 16px;
          color: #222;
        }
      }
    }
    .post-comments {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 10px;
        i {
          font-size: 16px;
          color: #222;
          margin-right: 10px;
        }
      }
      .post-comment-item {
        display: flex;
        gap: 20px;
        flex-direction: column;
        margin-bottom: 20px;
        .comment-author {
          display: flex;
          align-items: flex-start;
          .author-image {
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 10px;
              object-fit: cover;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .author-names {
              h4 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
                line-height: 1.5;
              }
              p {
                color: #666;
                margin: 0;
                font-size: 14px;
                line-height: 1.5;
              }
            }
            .comment-text {
              p {
                font-size: 14px;
                color: #666;
                line-height: 1.5;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>