<template>
  <div class="tenant-nav">
    <div class="tenant-nav-content">
      <div class="tenant-nav-content-header">
        <label for="">Centenary house</label>
      </div>
      <div class="tenant-nav-content-inner">
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'TenantDashboard' }"
            ><i class="fa fa-tachometer"></i> Dashboard</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'TenantBills' }"
            ><i class="fa-solid fa-money-bill-1-wave"></i> Bills</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'TenantSubscriptions' }"
            ><i class="fa fa-credit-card"></i> Subcriptions</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'TenantMessages' }"
            ><i class="fa fa-envelope"></i> Messages
            <Tag color="#e96767" v-if="unread > 0">{{
              unread
            }}</Tag></router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'TenantPosts' }"
            ><i class="fa fa-bell"></i> Posts</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'Home' }"
            ><i class="fa fa-exclamation-triangle"></i> Complaints</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'TenantCancellationRequests' }"
            ><i class="fa fa-ban"></i> Cancellations</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <router-link :to="{ name: 'Home' }"
            ><i class="fa fa-cog"></i> Settings</router-link
          >
        </div>
        <div class="tenant-nav-content-inner-item">
          <a href="#" @click="$tenantLogout"
            ><i class="fa fa-sign-out"></i> Logout</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag } from "ant-design-vue";
import { toast } from "vue3-toastify";
export default {
  components: {
    Tag,
  },
  data() {
    return {
      unread: 0,
    };
  },
  methods: {
    getChats() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_CHATS", {
          token: this.$getTenantToken(),
          data: {
            house_id: this.$getTenant().house_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              // this.chats = res.data.chats;
              this.unread = res.data.unread;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (!this.$getTenant()) {
      this.$tenantLogout();
    }
    this.getChats();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-nav {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(63, 63, 63, 0.1);
  height: 85vh;
  .tenant-nav-content {
    .tenant-nav-content-header {
      padding: 1rem;
      border-bottom: 2px solid #7e82af;
      label {
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .tenant-nav-content-inner {
      display: flex;
      flex-direction: column;
      .tenant-nav-content-inner-item {
        a {
          padding: 1rem 1rem;
          border-bottom: 1px solid #f3f3f3;
          display: block;
          color: #000;
          font-size: 0.9rem;
          transition: all 0.3s ease;
          font-weight: 500;
          &:hover {
            background: #7e82af73;
          }
        }
        .router-link-active {
          background: #a8ade65e;
          color: $primary_color;
        }
      }
    }
  }
}
</style>
