<template>
  <miLoader v-if="$store.state.startLoader" />
  <notifications />
  <!-- <router-view v-if="this.$store.state.sitesettings" /> -->
  <router-view />
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getSettings() {
      this.$store.dispatch("GET_SETTINGS").then((res) => {
        if (res) {
          if (res.status === 200) {
            this.$store.state.sitesettings = res.data.settings;
          }
        }
      });
    },
  },
  mounted() {
    // this.getSettings();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}
a {
  text-decoration: none;
  color: #000;
}

@import "@/assets/main.scss";
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.bp {
  background: $primary_color;
}
.cp {
  color: $primary_color;
}
.bw {
  background: #fff;
}
.cw {
  color: #fff;
}
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: 500;
  i {
    margin-left: 5px;
  }
  &:hover {
    // transform: scale(1.1);
  }
}
.input-group {
  gap: 20px;
}
.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.group-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
.group-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.my-btn {
  background: $primary_color;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    width: 50px;
    overflow: hidden;
    span {
      display: none;
    }
  }
  &.sm {
    padding: 5px 10px;
  }
  &.wd {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.publish {
    background-color: #2e7d3228 !important;
    color: #4caf50 !important;
  }
  &.unpublish {
    background-color: #c55b2a2a !important;
    color: #a0511d !important;
  }
  &.danger {
    background-color: #c52a442a !important;
    color: #c52a44 !important;
  }
  &.glow {
    box-shadow: 0px 0px 15px 0px #1d21887e;
  }
  &:hover {
    background: darken($color: $primary_color, $amount: 10%);
  }
  &.bw {
    background: #fff;
    color: $primary_color;
    &:hover {
      background: #f0f0f0;
    }
  }
  &.round {
    border-radius: 50px !important;
  }
  &.green {
    background: green;
    &:hover {
      background: darken($color: green, $amount: 10%);
    }
  }
  &.red {
    background: rgb(182, 57, 57);
    &:hover {
      background: darken($color: rgb(182, 57, 57), $amount: 10%);
    }
  }
  &.default {
    background: #f0f0f0;
    color: #000;
  }
  &.blue {
    background: $primary_color;
    &:hover {
      background: darken($color: $primary_color, $amount: 10%);
    }
  }
}
.sub-btns {
  .btn-group {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
      padding: 7px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 0.8rem;
      font-weight: 500;
      &.edit {
        border: 1px solid $primary_color;
        color: $primary_color;
        &:hover {
          background-color: $primary_color;
          color: white;
        }
      }
      &.success {
        border: 1px solid #2d882d;
        color: #2d882d;
        &:hover {
          background-color: #2d882d;
          color: white;
        }
      }
      &.end {
        border: 1px solid #a82929;
        color: #a82929;
        &:hover {
          background-color: #a82929;
          color: white;
        }
      }
    }
  }
}

.container {
  width: 1500px;
  @media (max-width: 2400px) {
    width: 1300px;
  }
  @media (max-width: 1500px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    label {
      padding: 5px 15px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin: 0 10px;
      transition: all 0.3s;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: $primary_color;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.active {
        background-color: $primary_color;
        color: #fff;
      }
    }
  }
}
.form-group {
  .form-control {
    padding: 10px 5px;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    margin-bottom: 20px;
    outline: none;
    font-size: 0.8rem;
  }
  label {
    font-size: 0.8rem;
    margin-bottom: 10px;
    display: inline-block;
  }
}
.info {
  background: $secondary_color;
  color: #fff;
}
.my-table {
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      th {
        text-align: left;
        padding: 0.51rem 0.5rem;
        font-weight: 600;
        border-bottom: 1px solid #e5e8ee;
        font-size: 0.8rem;
        color: $secondary_color;
        text-transform: uppercase;
        @media (max-width: 1500px) {
          font-size: 0.6rem;
        }
        @media (max-width: 1200px) {
          font-size: 0.5rem;
        }
      }
      background: #cfcedd;
    }
    tbody {
      tr {
        td {
          padding: 5px;
          border-bottom: 1px solid #e5e8ee;
          font-size: 0.9rem;
          @media (max-width: 1500px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1200px) {
            font-size: 0.6rem;
          }
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 5px;
          }
          a {
            color: $primary_color;
            font-weight: 500;
          }
          button {
            margin-left: 10px;
            padding: 0.3rem 1rem;
            border-radius: 50px;
            background-color: #f3f3f3;
            color: #333;
            &.edit {
              background-color: $primary_color;
              color: white;
              &:hover {
                background-color: $secondary_color;
              }
            }
            &.delete {
              background-color: #a82929;
              color: white;
              &:hover {
                background-color: #7a1f1f;
              }
            }
            &.default {
              background-color: $primary_color;
              color: white;
              &:hover {
                background-color: $secondary_color;
              }
            }
          }
        }
      }
    }
  }
}
.flex-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
}

.cancel {
  background-color: #a82929;
  color: white;
  &:hover {
    background-color: #7a1f1f;
  }
}

.page-title {
  span {
    font-size: 1.2rem;
    color: $secondary_color;
    font-weight: 500;
    small {
      font-size: 0.8rem;
      color: #000;
      font-weight: 300;
    }
  }
}
.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.files {
  padding: 0 2rem;
  &.list {
    .files-header {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      text-align: left;
      padding: 0.51rem 0.5rem;
      font-weight: 600;
      border-bottom: 1px solid #e5e8ee;
      font-size: 0.7rem;
      color: $secondary_color;
      text-transform: uppercase;
      span {
        text-align: left;
      }
    }
    .files-body {
      .file {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        padding: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.034);
        cursor: pointer;
        &:hover {
          background-color: #e5e8ee;
          span {
            color: $secondary_color;
            font-weight: 600;
          }
        }
        span {
          text-align: left;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
        }
        img {
          height: 20px;
          width: 20px;
          object-fit: contain;
          margin-right: 0.5rem;
        }
      }
    }
  }
  &.grid {
    .files-body {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;
      .file {
        padding: 1rem;
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        &:hover {
          box-shadow: 0 0 5px #2d274933;
          background: #efedf5;
          cursor: pointer;
        }
        span {
          display: block;
          text-align: center;
          font-size: 0.9rem;
          font-weight: 600;
          color: $secondary_color;
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          label {
            margin-top: 0.5rem;
          }
          &.smalls {
            display: none;
          }
        }
        img {
          height: 50px;
          width: 50px;
          object-fit: contain;
          margin: 0 auto;
        }
      }
    }
    .files-header {
      display: none;
    }
    .search-section {
      margin-bottom: 2rem;
    }
  }
  label {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
  }

  .search-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      padding: 0.3rem 0.5rem;
      border-bottom: 1px solid #e5e8ee;
      margin-right: 1rem;
      width: 300px;
      background: white;
      font-size: 0.9rem;
    }
    .displayModel {
      button {
        padding: 0.5rem 1rem;
        border: 1px solid #e5e8ee;
        border-radius: 5px;
        margin-right: 1rem;
        background-color: white;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #e5e8ee;
        }
        &.active {
          background-color: $primary_color;
          color: white;
        }
      }
    }
  }
}
.form-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &.cancel {
      background-color: #a82929;
      color: white;
      &:hover {
        background-color: #7a1f1f;
      }
    }
    &.save {
      background-color: $primary_color;
      color: white;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
}

.admin-forms {
  padding: 2rem 0;
  border-radius: 5px;
  .input-group {
    margin-bottom: 1rem;
    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
      input,
      select,
      textarea,
      .my-multiselect {
        width: 100%;
        padding: 0.9rem;
        border-radius: 5px;
        border: none;
        margin-bottom: 1rem;
        background: #fff;
        outline: none;
        font-size: 13px;
        color: #686868;
        border: 1px solid #e5e5e5;
      }
    }
  }
  button {
    padding: 0.75rem 1rem;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    margin-top: 2rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}

.page-title {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 0.5rem 1rem;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}

.dflex {
  display: flex;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  display: flex;
  flex-wrap: wrap-reverse;
}
.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}
.align-content-center {
  align-content: center;
}
.align-content-start {
  align-content: flex-start;
}
.align-content-end {
  align-content: flex-end;
}
.align-content-between {
  align-content: space-between;
}
.align-content-around {
  align-content: space-around;
}
.align-content-stretch {
  align-content: stretch;
}
.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.chip {
  padding: 5px 15px !important;
  border-radius: 5px !important;
  background-color: #f0f0f0;
  color: #000;
  font-size: 0.75rem !important;
  margin-left: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 500 !important;
  &.chip-primary,
  &.primary {
    background-color: $primary_color;
    color: #fff;
  }
  &.chip-secondary {
    background-color: $secondary_color;
    color: #fff;
  }
  &.chip-danger,
  &.ended,
  &.rejected,
  &.inactive,
  &.Ended,
  &.overdue {
    color: #a82929;
    background-color: #f8d7da;
  }

  &.paused {
    color: #000000;
    background-color: #0000001f;
  }
  &.chip-warning,
  &.pending {
    color: #e08a00 !important;
    background-color: #fff3cd !important;
  }
  &.chip-success,
  &.active,
  &.Active,
  &.available,
  &.paid,
  &.approved {
    color: rgb(0, 156, 0);
    background-color: #d4edda;
  }
  &.chip-info {
    background-color: #007bff;
    color: #fff;
  }
  &.chip-light {
    background-color: #f8f9fa;
    color: #000;
  }
  &.chip-dark {
    background-color: #343a40;
    color: #fff;
  }
  &.chip-round {
    border-radius: 50px;
  }
}

.payment-table {
  margin-top: 1rem;
  .table-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: left;
    padding: 0.51rem 0.5rem;
    font-weight: 600;
    font-size: 0.7rem;
    color: $secondary_color;
    text-transform: uppercase;
    background: #cfcedd;
    gap: 1rem;
    span {
      font-weight: bold;
      &:last-child {
        text-align: right;
      }
      &:first-child {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 0.5rem;
        input[type="checkbox"] {
          // change color of checkbox
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 15px;
          height: 15px;
          border: 1px solid #00000048;
          border-radius: 3px;
          cursor: pointer;
          display: block;
          &:hover {
            background-color: lighten($primary_color, 10%);
          }
          &:checked {
            background-color: $primary_color;
            border: 1px solid $primary_color;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before {
              content: "✔";
              font-size: 0.6rem;
              color: white;
            }
          }
        }
      }
    }
  }
  .table-body {
    width: 100%;
    .table-row {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0.5rem;
      flex-direction: column;
      border-top: 1px solid #00000015;
      width: 100%;
      cursor: pointer;
      .row-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 1rem;
        span {
          font-size: 0.9rem;
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          &.check {
            display: grid;
            grid-template-columns: 20px 1fr;
            gap: 0.5rem;
          }
          input[type="checkbox"] {
            // change color of checkbox
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            border: 1px solid #00000048;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              background-color: lighten($primary_color, 10%);
            }
            &:checked {
              background-color: $primary_color;
              border: 1px solid $primary_color;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              &:before {
                content: "✔";
                font-size: 0.6rem;
                color: white;
              }
            }
          }
          &:last-child {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            width: 100%;
          }
        }
      }
      .row-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 0.5rem;
        .table-row {
          border-bottom: 1px solid #00000015;
          display: grid;
          grid-template-columns: 1fr 1fr;
          &:last-child {
            border-bottom: none;
          }
          span {
            font-size: 0.8rem;
            color: #444;
            font-weight: 300;
            &:last-child {
              text-align: right;
            }
            &:first-child {
              padding-left: 1rem;
              &:before {
                content: "•";
                margin-right: 0.5rem;
              }
            }
          }
        }
        .export-bill {
          border: 1px solid $secondary_color !important;
          background-color: transparent;
          color: $secondary_color;
          border: none;
          border-radius: 5px;
          padding: 0.35rem 0.5rem;
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          width: 110px;
          font-size: 0.8rem;
          i {
            margin-right: 0.5rem;
          }
          &:hover {
            cursor: pointer;
            background-color: $secondary_color;
            color: white;
          }
        }
      }
    }
  }
}
.no-data {
  font-size: 0.8rem;
  color: #818181;
}
.gap-10 {
  gap: 10px;
}
.gap-20 {
  gap: 20px;
}
.gap-30 {
  gap: 30px;
}
.tags {
  display: flex;
  gap: 0.5rem;
  .tag {
    padding: 0.25rem 0.5rem;
    background-color: #9695fc41;
    border-radius: 5px;
    font-size: 0.75rem;
    color: $primary_color;
  }
}

.popular-posts {
  padding: 1rem;
  border-radius: 5px;
  h3 {
    font-size: 1rem;
    font-weight: bold;
    border-left: 3px $primary_color solid;
    padding-left: 0.5rem;
  }
  .popular-posts-items {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .popular-posts-item {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 1rem;
      background-color: #f9f9f9;

      .post-item {
        .post-item-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            .post-image {
              width: 120px;
              height: 120px;
              border-radius: 5px;
              object-fit: cover;
              margin-right: 1rem;
            }
          }
          .right {
            .post-item-title {
              h3 {
                font-size: 0.9rem;
                font-weight: 400;
                margin-bottom: 0.5rem;
                border: none;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 0;
                line-height: 1.5;
              }
              p {
                font-size: 0.8rem;
                color: #666;
                margin-bottom: 0.5rem;
              }
              .stats {
                display: flex;
                gap: 1rem;
                .likes,
                .comments {
                  display: flex;
                  align-items: center;
                  i {
                    margin-right: 0.5rem;
                  }
                  &.likes {
                    background: rgba(214, 43, 57, 0.13);
                    padding: 0.25rem 0.5rem;
                    border-radius: 5px;
                    font-size: 0.75rem;
                    color: #c52a44;
                  }
                  &.comments {
                    background: rgba(157, 100, 248, 0.13);
                    padding: 0.25rem 0.5rem;
                    border-radius: 5px;
                    font-size: 0.75rem;
                    color: #783bda;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.site-title {
  font-size: 1rem;
  font-weight: 500;
  color: $secondary_color;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}
.site-label {
  width: 100%;
  font-size: 3rem;
  color: $secondary_color;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  display: block;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
.none {
  display: none;
}

.tenant-dashboard-content .container {
  display: grid;
  grid-template-columns: 250px 1fr;
}
.form-control {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.tenant-dashboard-widget {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  .tenant-dashboard-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    h3 {
      font-size: 1rem;
      font-weight: 500;
      border-left: 3px solid $primary_color;
      padding-left: 0.5rem;
    }
    form {
      width: 300px;
      input {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #f3f3f3;
        padding: 0.7rem 0.5rem;
        outline: none;
      }
    }
  }
  .tenant-dashboard-widget-stats {
    display: flex;
    flex-wrap: wrap;
    .tenant-dashboard-widget-stats-item {
      flex: 1;
      padding: 1rem;
      border-right: 1px solid #f3f3f3;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 1.75rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        color: $primary_color;
      }
      h3 {
        font-size: 0.8rem;
        font-weight: 400;
      }
      &:last-child {
        border-right: none;
      }
      &.danger {
        h1 {
          color: #ad0000;
        }
      }
    }
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.not-authorized {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  text-align: center;
  margin-top: 2rem;
  width: 100%;
  display: block;
  padding: 3rem 1rem;
  border-radius: 5px;
  border: 1px dashed #ccc;
  margin: 1rem;
}
</style>
