<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Posts', 'read')">
      <h1 class="page-title">
        <span>Posts</span>
      </h1>

      <div class="my-table">
        <div class="table-filters">
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow" @click="getItems()">
              Filter
            </button>
          </div>
        </div>
        <div class="admin-posts-section">
          <div class="posts-list">
            <div
              class="add-post"
              @click="startAdd = true"
              v-if="$access('Posts', 'write')"
            >
              <h3>Create Post</h3>
              <p>
                Tell the tenants about upcoming events, maintenance, or any
                other important information.
              </p>
              <button @click="startAdd = true">Post</button>
            </div>
            <div class="posts-list-items">
              <div
                class="posts-list-item"
                v-for="item in allItems"
                :key="item.id"
              >
                <div class="post-item">
                  <div class="post-item-content">
                    <div class="post-image">
                      <img
                        :src="$upload(item.image)"
                        alt="post"
                        class="post-image"
                      />
                    </div>
                    <div class="post-item-title">
                      <h3>{{ item.title }}</h3>
                      <div class="tags">
                        <span class="tag" v-for="tag in item.tags" :key="tag">
                          {{ tag }}</span
                        >
                      </div>
                    </div>
                    <div class="post-item-description">
                      <p v-html="item.post_content"></p>
                    </div>
                    <div class="post-item-footer">
                      <div class="post-item-date">
                        <div class="creator">
                          <img
                            :src="$upload(item.admin.image)"
                            alt="creator"
                            class="creator-image"
                          />
                          <div class="right">
                            <h3>{{ item.admin.name }}</h3>
                            <p>{{ $date(item.created_at) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="post-item-actions">
                        <a-tooltip placement="top" title="View Post">
                          <template #content> <p>View Post</p> </template
                          ><router-link
                            :to="{
                              name: 'AdminViewPost',
                              params: { id: item.post_id },
                            }"
                            class="my-btn hide"
                            title=""
                          >
                            <i class="fas fa-eye"></i>
                            <span> View </span>
                          </router-link>
                        </a-tooltip>
                        <a-tooltip
                          placement="top"
                          title="Update Post"
                          v-if="$access('Posts', 'write')"
                        >
                          <button
                            @click="
                              startUpdate = true;
                              selectedItem = item;
                            "
                            class="my-btn hide"
                          >
                            <i class="fas fa-edit"></i>
                            <span>Update</span>
                          </button>
                        </a-tooltip>
                        <a-tooltip
                          placement="top"
                          title="Publish Post"
                          v-if="item.status === 'draft'"
                        >
                          <button
                            @click="publishItem(item)"
                            class="my-btn publish hide"
                          >
                            <i class="fas fa-check-circle"></i>
                            <span>Publish</span>
                          </button>
                        </a-tooltip>
                        <a-tooltip
                          placement="top"
                          title="Unpublish Post"
                          v-else
                        >
                          <button
                            @click="unpublishItem(item)"
                            class="my-btn unpublish hide"
                          >
                            <i class="fas fa-times-circle"></i>
                            <span>Make Draft</span>
                          </button>
                        </a-tooltip>
                        <a-tooltip
                          placement="top"
                          title="Delete Post"
                          v-if="$access('Posts', 'delete')"
                        >
                          <button
                            @click="
                              startDelete = true;
                              selectedItem = item;
                            "
                            class="my-btn danger hide"
                          >
                            <i class="fas fa-trash"></i>
                            <span>Delete</span>
                          </button>
                        </a-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination">
              <button
                :class="{ disabled: currentPage === 1 }"
                @click="$previousPage()"
              >
                <label>Previous</label>
              </button>
              <p v-if="currentPage > 3">...</p>
              <button
                class="numbers"
                v-for="number in pagesNumber"
                :key="number"
              >
                <label
                  :class="{ active: currentPage === number }"
                  for=""
                  v-if="
                    (number == 1 && currentPage <= 2) ||
                    (currentPage + 2 >= pagesNumber &&
                      number >= pagesNumber - 2) ||
                    (number >= currentPage - 2 && number <= currentPage + 2)
                  "
                  @click="$changePage(number)"
                >
                  {{ number }}
                </label>
              </button>
              <p v-if="currentPage <= pagesNumber - 3">...</p>
              <button
                :class="{ disabled: currentPage === pagesNumber.length }"
                @click="$nextPage()"
              >
                <label>Next</label>
              </button>
            </div>
          </div>
          <div class="popular-posts">
            <h3>Popular Posts</h3>
            <div class="popular-posts-items">
              <div
                class="popular-posts-item"
                v-for="item in popularItems"
                :key="item.id"
              >
                <div class="post-item">
                  <div class="post-item-content">
                    <div class="left">
                      <div class="post-image">
                        <img
                          :src="$upload(item.image)"
                          alt="post"
                          class="post-image"
                        />
                      </div>
                    </div>
                    <div class="right">
                      <div class="post-item-title">
                        <h3>{{ item.title }}</h3>
                        <p>{{ $date(item.created_at) }}</p>
                        <div class="stats">
                          <!-- likes -->
                          <span class="likes">
                            <i class="fas fa-thumbs-up"></i>
                            {{ item.likes }}
                          </span>
                          <!-- comments -->
                          <span class="comments">
                            <i class="fas fa-comment"></i>
                            {{ item.comments.length }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        :title="'Create post'"
        :size="'sm'"
        v-model:open="startAdd"
        @closeModel="startAdd = false"
        centered
        :okText="'Post'"
        @ok="addItem()"
        width="600px"
      >
        <div class="form-group">
          <label for="image">Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                newItem.image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newItem.image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
          <br />
        </div>
        <div class="form-group">
          <label for="title">Title</label>
          <input
            type="text"
            id="title"
            v-model="newItem.title"
            class="form-control"
            placeholder="Post title"
          />
        </div>
        <div class="form-group">
          <label for="post_content">Content</label>
          <quill-editor
            theme="snow"
            v-model:content="newItem.post_content"
            contentType="html"
            placeholder="Write here..."
          >
          </quill-editor>
        </div>
        <br />
        <div class="form-group">
          <label for="tags">Tags</label>

          <a-select
            v-model:value="newItem.tags"
            mode="tags"
            style="width: 100%"
            :token-separators="[',']"
            placeholder="Select tags"
          >
            <a-select-option
              v-for="item in [
                'New',
                'Featured',
                'Tips',
                'Announcement',
                'Event',
              ]"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div>
      </Modal>

      <!-- update -->
      <Modal
        :title="'Update post'"
        :size="'sm'"
        v-model:open="startUpdate"
        @closeModel="startUpdate = false"
        centered
        :okText="'Update'"
        @ok="updateItem()"
        width="600px"
      >
        <div class="form-group">
          <label for="image">Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                selectedItem.image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="selectedItem.image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
          <br />
        </div>
        <div class="form-group">
          <label for="title">Title</label>
          <input
            type="text"
            id="title"
            v-model="selectedItem.title"
            class="form-control"
            placeholder="Post title"
          />
        </div>
        <div class="form-group">
          <label for="post_content">Content</label>
          <quill-editor
            theme="snow"
            v-model:content="selectedItem.post_content"
            contentType="html"
            placeholder="Write here..."
          >
          </quill-editor>
        </div>
        <br />
        <div class="form-group">
          <label for="tags">Tags</label>

          <a-select
            v-model:value="selectedItem.tags"
            mode="tags"
            style="width: 100%"
            :token-separators="[',']"
            placeholder="Select tags"
          >
            <a-select-option
              v-for="item in [
                'New',
                'Featured',
                'Tips',
                'Announcement',
                'Event',
              ]"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div>
      </Modal>
      <!-- delete -->
      <Modal
        :title="'Delete post'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this post?</p>
      </Modal>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>
  
  <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      allItems: [],
      popularItems: [],
      newItem: {
        title: "",
        post_content: "",
        tags: [],
        attachments: "",
        image: "",
      },
      selectedItem: null,
      startAdd: false,
      startUpdate: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      searchQuery: {
        search: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POSTS_BY_HOUSE_ID", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getPopularItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_POPULAR_POSTS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.popularItems = res.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    addItem() {
      this.$startLoader();
      this.newItem.house_id = this.$getHouseId();
      this.$store
        .dispatch("CREATE_POST", {
          data: this.newItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startAdd = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_POST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startUpdate = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    publishItem(item) {
      item.status = "published";
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_POST", {
          data: item,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Post published.");
              this.getItems();
              this.getPopularItems();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    unpublishItem(item) {
      item.status = "draft";
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_POST", {
          data: item,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Post unpublished.");
              this.getItems();
              this.getPopularItems();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_POST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.getPopularItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (this.$access("Posts", "read")) {
      this.getItems();
      this.getPopularItems();
    }
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}

.admin-posts-section {
  display: grid;
  grid-template-columns: 1fr 500px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  .add-post {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    h3 {
      font-size: 1rem;
      font-weight: bold;
      border-left: 3px $primary_color solid;
      padding-left: 0.5rem;
    }
    p {
      padding: 1rem 0;
      font-size: 0.85rem;
      color: #666;
      margin-bottom: 1rem;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      width: 100%;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .posts-list {
    width: 100%;
    .posts-list-items {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .posts-list-item {
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        border-radius: 5px;
        padding: 1rem;
        .post-item {
          .post-item-content {
            .post-image {
              width: 100%;
              height: 100px;
              border-radius: 5px;
              object-fit: cover;
              margin-bottom: 1rem;
            }
            .post-item-title {
              h3 {
                font-size: 1rem;
                font-weight: bold;
                margin-bottom: 1rem;
              }
            }
            .post-item-description {
              margin-top: 1rem;
              p {
                font-size: 0.85rem;
                color: #666;
                margin-bottom: 1rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .post-item-footer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .post-item-date {
                .creator {
                  display: flex;
                  align-items: center;
                  .creator-image {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 1rem;
                    object-fit: cover;
                    border: 4px solid #b6c8d171;
                  }
                  .right {
                    h3 {
                      font-size: 0.9rem;
                      font-weight: 500;
                    }
                    p {
                      font-size: 0.85rem;
                      color: #666;
                    }
                  }
                }
              }
              .post-item-actions {
                display: flex;
                gap: 0.5rem;
                button {
                  padding: 0.5rem 1rem;
                  background-color: $primary_color;
                  color: white;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
                  font-size: 0.8rem;
                  &:hover {
                    background-color: $secondary_color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>