import { BACKEND } from "../variables.js";
import axios from "axios";
const ADMIN_LOGIN = BACKEND + "/admin/login";
const ADMIN_LOGOUT = BACKEND + "/admin/logout";
const GET_ADMINS = BACKEND + "/admin/getAllAdmins";
const CREATE_ADMIN = BACKEND + "/admin/createAdmin";
const UPDATE_ADMIN = BACKEND + "/admin/updateAdmin";
const DELETE_ADMIN = BACKEND + "/admin/deleteAdmin";
const UPDATE_ADMIN_PASSWORD = BACKEND + "/admin/updateAdminPassword";
const UPDATE_ADMIN_PROFILE = BACKEND + "/admin/updateAdminProfile";
const UPDATE_ADMIN_PROFILE_PASSWORD =
  BACKEND + "/admin/updateAdminProfilePassword";
const GET_ADMINS_BY_ORG = BACKEND + "/admin/getAdminsByOrganizationId";
const GET_MY_ADMINS_BY_ORG = BACKEND + "/admin/getMyOrganizationAdmins";
const GET_ADMINS_BY_HOUSE = BACKEND + "/admin/getAdminsByHouseId";
const MAKE_ADMIN_OWNER = BACKEND + "/admin/makeOwner";
const REMOVE_ADMIN_OWNER = BACKEND + "/admin/removeOwner";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["ADMIN_LOGIN"](state, payload) {
      return axios({
        method: "POST",
        url: ADMIN_LOGIN,
        data: payload,
      });
    },
    ["ADMIN_LOGOUT"](state, payload) {
      return axios({
        method: "POST",
        url: ADMIN_LOGOUT,
        data: payload,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ADMINS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ADMINS + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_ADMIN"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_ADMIN,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ADMIN"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ADMIN,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ADMIN"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ADMIN,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ADMIN_PASSWORD"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ADMIN_PASSWORD,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ADMIN_PROFILE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ADMIN_PROFILE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ADMIN_PROFILE_PASSWORD"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ADMIN_PROFILE_PASSWORD,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ADMINS_BY_ORG"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ADMINS_BY_ORG + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_ADMINS_BY_ORG"](state, payload) {
      return axios({
        method: "POST",
        url: GET_MY_ADMINS_BY_ORG,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_ADMINS_BY_HOUSE"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ADMINS_BY_HOUSE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["MAKE_ADMIN_OWNER"](state, payload) {
      return axios({
        method: "POST",
        url: MAKE_ADMIN_OWNER,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["REMOVE_ADMIN_OWNER"](state, payload) {
      return axios({
        method: "POST",
        url: REMOVE_ADMIN_OWNER,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
