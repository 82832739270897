import { BACKEND } from "../variables.js";
import axios from "axios";
const START_SUBSCRIPTION_URL = BACKEND + "/tenantSubscriptions/add";
const UPDATE_SUBSCRIPTION_URL = BACKEND + "/tenantSubscriptions/update";
const RENEW_SUBSCRIPTION_URL = BACKEND + "/tenantSubscriptions/renew";
const END_SUBSCRIPTION_URL = BACKEND + "/tenantSubscriptions/end";
const GET_MY_SUBSCRIPTIONS_URL = BACKEND + "/tenantSubscriptions/my";
const GET_SUBSCRIPTION_URL = BACKEND + "/tenantSubscriptions/get/";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["START_SUBSCRIPTION"](state, payload) {
      return axios({
        method: "POST",
        url: START_SUBSCRIPTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_SUBSCRIPTION"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_SUBSCRIPTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["RENEW_SUBSCRIPTION"](state, payload) {
      return axios({
        method: "POST",
        url: RENEW_SUBSCRIPTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["END_SUBSCRIPTION"](state, payload) {
      return axios({
        method: "POST",
        url: END_SUBSCRIPTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_SUBSCRIPTIONS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_MY_SUBSCRIPTIONS_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_SUBSCRIPTION"](state, payload) {
      return axios({
        method: "GET",
        url: GET_SUBSCRIPTION_URL + payload.data.subscription_id,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
