<template>
  <div class="dashboard">
    <div class="top-header">
      <h4>SUBSCRIPTIONS REPORT</h4>
      <div class="current-year">
        <label for="currentYear">Year:</label>
        <select id="currentYear" v-model="currentYear" @change="getItems">
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="top-header-buttons">
        <button class="btn">
          <i class="fa-solid fa-cloud-arrow-down"></i>
          EXPORT
        </button>
      </div>
    </div>
    <div class="dash-content">
      <div class="top-dash">
        <div class="dash-item">
          <div class="dash-item-header">
            <div class="left">
              <h2>{{ currentYear }}</h2>
              <p>Subscribers count</p>
            </div>
          </div>
          <div class="dash-item-body">
            <canvas id="myChart" width="400" height="400"></canvas>
          </div>
        </div>
      </div>
      <div class="subscriptions-table">
        <div class="total-count">
          <p>
            Total Subscriptions <span>{{ subscriptions.length }}</span>
          </p>
        </div>
        <table v-if="subscriptions.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>Tenant</th>
              <th>Room</th>
              <th>Start Date</th>
              <th>Due Date</th>
              <th>Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(subscription, index) in subscriptions"
              :key="subscription.subscription_id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ subscription.tenant.tenant_name }}</td>
              <td>{{ subscription.room.room_number }}</td>
              <td>{{ subscription.started_at }}</td>
              <td>{{ subscription.expire_at }}</td>
              <td>{{ subscription.subscription_type }}</td>
              <td>
                <span :class="'chip ' + subscription.status">{{
                  subscription.status
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="no-data">
          <p>No subscriptions found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import Chart from "chart.js/auto";
export default {
  data() {
    return {
      selectedDate: new Date(),
      insights: [],
      subscriptions: [],
      currentYear: 2024,
      years: [],
      chartInstance: null,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_REPORTS", {
          token: this.$getAdminToken(),
          data: {
            house_id: this.$getHouseId(),
            year: this.currentYear,
            report: "subscriptions",
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.subscriptions = res.data.results.data;
              this.refreshChart(res.data.results);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            toast.error(err.response.data.message);
            this.$AdminLogout();
          } else {
            toast.error("Something went wrong, please try again later.");
            console.log(err);
          }
          this.$stopLoader();
        });
    },
    getPercentage(total, value) {
      return value == 0 ? 0 : ((value / total) * 100).toFixed(2);
    },
    refreshChart(results) {
      if (this.chartInstance) {
        // Step 2: Check if the chart instance exists
        this.chartInstance.destroy(); // Destroy the existing chart instance
      }
      const ctx = document.getElementById("myChart").getContext("2d");
      this.chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              label: "Year " + this.currentYear,
              data: results.perMonth,
              backgroundColor: ["#5468ff19"],
              borderColor: ["#5468ff"],
              borderWidth: 1,
              tension: 0.5,
              borderRadius: 5,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          grid: {
            display: false,
          },
        },
      });
    },
  },
  mounted() {
    if (this.$access("Dashboard", "read")) {
      this.getItems();
    }
    for (let i = 2010; i <= new Date().getFullYear(); i++) {
      this.years.unshift(i);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  margin-top: 1rem;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 2rem;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    border: 1px solid #e0e0e0;
    h4 {
      font-weight: 500;
      margin-bottom: 0;
    }
    .current-year {
      display: flex;
      align-items: center;
      column-gap: 10px;
      label {
        font-size: 0.9rem;
        font-weight: 500;
      }
      select {
        padding: 0.5rem 1rem;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
      }
    }
    .top-header-buttons {
      button {
        font-size: 0.9rem;
        padding: 0.75rem 1rem;
        border-radius: 5px;
        background-color: $primary_color;
        color: #fff;
        font-weight: 500;
        border: none;
        &:hover {
          background-color: $primary_color_hover;
        }
      }
    }
  }
  .dash-content {
    padding: 0 20px;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    border: 1px solid #e0e0e0;
    .top-dash {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
      @media (max-width: 1300px) {
        grid-template-columns: 1fr;
      }
      .dash-item {
        width: 100%;
        background-color: #fff;
        .dash-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 2rem;
          padding-bottom: 0;
          .left {
            h2 {
              font-size: 1.5rem;
              font-weight: 600;
              @media (max-width: 1700px) {
                font-size: 1.2rem;
              }
              @media (max-width: 1500px) {
                font-size: 1.1rem;
              }
              @media (max-width: 1300px) {
                font-size: 1rem;
              }
              @media (max-width: 900px) {
                font-size: 0.95rem;
              }
            }
            p {
              font-size: 0.9rem;
              color: #888;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
              }
              @media (max-width: 1500px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.6rem;
              }
              @media (max-width: 900px) {
                font-size: 0.55rem;
              }
            }
          }
        }
        .dash-item-body {
          padding: 20px;
          border-radius: 10px;

          .circular-progress {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            .circular-progress-circle {
              position: relative;
              width: 170px;
              height: 170px;
              background: transparent;
              border-radius: 50%;
              overflow: hidden;
              @media (max-width: 1700px) {
                width: 150px;
                height: 150px;
              }
              @media (max-width: 1500px) {
                width: 130px;
                height: 130px;
              }
              @media (max-width: 1300px) {
                width: 110px;
                height: 110px;
              }
              @media (max-width: 900px) {
                width: 90px;
                height: 90px;
              }
            }

            .segment {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100vw;
              height: 100vw;
              background: $primary_color;
              transform-origin: 0 0;
            }

            .circular-progress-inner {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 150px;
              height: 150px;
              transform: translateX(-50%) translateY(-50%);
              background: #fff;
              border-radius: 50%;
            }

            .circular-progress-value {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              p {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 5px;
                @media (max-width: 1700px) {
                  font-size: 1.3rem;
                }
                @media (max-width: 1500px) {
                  font-size: 1.1rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.9rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.8rem;
                }
              }
              span {
                font-size: 0.9rem;
                color: $primary_color;
                background: rgba(12, 2, 68, 0.993) !important;
                padding: 3px 5px;
                display: inline-block;
                border-radius: 5px;
                font-weight: 600;
              }
            }
          }
          .arc-text {
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0 2rem;
            p {
              font-size: 0.9rem;
              color: #000;
              text-align: center;
            }
          }
          .arc-stats {
            display: flex;
            justify-content: space-between;
            .stat-item {
              width: 30%;
              padding: 10px;
              border-radius: 5px;
              background-color: #fff;
              .top {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                p {
                  font-size: 0.9rem;
                  color: #646464;
                }
              }
              .bottom {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 5px;
                span {
                  font-size: 1.2rem;
                  font-weight: 600;
                }
                i {
                  font-size: 0.9rem;
                  color: $primary_color;
                }
              }
            }
          }

          #myChart {
            width: 100%;
            max-height: 300px;
          }
        }
      }
    }
    .subscriptions-table {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 2rem;
      margin-top: 2rem;
      border-top: 1px solid #e0e0e0;
      .total-count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        p {
          font-size: 0.85rem;
          font-weight: 400;
          color: #000;
          text-align: center;
          height: 150px;
          width: 150px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border-radius: 50%;
          border: 3px solid $primary_color;
          span {
            font-weight: 500;
            display: block;
            margin-top: 5px;
            color: $primary_color;
            font-size: 2rem;
          }
        }
      }
      table {
        width: 100%;
        border-collapse: collapse;
        background-color: #eee;
        thead {
          tr {
            background-color: #eee;
            color: #13122ece;
            th {
              padding: 10px;
              font-weight: 600;
              font-size: 0.8rem;
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            background-color: #fff;
            border-bottom: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            td {
              padding: 12px 10px;
              font-size: 0.9rem;
            }
          }
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>