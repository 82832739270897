<template>
  <div class="tenant-dashboard">
    <NavBar />
    <div class="tenant-dashboard-content">
      <div class="container">
        <TenantNav />
        <div class="dashboard-content">
          <div class="dashboard-content-left">
            <div class="dashboard-content-header">
              <h3>Cancellation Requests</h3>
            </div>
            <div class="dashboard-content-body">
              <div class="tenant-dashboard-widget">
                <div class="tenant-dashboard-widget-header">
                  <h3>Requests</h3>
                </div>
                <div
                  class="tenant-dashboard-widget-body"
                  v-if="allItems.length > 0"
                >
                  <div class="my-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Subscriber</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Room number(s)</th>
                          <th>Status</th>
                          <th>Requested at</th>
                          <th>Approved by</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in allItems" :key="index">
                          <td>
                            {{ index + 1 }}
                          </td>
                          <td v-if="item.tenant.tenant_type != 'company'">
                            {{ item.tenant.tenant_name }}
                          </td>
                          <td v-else>{{ item.tenant.company_name }}</td>
                          <td>{{ item.tenant.tenant_phone }}</td>
                          <td>{{ item.tenant.tenant_email }}</td>
                          <td>
                            <span
                              v-for="(
                                subscription, index
                              ) in item.subscriptionsData"
                              :key="subscription.id"
                              >{{ subscription.room.room_number
                              }}<span
                                v-if="index < item.subscriptionsData.length - 1"
                                >,</span
                              >
                              &nbsp;
                            </span>
                          </td>
                          <td>
                            <span :class="'chip ' + item.status">{{
                              item.status
                            }}</span>
                          </td>
                          <td>{{ $date(item.created_at) }}</td>
                          <td v-if="item.approved_by">
                            {{ item.approver.name }}
                          </td>
                          <td v-else>-</td>
                          <td>
                            <a-dropdown-button v-if="item.status == 'pending'">
                              Actions
                              <template #overlay>
                                <a-menu>
                                  <a-menu-item
                                    key="1"
                                    @click="
                                      selectedItem = item;
                                      startDelete = true;
                                    "
                                    >Delete</a-menu-item
                                  >
                                </a-menu>
                              </template>
                              <template #icon
                                ><i class="fa fa-angle-down"></i
                              ></template>
                            </a-dropdown-button>
                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="pagination">
                      <button
                        :class="{ disabled: currentPage === 1 }"
                        @click="$previousPage()"
                      >
                        <label>Previous</label>
                      </button>
                      <p v-if="currentPage > 3">...</p>
                      <button
                        class="numbers"
                        v-for="number in pagesNumber"
                        :key="number"
                      >
                        <label
                          :class="{ active: currentPage === number }"
                          for=""
                          v-if="
                            (number == 1 && currentPage <= 2) ||
                            (currentPage + 2 >= pagesNumber &&
                              number >= pagesNumber - 2) ||
                            (number >= currentPage - 2 &&
                              number <= currentPage + 2)
                          "
                          @click="$changePage(number)"
                        >
                          {{ number }}
                        </label>
                      </button>
                      <p v-if="currentPage <= pagesNumber - 3">...</p>
                      <button
                        :class="{
                          disabled: currentPage === pagesNumber.length,
                        }"
                        @click="$nextPage()"
                      >
                        <label>Next</label>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="no-data" v-else>
                  <i>No requests found</i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          :title="'Cancel Request'"
          :size="'sm'"
          v-model:open="startDelete"
          @closeModel="startDelete = false"
          centered
          width="500px"
          :okText="'Delete Request'"
          :cancelText="'Close'"
          @ok="deleteItem"
        >
          <p>
            We are glad to see you stay, if you have any issue please reach out
            to our administration
          </p>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      allItemsCount: 0,
      allItems: [],
      selectedItems: [],
      allSelected: false,
      startViewSubscription: false,
      selectedItem: null,
      popularPosts: [],
      startCancellation: false,
      reason: "",
      startDelete: false,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_CANCELLATION_REQUESTS", {
          token: this.$getTenantToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.data;
              this.pagesNumber = Math.ceil(res.data.total / this.itemsPerpage);
              this.allItemsCount = res.data.total;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
    requestCancellation() {
      this.$startLoader();
      this.$store
        .dispatch("CREATE_CANCELLATION_REQUEST", {
          token: this.$getTenantToken(),
          data: {
            subscriptions: [
              { subscription_id: this.selectedItem.subscription_id },
            ],
            reason: this.reason,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            toast.success(res.data.message);
            this.startCancellation = false;
            this.$stopLoader();
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_CANCELLATION_REQUEST", {
          token: this.$getTenantToken(),
          data: {
            cancellation_request_id: this.selectedItem.cancellation_request_id,
          },
        })
        .then((res) => {
          if (res) {
            toast.success(res.data.message);
            this.startDelete = false;
            this.getItems();
            this.$stopLoader();
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong, please try again later. " + err.message
          );
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.tenant-dashboard {
  background: #c6cae046;
  min-height: 100vh;
}
.tenant-dashboard-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.dashboard-content {
  padding: 0 1rem;
  margin: 0 1rem;
  height: 85vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .dashboard-content-left {
    .dashboard-content-header {
      h3 {
        font-size: 1.15rem;
        font-weight: 500;
      }
    }
    .tenant-dashboard-widget {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      margin: 1rem 0;
      .tenant-dashboard-widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }
        form {
          width: 300px;
          input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #f3f3f3;
            padding: 0.7rem 0.5rem;
            outline: none;
          }
        }
      }
      .tenant-dashboard-widget-stats {
        display: flex;
        flex-wrap: wrap;
        .tenant-dashboard-widget-stats-item {
          flex: 1;
          padding: 1rem;
          border-right: 1px solid #f3f3f3;
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            color: $primary_color;
          }
          h3 {
            font-size: 0.8rem;
            font-weight: 400;
          }
          &:last-child {
            border-right: none;
          }
          &.danger {
            h1 {
              color: #ad0000;
            }
          }
        }
      }
    }
  }
}
.container {
  width: 100%;
  padding: 0 1rem;
}
</style>
