<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Add Role</span>
        <p>Fill the form below to add a new router-link-exact-active.</p>
      </h1>
      <div class="admin-forms">
        <div class="input-group group-1">
          <div class="form-group">
            <label for="names">Role Name</label>
            <input
              type="text"
              id="names"
              v-model="newItem.role_name"
              placeholder="Names"
            />
          </div>
        </div>
        <h4>Assign Permissions</h4>
        <div class="permissions">
          <div
            class="permission"
            v-for="permission in permissions"
            :key="permission.id"
          >
            <h4>{{ permission.name }}</h4>
            <div class="checkbox-items">
              <div
                class="checkbox-item"
                v-for="value in permission.values"
                :key="value"
              >
                <label>
                  <input
                    type="checkbox"
                    :checked="checkPermission(permission.name, value)"
                    :value="value"
                    @change="handlePermissionChange(permission.name, value)"
                  />
                  <span>{{ value }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <button
          @click="createItem"
          v-if="$access('Employee Roles', 'write') && action != 'Edit'"
        >
          Create
        </button>
        <button
          @click="updateItem"
          v-if="$access('Employee Roles', 'write') && action == 'Edit'"
        >
          Update
        </button>
      </div>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Archive Tenant'"
        :size="'sm'"
        :displayText="'Are you sure you want to move this tenant to archive?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      tenants: [],
      permissions: [],
      newItem: {
        house_id: "",
        role_name: "",
        permissions: [],
        role_type: "defined",
      },
      startAdd: false,
      selected: null,
      action: null,
    };
  },
  methods: {
    createItem() {
      if (this.newItem.role_name && this.newItem.permissions.length > 0) {
        this.$startLoader();
        this.newItem.house_id = this.$getHouseId();
        // remove empty permissions
        this.newItem.permissions = this.newItem.permissions.filter(
          (p) => p.values.length > 0
        );
        this.$store
          .dispatch("ADD_ROLE", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.$router.push({ name: "adminRoles" });
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.newItem.role_name && this.newItem.permissions.length > 0) {
        this.$startLoader();
        this.newItem.house_id = this.$getHouseId();
        // remove empty permissions
        this.newItem.permissions = this.newItem.permissions.filter(
          (p) => p.values.length > 0
        );
        this.$store
          .dispatch("UPDATE_ROLE", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.$router.push({ name: "AdminRoles" });
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      }
    },
    getPermissions() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PERMISSIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.permissions = res.data.data;
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    handlePermissionChange(name, value) {
      let permission = this.newItem.permissions.find((p) => p.name === name);
      if (permission) {
        if (permission.values.includes(value)) {
          permission.values = permission.values.filter((v) => v !== value);
        } else {
          permission.values.push(value);
        }
      } else {
        this.newItem.permissions.push({ name, values: [value] });
      }
    },
    checkPermission(name, value) {
      let permission = this.newItem.permissions.find((p) => p.name === name);
      return permission && permission.values.includes(value);
    },
    getRole() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ROLE", {
          token: this.$getAdminToken(),
          data: {
            role_id: this.selected,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.newItem = res.data;
            this.newItem.permissions = res.data.permissions;
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getPermissions();
    this.action = this.$route.query.action;
    if (this.$route.query.action == "Edit") {
      this.selected = this.$route.query.id;
      this.getRole();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 1rem;
    p {
      font-size: 14px;
      color: #686868;
      font-weight: 400;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
  .permissions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: rgba(245, 245, 245, 0.699);
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    .permission {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-bottom: 1px dashed #d4d4d4;
      padding-bottom: 1rem;
      h4 {
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 0rem;
      }
      .checkbox-items {
        display: flex;
        gap: 2rem;
        .checkbox-item {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          label {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            font-size: 0.9rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>