<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Employees', 'read')">
      <h1 class="page-title">
        <span>Employees <small>(3)</small></span
        ><a
          href=""
          class="my-btn cw glow wd"
          @click.prevent="startAdd = true"
          v-if="$access('Employees', 'write')"
        >
          Add Employee
        </a>
      </h1>

      <div class="my-table">
        <div class="table-filters">
          <fieldset>
            <legend>Role</legend>
            <div class="form-group">
              <select id="type" class="form-control">
                <option value="all">All Roles</option>
                <option v-for="role in roles" :key="role.role_id">
                  {{ role.role_name }}
                </option>
              </select>
            </div>
          </fieldset>
          <!-- status -->
          <fieldset>
            <legend>Status</legend>
            <div class="form-group">
              <select id="status" class="form-control">
                <option value="all">All Statuses</option>
                <option value="active">Active</option>
                <option value="paused">Paused</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button class="my-btn cw wd glow">Filter</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Company Role</th>
              <th>System Role</th>
              <th>Joined at</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in employees" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ item.name }} <span v-if="isCurrentUser(item.id)">(You)</span>
              </td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.employee_role }}</td>
              <td>{{ item.role.role_name }}</td>
              <td>{{ $date(item.created_at) }}</td>
              <td>
                <span :class="'chip ' + item.status">{{ item.status }}</span>
              </td>
              <td>
                <a-dropdown-button>
                  Actions
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                        v-if="$access('Employees', 'write')"
                        >Edit</a-menu-item
                      >
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                        v-if="
                          !isCurrentUser(item.id) &&
                          $access('Employees', 'delete')
                        "
                        >Delete</a-menu-item
                      >
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startForceLogout = true;
                        "
                        v-if="
                          !isCurrentUser(item.id) &&
                          $access('Employees', 'force_logout')
                        "
                        >Force Logout</a-menu-item
                      >
                    </a-menu>
                  </template>
                  <template #icon><i class="fa fa-angle-down"></i></template>
                </a-dropdown-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>

      <!-- add employee -->
      <Modal
        :title="'Add Employee'"
        :size="'lg'"
        v-model:open="startAdd"
        centered
        :okText="'Save'"
        @ok="createItem()"
        width="800px"
      >
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">Names</label>
            <input
              type="text"
              id="name"
              v-model="newItem.name"
              class="form-control"
              placeholder="Employee Names"
            />
          </div>
          <div class="input-group">
            <label for="name">Email</label>
            <input
              type="email"
              id="name"
              v-model="newItem.email"
              class="form-control"
              placeholder="Employee Email"
            />
          </div>
          <div class="input-group">
            <label for="status">Phone</label>
            <input
              type="text"
              id="status"
              v-model="newItem.phone"
              class="form-control"
              placeholder="Employee Phone"
            />
          </div>
        </div>
        <div class="form-group group-3">
          <div class="input-group">
            <label for="role">Company Role</label>
            <input
              type="text"
              id="role"
              v-model="newItem.employee_role"
              class="form-control"
              placeholder="Company Role"
            />
          </div>
          <div class="input-group">
            <label for="role">System Role</label>
            <select id="role" v-model="newItem.role_id" class="form-control">
              <option value="">Select role</option>
              <option
                v-for="item in roles"
                :key="item.role_id"
                :value="item.role_id"
              >
                {{ item.role_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="status">Status</label>
            <select id="status" v-model="newItem.status" class="form-control">
              <option value="">Select status</option>
              <option value="active">Active</option>
              <option value="paused">Paused</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              v-model="newItem.password"
              class="form-control"
              placeholder="Create password for the employee"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
      </Modal>

      <!-- update employee -->
      <Modal
        :title="'Update Employee'"
        :size="'lg'"
        v-model:open="startEdit"
        @closeModel="startEdit = false"
        centered
        :okText="'Save'"
        @ok="updateItem()"
        width="800px"
      >
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">Names</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.name"
              class="form-control"
              placeholder="Employee Names"
            />
          </div>
          <div class="input-group">
            <label for="name">Email</label>
            <input
              type="email"
              id="name"
              v-model="selectedItem.email"
              class="form-control"
              placeholder="Employee Email"
            />
          </div>
          <div class="input-group">
            <label for="status">Phone</label>
            <input
              type="text"
              id="status"
              v-model="selectedItem.phone"
              class="form-control"
              placeholder="Employee Phone"
            />
          </div>
        </div>
        <div class="form-group group-3">
          <div class="input-group">
            <label for="role">Company Role</label>
            <input
              type="text"
              id="role"
              v-model="selectedItem.employee_role"
              class="form-control"
              placeholder="Company Role"
            />
          </div>
          <div class="input-group">
            <label for="role">System Role</label>
            <select
              id="role"
              v-model="selectedItem.role_id"
              class="form-control"
            >
              <option value="">Select role</option>
              <option
                v-for="item in roles"
                :key="item.role_id"
                :value="item.role_id"
              >
                {{ item.role_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="status">Status</label>
            <select
              id="status"
              v-model="selectedItem.status"
              class="form-control"
            >
              <option value="">Select status</option>
              <option value="active">Active</option>
              <option value="paused">Paused</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
      </Modal>

      <!-- delete employee -->
      <Modal
        :title="'Delete Employee'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this {{ selectedItem.name }}?</p>
      </Modal>

      <!-- force logout -->
      <Modal
        :title="'Force Logout'"
        :size="'sm'"
        v-model:open="startForceLogout"
        @closeModel="startForceLogout = false"
        centered
        :okText="'Force Logout'"
        @ok="forceLogout()"
        width="400px"
      >
        <p>
          Are you sure you want to force logout this {{ selectedItem.name }}?
        </p>
      </Modal>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      roles: [],
      employees: [],
      newItem: {
        name: "",
        email: "",
        phone: "",
        employee_role: "",
        role_id: "",
        status: "",
        image: "",
        password: "",
        type: "admin",
        organization_id: "",
        access_houses: [],
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startForceLogout: false,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_EMPLOYEES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.employees = res.data.admins.data;
              this.allItemsCount = res.data.admins.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getRoles() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ROLES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.roles = res.data.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (
        this.newItem.name &&
        this.newItem.email &&
        this.newItem.phone &&
        this.newItem.employee_role &&
        this.newItem.role_id &&
        this.newItem.status &&
        this.newItem.image
      ) {
        this.$startLoader();
        this.newItem.organization_id = this.$getOrganizationId();
        this.newItem.access_houses = [this.$getHouseId()];
        this.$store
          .dispatch("CREATE_EMPLOYEE", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (
        this.selectedItem.name &&
        this.selectedItem.email &&
        this.selectedItem.phone &&
        this.selectedItem.employee_role &&
        this.selectedItem.role_id &&
        this.selectedItem.status
      ) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_EMPLOYEE", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_EMPLOYEE", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    forceLogout() {
      this.$startLoader();
      this.$store
        .dispatch("FORCE_LOGOUT", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.startForceLogout = false;
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    isCurrentUser(id) {
      return this.$getAdmin().id === id;
    },
  },
  mounted() {
    if (this.$access("Employees", "read")) {
      this.getItems();
      this.getRoles();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
</style>