<template>
  <div id="RoomCards" v-if="rooms.length > 0">
    <div
      class="card"
      v-for="room in rooms"
      :key="room.id"
      @click="$emit('roomSelected', room)"
    >
      <div
        class="card-body"
        :style="`background-image: url(${
          room.image ? $upload(room.image) : $upload(room.roomCategory.image)
        })`"
      >
        <h5 class="card-title" style="background: transparent">
          {{ room.room_name }}
        </h5>
        <div
          class="counts-section"
          :style="`background-image: url(${
            room.image ? $upload(room.image) : $upload(room.roomCategory.image)
          })`"
        >
          <div class="counts">
            <div class="count-item">
              <label for="floors">Number</label>
              <p class="floors">{{ room.room_number }}</p>
            </div>
            <div class="count-item">
              <label for="rooms">Price</label>
              <p class="rooms">{{ $money($comma(room.room_price)) }}</p>
            </div>
            <div class="count-item">
              <label for="rooms">Tenant</label>
              <p
                class="rooms"
                v-if="room.tenant && room.tenant.tenant_type == 'company'"
              >
                {{ room.tenant.company_name }}
              </p>
              <p
                class="rooms"
                v-if="room.tenant && room.tenant.tenant_type != 'company'"
              >
                {{ room.tenant.tenant_name }}
              </p>
              <p class="rooms" v-if="!room.tenant"><i>-</i></p>
            </div>
            <div class="count-item">
              <label for="rooms">Parent</label>
              <p class="rooms" v-if="room.parent_room">
                {{ room.parent_room.room_number }}
              </p>
              <p class="rooms" v-else><i>-</i></p>
            </div>
            <div class="count-item">
              <label for="rooms">Status</label>
              <p class="rooms">
                <Tag color="#87d068" v-if="room.status == 'Available'">{{
                  room.status
                }}</Tag>
                <Tag color="#db4d4d" v-if="room.status == 'Unavailable'">{{
                  room.status
                }}</Tag>
                <Tag color="#555" v-if="room.status == 'Occupied'">{{
                  room.status
                }}</Tag>
                <Tag color="#ab5839" v-if="room.status == 'In Repair'">{{
                  room.status
                }}</Tag>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-options">
        <div
          class="option"
          @click="
            selectedRoom = room;
            viewRoom = true;
          "
          v-if="$access('Rooms', 'read')"
        >
          View
        </div>
        <div
          class="option"
          @click="$emit('editRoom', room)"
          v-if="$access('Rooms', 'write')"
        >
          Edit
        </div>
        <div
          class="option delete"
          @click="$emit('deleteRoom', room)"
          v-if="room.status != 'Occupied' && $access('Rooms', 'delete')"
        >
          Delete
        </div>
      </div>
    </div>
    <Modal
      v-if="viewRoom"
      v-model:open="viewRoom"
      :title="selectedRoom.room_name + ' Details'"
      width="1000px"
      footer=""
    >
      <div class="room-view-widget">
        <div class="room-view-widget-left">
          <div class="tenant-card" v-if="selectedRoom.tenant">
            <div class="tenant-card-header">
              <h5>Tenant Details</h5>
            </div>
            <div class="tenant-card-body">
              <div class="tenant-image">
                <img
                  :src="$upload(selectedRoom.tenant.tenant_image)"
                  alt="Tenant Image"
                />
                <h3>{{ selectedRoom.tenant.tenant_name }}</h3>
                <p>({{ selectedRoom.tenant.tenant_type }})</p>
              </div>
              <div class="tenant-card-body-footer">
                <a
                  :href="'tel:' + selectedRoom.tenant.tenant_phone"
                  class="call"
                >
                  <i class="fas fa-phone"></i>
                  Call</a
                >
                <a
                  @click.prevent="
                    $router.push({
                      name: 'AdminMessages',
                      query: { tenant: selectedRoom.tenant.tenant_id },
                    })
                  "
                  class="message"
                >
                  <i class="fas fa-comment"></i>
                  Chat
                </a>
              </div>
            </div>
          </div>
          <div class="description-section">
            <h5 class="section-title">Description:</h5>
            <p v-if="selectedRoom.description">
              {{ selectedRoom.description }}
            </p>
            <p v-else><i>No Description Yet</i></p>
          </div>
        </div>
        <div class="room-view-widget-right">
          <div
            class="room-image"
            v-if="selectedRoom.image || selectedRoom.roomCategory.image"
          >
            <img
              :src="$upload(selectedRoom.image)"
              alt="Room Image"
              v-if="selectedRoom.image"
            />
            <img
              :src="$upload(selectedRoom.roomCategory.image)"
              alt="Room Category Image"
              v-if="!selectedRoom.image"
            />
            <div class="quick-buttons">
              <button class="primary">Edit</button>
              <button class="danger">Delete</button>
            </div>
          </div>
          <div class="room-text-content">
            <div class="grid-data">
              <div class="grid-data-item">
                <span>Wing</span>
                <p>{{ selectedRoom.wing.wing_name }}</p>
              </div>
              <div class="grid-data-item">
                <span>Floor</span>
                <p>{{ selectedRoom.floor.floor_name }}</p>
              </div>
              <div class="grid-data-item">
                <span>Category</span>
                <p>{{ selectedRoom.roomCategory.room_category_name }}</p>
              </div>
              <div class="grid-data-item">
                <span>Number</span>
                <p>{{ selectedRoom.room_number }}</p>
              </div>
            </div>
            <div class="equipments-section">
              <h5 class="section-title">Equipments:</h5>
              <div class="equipments-tags">
                <Tag
                  v-for="equipment in selectedRoom.equipments"
                  :key="equipment.equipment_id"
                  color="#f0f0f0"
                >
                  {{ equipment }}
                </Tag>
              </div>
            </div>
            <div class="details-list">
              <h5 class="section-title">Details:</h5>
              <div class="details-list-items">
                <div class="details-list-item">
                  <label>Price:</label>
                  <p>{{ $money($comma(selectedRoom.room_price)) }}</p>
                </div>
                <div class="details-list-item">
                  <label>Payment Frequency:</label>
                  <p>{{ selectedRoom.frequency }}</p>
                </div>
                <div class="details-list-item">
                  <label>Status:</label>
                  <p>
                    <Tag
                      color="#87d068"
                      v-if="selectedRoom.status == 'Available'"
                    >
                      {{ selectedRoom.status }}
                    </Tag>
                    <Tag
                      color="#db4d4d"
                      v-if="selectedRoom.status == 'Unavailable'"
                    >
                      {{ selectedRoom.status }}
                    </Tag>
                    <Tag color="#555" v-if="selectedRoom.status == 'Occupied'">
                      {{ selectedRoom.status }}
                    </Tag>
                    <Tag
                      color="#ab5839"
                      v-if="selectedRoom.status == 'In Repair'"
                    >
                      {{ selectedRoom.status }}
                    </Tag>
                  </p>
                </div>
                <div class="details-list-item">
                  <label>Added:</label>
                  <p>{{ $date(selectedRoom.created_at) }}</p>
                </div>
                <div class="details-list-item two-columns">
                  <label>Added by:</label>
                  <p>
                    {{ selectedRoom.created_by.name }}
                    <i>({{ selectedRoom.created_by.email }})</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
    
<script>
import { toast } from "vue3-toastify";
import { Tag, Modal } from "ant-design-vue";
export default {
  components: {
    Tag,
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedRoom: null,
      viewRoom: false,
    };
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>
    
  <style scoped lang="scss">
@import "@/assets/main.scss";
#RoomCards {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  .card {
    cursor: pointer;
    width: 250px;
    height: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    .card-body {
      border-radius: 10px;
      overflow: hidden;
      background: white;
      position: relative;
      z-index: 1;
      // make background dark
      // make the image dark
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
      }
      .card-title {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 0px;
        text-align: center;
        padding: 1rem 10px;
        background: $primary_color;
        border-radius: 0px 0px 30px 0px;
        color: white;
      }
      .counts-section {
        background: $primary_color;
        position: relative;
        z-index: 1;
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          height: 100%;
          background: rgba(0, 0, 0, 0.48);
          z-index: -1;
        }
        .counts {
          display: flex;
          flex-direction: column;
          background: white;
          padding: 20px 10px 10px 10px;
          border-radius: 30px 0px 0px 0px;
          gap: 10px;
          .count-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #00000017;
            padding: 0 10px;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            label {
              font-size: 0.8rem;
              color: #333;
            }
            p {
              font-size: 0.8rem;
              font-weight: 600;
            }
          }
        }
      }
    }
    .card-options {
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 10px;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px;
      display: none;
      transition: display 0.3s ease;
      // background blur effect
      backdrop-filter: blur(2px);
      background: rgba(255, 255, 255, 0.5);

      .option {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 5px;
        background: $primary_color;
        color: white;
        width: 100px;
        font-size: 0.8rem;
        &:hover {
          background: darken($primary_color, 10%);
        }
        &.delete {
          background: #db4d4d;
          &:hover {
            background: darken(#db4d4d, 10%);
          }
        }
      }
    }

    &:hover {
      .card-options {
        z-index: 1;
        display: flex;
      }
    }
  }

  .card::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: -2px;
    bottom: -2px;
    border-radius: inherit;
    background: linear-gradient(45deg, #c06c6c, #2150e7, #8b4dff, #54d68f);
    background-size: 300% 300%;
    animation: gradientGlow 3s ease infinite;
    opacity: 0;
    transition: opacity 1s ease;
    z-index: -1;
  }

  .card:hover::before {
    opacity: 1;
  }

  @keyframes gradientGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    80% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .add-card {
    cursor: pointer;
    width: 200px;
    height: 149px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: white;
    color: $primary_color;
    font-size: 0.9rem;
    position: relative;
    z-index: 1;
    .card-content {
      gap: 10px;
      background: white;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      i {
        margin-right: 10px;
        font-size: 1.1rem;
      }
      p {
        font-weight: 400;
        color: #333;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: -2px;
      bottom: -2px;
      border-radius: inherit;
      background: linear-gradient(45deg, #c06c6c, #2150e7, #8b4dff, #54d68f);
      background-size: 300% 300%;
      animation: gradientGlow 3s ease infinite;
      opacity: 0;
      transition: opacity 1s ease;
      z-index: -1;
    }
    &:hover::before {
      opacity: 1;
    }
  }
}

.room-view-widget {
  display: grid;
  gap: 1rem;
  padding: 1rem 0;
  grid-template-columns: 320px 1fr;
  .section-title {
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
    margin: 10px 0;
    margin-top: 20px;
  }
  .room-view-widget-left {
    width: 100%;
    .tenant-card {
      width: 100%;
      border-radius: 10px;
      background: white;
      border: 1px solid #f0f0f0;
      .tenant-card-header {
        background: #f8fdff;
        color: #333;
        padding: 10px;
        border-radius: 3px;
        h5 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }
      .tenant-card-body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .tenant-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            border: 5px solid #eff5f7;
          }
          h3 {
            font-size: 1.1rem;
            font-weight: 400;
            margin-bottom: 0px;
          }
          p {
            font-size: 0.9rem;
            font-weight: 400;
            color: #333;
          }
        }
        .tenant-card-body-footer {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          .call,
          .message {
            padding: 10px;
            border-radius: 5px;
            background: $primary_color;
            color: white;
            font-size: 0.9rem;
            text-align: center;
            cursor: pointer;
            i {
              margin-right: 0px;
            }
            &:hover {
              background: darken($primary_color, 10%);
            }
          }
          .message {
            background: #54d68f;
            &:hover {
              background: darken(#54d68f, 10%);
            }
          }
        }
      }
    }
    .description-section {
      p {
        font-size: 0.9rem;
        color: #333;
      }
    }
  }
  .room-view-widget-right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .room-image {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 10px;
        border: 5px solid #eff5f7;
      }
      .quick-buttons {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        gap: 10px;
        button {
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.8rem;
          font-weight: 600;
          &.primary {
            background: $primary_color;
            color: white;
            &:hover {
              background: darken($primary_color, 10%);
            }
          }
          &.danger {
            background: #db4d4d;
            color: white;
            &:hover {
              background: darken(#db4d4d, 10%);
            }
          }
        }
      }
    }
    .room-text-content {
      .grid-data {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        border-radius: 10px;
        background: #032a3a0c;
        padding: 10px;
        .grid-data-item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 0 10px;
          border-right: 1px solid #00000017;
          &:nth-last-child(1) {
            border-right: none;
          }
          span {
            font-size: 0.75rem;
            color: #333;
          }
          p {
            font-size: 0.9rem;
            font-weight: 600;
          }
        }
      }
      .equipments-section {
        .equipments-tags {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
          margin-top: 5px;
          .ant-tag {
            font-size: 0.8rem;
            font-weight: 400;
            color: #333;
            background: #f0f0f0;
            border-radius: 5px;
            padding: 3px 10px;
            border: 1px solid #bdbdbd;
          }
        }
      }
      .details-list {
        .details-list-items {
          display: grid;
          gap: 5px;
          grid-template-columns: 1fr 1fr;
          .details-list-item {
            display: flex;
            gap: 5px;
            align-items: center;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            label {
              font-size: 0.8rem;
              color: #333;
              font-weight: 600;
            }
            p {
              font-size: 0.9rem;
              margin-bottom: 0;
            }
            &.two-columns {
              grid-column: 1 / -1;
            }
          }
        }
      }
    }
  }
}
</style>